@charset "UTF-8";
/**
 * Options
 */
/**
 * Colors
 */
@import url("https://fonts.bunny.net/css?family=source-sans-pro:200,300,400,500,600,700,800,900");
:root {
  --custom-ley-white: #ffffff;
  --custom-ley-black: #000000;
  --custom-ley-primary: #183a68;
  --custom-ley-secondary: #b70e0c;
  --custom-ley-third: #009a9b;
  --custom-ley-offer-items: 1;
}

.slider {
  width: 300px;
}

.text-decoration-underline {
  text-decoration: underline;
  text-decoration-color: #b70e0c;
}

/**
 * Fonts
 */
/**
 * Transitions
 */
/**
 * Transitions
 */
/** *********************************************************************** **/
/**
 *
 */
.clear-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*!
 * Bootstrap  v5.3.0-alpha3 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #183a68;
  --bs-secondary: #b70e0c;
  --bs-third: #009a9b;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f7f8f9;
  --bs-dark: #333333;
  --bs-highlight: #009a9b;
  --bs-primary-rgb: 24, 58, 104;
  --bs-secondary-rgb: 183, 14, 12;
  --bs-third-rgb: 0, 154, 155;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 247, 248, 249;
  --bs-dark-rgb: 51, 51, 51;
  --bs-highlight-rgb: 0, 154, 155;
  --bs-primary-text-emphasis: #0a172a;
  --bs-secondary-text-emphasis: #490605;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d1d8e1;
  --bs-secondary-bg-subtle: #f1cfce;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a3b0c3;
  --bs-secondary-border-subtle: #e29f9e;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Source Sans Pro", Helvetica, Arial, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 300;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-link-color: #183a68;
  --bs-link-color-rgb: 24, 58, 104;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #132e53;
  --bs-link-hover-color-rgb: 19, 46, 83;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(24, 58, 104, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #7489a4;
  --bs-secondary-text-emphasis: #d46e6d;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #050c15;
  --bs-secondary-bg-subtle: #250302;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #0e233e;
  --bs-secondary-border-subtle: #6e0807;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-link-color: #7489a4;
  --bs-link-hover-color: #90a1b6;
  --bs-link-color-rgb: 116, 137, 164;
  --bs-link-hover-color-rgb: 144, 161, 182;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color, inherit);
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 800;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #d1d8e1;
  --bs-table-border-color: #bcc2cb;
  --bs-table-striped-bg: #c7cdd6;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bcc2cb;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1c8d0;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #f1cfce;
  --bs-table-border-color: #d9bab9;
  --bs-table-striped-bg: #e5c5c4;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #d9bab9;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #dfbfbf;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #f7f8f9;
  --bs-table-border-color: #dedfe0;
  --bs-table-striped-bg: #ebeced;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dedfe0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e4e5e6;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #333333;
  --bs-table-border-color: #474747;
  --bs-table-striped-bg: #3d3d3d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #474747;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #424242;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #8c9db4;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  background-image: var(--bs-gradient);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.form-select:focus {
  border-color: #8c9db4;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8c9db4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
}
.form-check-input:checked {
  background-color: #183a68;
  border-color: #183a68;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"), var(--bs-gradient);
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e"), var(--bs-gradient);
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #183a68;
  border-color: #183a68;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"), var(--bs-gradient);
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238c9db4'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"), var(--bs-gradient);
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #183a68;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bac4d2;
  background-image: var(--bs-gradient);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #183a68;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.form-range::-moz-range-thumb:active {
  background-color: #bac4d2;
  background-image: var(--bs-gradient);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  background-image: var(--bs-gradient);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  background-image: var(--bs-gradient);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  background-image: none;
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  background-image: none;
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #183a68;
  --bs-btn-border-color: #183a68;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #143158;
  --bs-btn-hover-border-color: #132e53;
  --bs-btn-focus-shadow-rgb: 59, 88, 127;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #132e53;
  --bs-btn-active-border-color: #122c4e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #183a68;
  --bs-btn-disabled-border-color: #183a68;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #b70e0c;
  --bs-btn-border-color: #b70e0c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #9c0c0a;
  --bs-btn-hover-border-color: #920b0a;
  --bs-btn-focus-shadow-rgb: 194, 50, 48;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #920b0a;
  --bs-btn-active-border-color: #890b09;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #b70e0c;
  --bs-btn-disabled-border-color: #b70e0c;
}

.btn-third {
  --bs-btn-color: #000000;
  --bs-btn-bg: #009a9b;
  --bs-btn-border-color: #009a9b;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #26a9aa;
  --bs-btn-hover-border-color: #1aa4a5;
  --bs-btn-focus-shadow-rgb: 0, 131, 132;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #33aeaf;
  --bs-btn-active-border-color: #1aa4a5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #009a9b;
  --bs-btn-disabled-border-color: #009a9b;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f7f8f9;
  --bs-btn-border-color: #f7f8f9;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d2d3d4;
  --bs-btn-hover-border-color: #c6c6c7;
  --bs-btn-focus-shadow-rgb: 210, 211, 212;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c6c6c7;
  --bs-btn-active-border-color: #b9babb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f7f8f9;
  --bs-btn-disabled-border-color: #f7f8f9;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #525252;
  --bs-btn-hover-border-color: #474747;
  --bs-btn-focus-shadow-rgb: 82, 82, 82;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5c5c5c;
  --bs-btn-active-border-color: #474747;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #333333;
  --bs-btn-disabled-border-color: #333333;
}

.btn-highlight {
  --bs-btn-color: #000000;
  --bs-btn-bg: #009a9b;
  --bs-btn-border-color: #009a9b;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #26a9aa;
  --bs-btn-hover-border-color: #1aa4a5;
  --bs-btn-focus-shadow-rgb: 0, 131, 132;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #33aeaf;
  --bs-btn-active-border-color: #1aa4a5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #009a9b;
  --bs-btn-disabled-border-color: #009a9b;
}

.btn-outline-primary {
  --bs-btn-color: #183a68;
  --bs-btn-border-color: #183a68;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #183a68;
  --bs-btn-hover-border-color: #183a68;
  --bs-btn-focus-shadow-rgb: 24, 58, 104;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #183a68;
  --bs-btn-active-border-color: #183a68;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #183a68;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #183a68;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #b70e0c;
  --bs-btn-border-color: #b70e0c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b70e0c;
  --bs-btn-hover-border-color: #b70e0c;
  --bs-btn-focus-shadow-rgb: 183, 14, 12;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b70e0c;
  --bs-btn-active-border-color: #b70e0c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #b70e0c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b70e0c;
  --bs-gradient: none;
}

.btn-outline-third {
  --bs-btn-color: #009a9b;
  --bs-btn-border-color: #009a9b;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #009a9b;
  --bs-btn-hover-border-color: #009a9b;
  --bs-btn-focus-shadow-rgb: 0, 154, 155;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #009a9b;
  --bs-btn-active-border-color: #009a9b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #009a9b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #009a9b;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f7f8f9;
  --bs-btn-border-color: #f7f8f9;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f7f8f9;
  --bs-btn-hover-border-color: #f7f8f9;
  --bs-btn-focus-shadow-rgb: 247, 248, 249;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f7f8f9;
  --bs-btn-active-border-color: #f7f8f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f7f8f9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f7f8f9;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #333333;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-focus-shadow-rgb: 51, 51, 51;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #333333;
  --bs-btn-active-border-color: #333333;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #333333;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333333;
  --bs-gradient: none;
}

.btn-outline-highlight {
  --bs-btn-color: #009a9b;
  --bs-btn-border-color: #009a9b;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #009a9b;
  --bs-btn-hover-border-color: #009a9b;
  --bs-btn-focus-shadow-rgb: 0, 154, 155;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #009a9b;
  --bs-btn-active-border-color: #009a9b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #009a9b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #009a9b;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 59, 88, 127;
  text-decoration: underline;
  background-image: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s cubic-bezier(0.87, 0, 0.13, 1);
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.87, 0, 0.13, 1);
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s cubic-bezier(0.87, 0, 0.13, 1);
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #183a68;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
  background-image: var(--bs-gradient);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
  background-image: var(--bs-gradient);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #183a68;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #183a68;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
  background-image: var(--bs-gradient);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  background-image: var(--bs-gradient);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a172a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8c9db4;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237489a4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237489a4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #183a68;
  --bs-pagination-active-border-color: #183a68;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  background-image: var(--bs-gradient);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  background-image: var(--bs-gradient);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-third {
  --bs-alert-color: var(--bs-third-text-emphasis);
  --bs-alert-bg: var(--bs-third-bg-subtle);
  --bs-alert-border-color: var(--bs-third-border-subtle);
  --bs-alert-link-color: var(--bs-third-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert-highlight {
  --bs-alert-color: var(--bs-highlight-text-emphasis);
  --bs-alert-bg: var(--bs-highlight-bg-subtle);
  --bs-alert-border-color: var(--bs-highlight-border-subtle);
  --bs-alert-link-color: var(--bs-highlight-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #183a68;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #183a68;
  --bs-list-group-active-border-color: #183a68;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-third {
  --bs-list-group-color: var(--bs-third-text-emphasis);
  --bs-list-group-bg: var(--bs-third-bg-subtle);
  --bs-list-group-border-color: var(--bs-third-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-third-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-third-border-subtle);
  --bs-list-group-active-color: var(--bs-third-bg-subtle);
  --bs-list-group-active-bg: var(--bs-third-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-third-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.list-group-item-highlight {
  --bs-list-group-color: var(--bs-highlight-text-emphasis);
  --bs-list-group-bg: var(--bs-highlight-bg-subtle);
  --bs-list-group-border-color: var(--bs-highlight-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-highlight-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-highlight-border-subtle);
  --bs-list-group-active-color: var(--bs-highlight-bg-subtle);
  --bs-list-group-active-bg: var(--bs-highlight-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-highlight-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(24, 58, 104, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001));
}

.carousel-control-next {
  right: 0;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001));
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(24, 58, 104, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(183, 14, 12, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-third {
  color: #000000 !important;
  background-color: RGBA(0, 154, 155, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #ffffff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(247, 248, 249, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(51, 51, 51, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-highlight {
  color: #000000 !important;
  background-color: RGBA(0, 154, 155, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1));
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(19, 46, 83, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(19, 46, 83, var(--bs-link-underline-opacity, 1));
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1));
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(146, 11, 10, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(146, 11, 10, var(--bs-link-underline-opacity, 1));
}

.link-third {
  color: RGBA(var(--bs-third-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-third-rgb), var(--bs-link-underline-opacity, 1));
}
.link-third:hover, .link-third:focus {
  color: RGBA(51, 174, 175, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(51, 174, 175, var(--bs-link-underline-opacity, 1));
}

.link-success {
  color: RGBA(var(--bs-success-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1));
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1));
}

.link-info {
  color: RGBA(var(--bs-info-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1));
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1));
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1));
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1));
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1));
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1));
}

.link-light {
  color: RGBA(var(--bs-light-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1));
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 249, 250, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(249, 249, 250, var(--bs-link-underline-opacity, 1));
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1));
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1));
}

.link-highlight {
  color: RGBA(var(--bs-highlight-rgb, var(--bs-link-opacity, 1)));
  text-decoration-color: RGBA(var(--bs-highlight-rgb), var(--bs-link-underline-opacity, 1));
}
.link-highlight:hover, .link-highlight:focus {
  color: RGBA(51, 174, 175, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(51, 174, 175, var(--bs-link-underline-opacity, 1));
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1));
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75));
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75));
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-third {
  --bs-focus-ring-color: rgba(var(--bs-third-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-highlight {
  --bs-focus-ring-color: rgba(var(--bs-highlight-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-third {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-third-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-highlight {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-highlight-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: 2.5rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-3 {
  font-size: 1.75rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: 200 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 800 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-third {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-third-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-highlight {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-highlight-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-third {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-third-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-highlight {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-highlight-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-third {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-third-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-highlight {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-highlight-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fasr,
.fal,
.fa-light,
.fasl,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fass,
.fa-sharp-solid,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass,
.fasr,
.fasl,
.fa-sharp {
  font-family: "Font Awesome 6 Sharp";
}

.fass,
.fa-sharp {
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30 ";
}

.fa-1::before {
  content: "\31 ";
}

.fa-2::before {
  content: "\32 ";
}

.fa-3::before {
  content: "\33 ";
}

.fa-4::before {
  content: "\34 ";
}

.fa-5::before {
  content: "\35 ";
}

.fa-6::before {
  content: "\36 ";
}

.fa-7::before {
  content: "\37 ";
}

.fa-8::before {
  content: "\38 ";
}

.fa-9::before {
  content: "\39 ";
}

.fa-fill-drip::before {
  content: "";
}

.fa-arrows-to-circle::before {
  content: "";
}

.fa-circle-chevron-right::before {
  content: "";
}

.fa-chevron-circle-right::before {
  content: "";
}

.fa-wagon-covered::before {
  content: "";
}

.fa-line-height::before {
  content: "";
}

.fa-bagel::before {
  content: "";
}

.fa-transporter-7::before {
  content: "";
}

.fa-at::before {
  content: "\@";
}

.fa-rectangles-mixed::before {
  content: "";
}

.fa-phone-arrow-up-right::before {
  content: "";
}

.fa-phone-arrow-up::before {
  content: "";
}

.fa-phone-outgoing::before {
  content: "";
}

.fa-trash-can::before {
  content: "";
}

.fa-trash-alt::before {
  content: "";
}

.fa-circle-l::before {
  content: "";
}

.fa-head-side-goggles::before {
  content: "";
}

.fa-head-vr::before {
  content: "";
}

.fa-text-height::before {
  content: "";
}

.fa-user-xmark::before {
  content: "";
}

.fa-user-times::before {
  content: "";
}

.fa-face-hand-yawn::before {
  content: "";
}

.fa-gauge-simple-min::before {
  content: "";
}

.fa-tachometer-slowest::before {
  content: "";
}

.fa-stethoscope::before {
  content: "";
}

.fa-coffin::before {
  content: "";
}

.fa-message::before {
  content: "";
}

.fa-comment-alt::before {
  content: "";
}

.fa-salad::before {
  content: "";
}

.fa-bowl-salad::before {
  content: "";
}

.fa-info::before {
  content: "";
}

.fa-robot-astromech::before {
  content: "";
}

.fa-ring-diamond::before {
  content: "";
}

.fa-fondue-pot::before {
  content: "";
}

.fa-theta::before {
  content: "";
}

.fa-face-hand-peeking::before {
  content: "";
}

.fa-square-user::before {
  content: "";
}

.fa-down-left-and-up-right-to-center::before {
  content: "";
}

.fa-compress-alt::before {
  content: "";
}

.fa-explosion::before {
  content: "";
}

.fa-file-lines::before {
  content: "";
}

.fa-file-alt::before {
  content: "";
}

.fa-file-text::before {
  content: "";
}

.fa-wave-square::before {
  content: "";
}

.fa-ring::before {
  content: "";
}

.fa-building-un::before {
  content: "";
}

.fa-dice-three::before {
  content: "";
}

.fa-tire-pressure-warning::before {
  content: "";
}

.fa-wifi-fair::before {
  content: "";
}

.fa-wifi-2::before {
  content: "";
}

.fa-calendar-days::before {
  content: "";
}

.fa-calendar-alt::before {
  content: "";
}

.fa-mp3-player::before {
  content: "";
}

.fa-anchor-circle-check::before {
  content: "";
}

.fa-tally-4::before {
  content: "";
}

.fa-rectangle-history::before {
  content: "";
}

.fa-building-circle-arrow-right::before {
  content: "";
}

.fa-volleyball::before {
  content: "";
}

.fa-volleyball-ball::before {
  content: "";
}

.fa-sun-haze::before {
  content: "";
}

.fa-text-size::before {
  content: "";
}

.fa-ufo::before {
  content: "";
}

.fa-fork::before {
  content: "";
}

.fa-utensil-fork::before {
  content: "";
}

.fa-arrows-up-to-line::before {
  content: "";
}

.fa-mobile-signal::before {
  content: "";
}

.fa-barcode-scan::before {
  content: "";
}

.fa-sort-down::before {
  content: "";
}

.fa-sort-desc::before {
  content: "";
}

.fa-folder-arrow-down::before {
  content: "";
}

.fa-folder-download::before {
  content: "";
}

.fa-circle-minus::before {
  content: "";
}

.fa-minus-circle::before {
  content: "";
}

.fa-face-icicles::before {
  content: "";
}

.fa-shovel::before {
  content: "";
}

.fa-door-open::before {
  content: "";
}

.fa-films::before {
  content: "";
}

.fa-right-from-bracket::before {
  content: "";
}

.fa-sign-out-alt::before {
  content: "";
}

.fa-face-glasses::before {
  content: "";
}

.fa-nfc::before {
  content: "";
}

.fa-atom::before {
  content: "";
}

.fa-soap::before {
  content: "";
}

.fa-icons::before {
  content: "";
}

.fa-heart-music-camera-bolt::before {
  content: "";
}

.fa-microphone-lines-slash::before {
  content: "";
}

.fa-microphone-alt-slash::before {
  content: "";
}

.fa-closed-captioning-slash::before {
  content: "";
}

.fa-calculator-simple::before {
  content: "";
}

.fa-calculator-alt::before {
  content: "";
}

.fa-bridge-circle-check::before {
  content: "";
}

.fa-sliders-up::before {
  content: "";
}

.fa-sliders-v::before {
  content: "";
}

.fa-location-minus::before {
  content: "";
}

.fa-map-marker-minus::before {
  content: "";
}

.fa-pump-medical::before {
  content: "";
}

.fa-fingerprint::before {
  content: "";
}

.fa-ski-boot::before {
  content: "";
}

.fa-standard-definition::before {
  content: "";
}

.fa-rectangle-sd::before {
  content: "";
}

.fa-h1::before {
  content: "";
}

.fa-hand-point-right::before {
  content: "";
}

.fa-magnifying-glass-location::before {
  content: "";
}

.fa-search-location::before {
  content: "";
}

.fa-message-bot::before {
  content: "";
}

.fa-forward-step::before {
  content: "";
}

.fa-step-forward::before {
  content: "";
}

.fa-face-smile-beam::before {
  content: "";
}

.fa-smile-beam::before {
  content: "";
}

.fa-light-ceiling::before {
  content: "";
}

.fa-message-exclamation::before {
  content: "";
}

.fa-comment-alt-exclamation::before {
  content: "";
}

.fa-bowl-scoop::before {
  content: "";
}

.fa-bowl-shaved-ice::before {
  content: "";
}

.fa-square-x::before {
  content: "";
}

.fa-utility-pole-double::before {
  content: "";
}

.fa-flag-checkered::before {
  content: "";
}

.fa-chevrons-up::before {
  content: "";
}

.fa-chevron-double-up::before {
  content: "";
}

.fa-football::before {
  content: "";
}

.fa-football-ball::before {
  content: "";
}

.fa-user-vneck::before {
  content: "";
}

.fa-school-circle-exclamation::before {
  content: "";
}

.fa-crop::before {
  content: "";
}

.fa-angles-down::before {
  content: "";
}

.fa-angle-double-down::before {
  content: "";
}

.fa-users-rectangle::before {
  content: "";
}

.fa-people-roof::before {
  content: "";
}

.fa-square-arrow-right::before {
  content: "";
}

.fa-arrow-square-right::before {
  content: "";
}

.fa-location-plus::before {
  content: "";
}

.fa-map-marker-plus::before {
  content: "";
}

.fa-lightbulb-exclamation-on::before {
  content: "";
}

.fa-people-line::before {
  content: "";
}

.fa-beer-mug-empty::before {
  content: "";
}

.fa-beer::before {
  content: "";
}

.fa-crate-empty::before {
  content: "";
}

.fa-diagram-predecessor::before {
  content: "";
}

.fa-transporter::before {
  content: "";
}

.fa-calendar-circle-user::before {
  content: "";
}

.fa-arrow-up-long::before {
  content: "";
}

.fa-long-arrow-up::before {
  content: "";
}

.fa-person-carry-box::before {
  content: "";
}

.fa-person-carry::before {
  content: "";
}

.fa-fire-flame-simple::before {
  content: "";
}

.fa-burn::before {
  content: "";
}

.fa-person::before {
  content: "";
}

.fa-male::before {
  content: "";
}

.fa-laptop::before {
  content: "";
}

.fa-file-csv::before {
  content: "";
}

.fa-menorah::before {
  content: "";
}

.fa-union::before {
  content: "";
}

.fa-chevrons-left::before {
  content: "";
}

.fa-chevron-double-left::before {
  content: "";
}

.fa-circle-heart::before {
  content: "";
}

.fa-heart-circle::before {
  content: "";
}

.fa-truck-plane::before {
  content: "";
}

.fa-record-vinyl::before {
  content: "";
}

.fa-bring-forward::before {
  content: "";
}

.fa-square-p::before {
  content: "";
}

.fa-face-grin-stars::before {
  content: "";
}

.fa-grin-stars::before {
  content: "";
}

.fa-sigma::before {
  content: "";
}

.fa-camera-movie::before {
  content: "";
}

.fa-bong::before {
  content: "";
}

.fa-clarinet::before {
  content: "";
}

.fa-truck-flatbed::before {
  content: "";
}

.fa-spaghetti-monster-flying::before {
  content: "";
}

.fa-pastafarianism::before {
  content: "";
}

.fa-arrow-down-up-across-line::before {
  content: "";
}

.fa-leaf-heart::before {
  content: "";
}

.fa-house-building::before {
  content: "";
}

.fa-cheese-swiss::before {
  content: "";
}

.fa-spoon::before {
  content: "";
}

.fa-utensil-spoon::before {
  content: "";
}

.fa-jar-wheat::before {
  content: "";
}

.fa-envelopes-bulk::before {
  content: "";
}

.fa-mail-bulk::before {
  content: "";
}

.fa-file-circle-exclamation::before {
  content: "";
}

.fa-bow-arrow::before {
  content: "";
}

.fa-cart-xmark::before {
  content: "";
}

.fa-hexagon-xmark::before {
  content: "";
}

.fa-times-hexagon::before {
  content: "";
}

.fa-xmark-hexagon::before {
  content: "";
}

.fa-circle-h::before {
  content: "";
}

.fa-hospital-symbol::before {
  content: "";
}

.fa-merge::before {
  content: "";
}

.fa-pager::before {
  content: "";
}

.fa-cart-minus::before {
  content: "";
}

.fa-address-book::before {
  content: "";
}

.fa-contact-book::before {
  content: "";
}

.fa-pan-frying::before {
  content: "";
}

.fa-grid::before {
  content: "";
}

.fa-grid-3::before {
  content: "";
}

.fa-football-helmet::before {
  content: "";
}

.fa-hand-love::before {
  content: "";
}

.fa-trees::before {
  content: "";
}

.fa-strikethrough::before {
  content: "";
}

.fa-page::before {
  content: "";
}

.fa-k::before {
  content: "K";
}

.fa-diagram-previous::before {
  content: "";
}

.fa-gauge-min::before {
  content: "";
}

.fa-tachometer-alt-slowest::before {
  content: "";
}

.fa-folder-grid::before {
  content: "";
}

.fa-eggplant::before {
  content: "";
}

.fa-ram::before {
  content: "";
}

.fa-landmark-flag::before {
  content: "";
}

.fa-lips::before {
  content: "";
}

.fa-pencil::before {
  content: "";
}

.fa-pencil-alt::before {
  content: "";
}

.fa-backward::before {
  content: "";
}

.fa-caret-right::before {
  content: "";
}

.fa-comments::before {
  content: "";
}

.fa-paste::before {
  content: "";
}

.fa-file-clipboard::before {
  content: "";
}

.fa-desktop-arrow-down::before {
  content: "";
}

.fa-code-pull-request::before {
  content: "";
}

.fa-pumpkin::before {
  content: "";
}

.fa-clipboard-list::before {
  content: "";
}

.fa-pen-field::before {
  content: "";
}

.fa-blueberries::before {
  content: "";
}

.fa-truck-ramp-box::before {
  content: "";
}

.fa-truck-loading::before {
  content: "";
}

.fa-note::before {
  content: "";
}

.fa-arrow-down-to-square::before {
  content: "";
}

.fa-user-check::before {
  content: "";
}

.fa-cloud-xmark::before {
  content: "";
}

.fa-vial-virus::before {
  content: "";
}

.fa-book-blank::before {
  content: "";
}

.fa-book-alt::before {
  content: "";
}

.fa-golf-flag-hole::before {
  content: "";
}

.fa-message-arrow-down::before {
  content: "";
}

.fa-comment-alt-arrow-down::before {
  content: "";
}

.fa-face-unamused::before {
  content: "";
}

.fa-sheet-plastic::before {
  content: "";
}

.fa-circle-9::before {
  content: "";
}

.fa-blog::before {
  content: "";
}

.fa-user-ninja::before {
  content: "";
}

.fa-pencil-slash::before {
  content: "";
}

.fa-bowling-pins::before {
  content: "";
}

.fa-person-arrow-up-from-line::before {
  content: "";
}

.fa-down-right::before {
  content: "";
}

.fa-scroll-torah::before {
  content: "";
}

.fa-torah::before {
  content: "";
}

.fa-webhook::before {
  content: "";
}

.fa-blinds-open::before {
  content: "";
}

.fa-fence::before {
  content: "";
}

.fa-up::before {
  content: "";
}

.fa-arrow-alt-up::before {
  content: "";
}

.fa-broom-ball::before {
  content: "";
}

.fa-quidditch::before {
  content: "";
}

.fa-quidditch-broom-ball::before {
  content: "";
}

.fa-drumstick::before {
  content: "";
}

.fa-square-v::before {
  content: "";
}

.fa-face-awesome::before {
  content: "";
}

.fa-gave-dandy::before {
  content: "";
}

.fa-dial-off::before {
  content: "";
}

.fa-toggle-off::before {
  content: "";
}

.fa-face-smile-horns::before {
  content: "";
}

.fa-box-archive::before {
  content: "";
}

.fa-archive::before {
  content: "";
}

.fa-grapes::before {
  content: "";
}

.fa-person-drowning::before {
  content: "";
}

.fa-dial-max::before {
  content: "";
}

.fa-circle-m::before {
  content: "";
}

.fa-calendar-image::before {
  content: "";
}

.fa-circle-caret-down::before {
  content: "";
}

.fa-caret-circle-down::before {
  content: "";
}

.fa-arrow-down-9-1::before {
  content: "";
}

.fa-sort-numeric-desc::before {
  content: "";
}

.fa-sort-numeric-down-alt::before {
  content: "";
}

.fa-face-grin-tongue-squint::before {
  content: "";
}

.fa-grin-tongue-squint::before {
  content: "";
}

.fa-shish-kebab::before {
  content: "";
}

.fa-spray-can::before {
  content: "";
}

.fa-alarm-snooze::before {
  content: "";
}

.fa-scarecrow::before {
  content: "";
}

.fa-truck-monster::before {
  content: "";
}

.fa-gift-card::before {
  content: "";
}

.fa-w::before {
  content: "W";
}

.fa-code-pull-request-draft::before {
  content: "";
}

.fa-square-b::before {
  content: "";
}

.fa-elephant::before {
  content: "";
}

.fa-earth-africa::before {
  content: "";
}

.fa-globe-africa::before {
  content: "";
}

.fa-rainbow::before {
  content: "";
}

.fa-circle-notch::before {
  content: "";
}

.fa-tablet-screen-button::before {
  content: "";
}

.fa-tablet-alt::before {
  content: "";
}

.fa-paw::before {
  content: "";
}

.fa-message-question::before {
  content: "";
}

.fa-cloud::before {
  content: "";
}

.fa-trowel-bricks::before {
  content: "";
}

.fa-square-3::before {
  content: "";
}

.fa-face-flushed::before {
  content: "";
}

.fa-flushed::before {
  content: "";
}

.fa-hospital-user::before {
  content: "";
}

.fa-microwave::before {
  content: "";
}

.fa-chf-sign::before {
  content: "";
}

.fa-tent-arrow-left-right::before {
  content: "";
}

.fa-cart-circle-arrow-up::before {
  content: "";
}

.fa-trash-clock::before {
  content: "";
}

.fa-gavel::before {
  content: "";
}

.fa-legal::before {
  content: "";
}

.fa-sprinkler-ceiling::before {
  content: "";
}

.fa-browsers::before {
  content: "";
}

.fa-trillium::before {
  content: "";
}

.fa-music-slash::before {
  content: "";
}

.fa-truck-ramp::before {
  content: "";
}

.fa-binoculars::before {
  content: "";
}

.fa-microphone-slash::before {
  content: "";
}

.fa-box-tissue::before {
  content: "";
}

.fa-circle-c::before {
  content: "";
}

.fa-star-christmas::before {
  content: "";
}

.fa-chart-bullet::before {
  content: "";
}

.fa-motorcycle::before {
  content: "";
}

.fa-tree-christmas::before {
  content: "";
}

.fa-tire-flat::before {
  content: "";
}

.fa-sunglasses::before {
  content: "";
}

.fa-badge::before {
  content: "";
}

.fa-message-pen::before {
  content: "";
}

.fa-comment-alt-edit::before {
  content: "";
}

.fa-message-edit::before {
  content: "";
}

.fa-bell-concierge::before {
  content: "";
}

.fa-concierge-bell::before {
  content: "";
}

.fa-pen-ruler::before {
  content: "";
}

.fa-pencil-ruler::before {
  content: "";
}

.fa-arrow-progress::before {
  content: "";
}

.fa-chess-rook-piece::before {
  content: "";
}

.fa-chess-rook-alt::before {
  content: "";
}

.fa-square-root::before {
  content: "";
}

.fa-album-collection-circle-plus::before {
  content: "";
}

.fa-people-arrows::before {
  content: "";
}

.fa-people-arrows-left-right::before {
  content: "";
}

.fa-face-angry-horns::before {
  content: "";
}

.fa-mars-and-venus-burst::before {
  content: "";
}

.fa-tombstone::before {
  content: "";
}

.fa-square-caret-right::before {
  content: "";
}

.fa-caret-square-right::before {
  content: "";
}

.fa-scissors::before {
  content: "";
}

.fa-cut::before {
  content: "";
}

.fa-list-music::before {
  content: "";
}

.fa-sun-plant-wilt::before {
  content: "";
}

.fa-toilets-portable::before {
  content: "";
}

.fa-hockey-puck::before {
  content: "";
}

.fa-mustache::before {
  content: "";
}

.fa-hyphen::before {
  content: "\-";
}

.fa-table::before {
  content: "";
}

.fa-user-chef::before {
  content: "";
}

.fa-message-image::before {
  content: "";
}

.fa-comment-alt-image::before {
  content: "";
}

.fa-users-medical::before {
  content: "";
}

.fa-sensor-triangle-exclamation::before {
  content: "";
}

.fa-sensor-alert::before {
  content: "";
}

.fa-magnifying-glass-arrow-right::before {
  content: "";
}

.fa-tachograph-digital::before {
  content: "";
}

.fa-digital-tachograph::before {
  content: "";
}

.fa-face-mask::before {
  content: "";
}

.fa-pickleball::before {
  content: "";
}

.fa-star-sharp-half::before {
  content: "";
}

.fa-users-slash::before {
  content: "";
}

.fa-clover::before {
  content: "";
}

.fa-meat::before {
  content: "";
}

.fa-reply::before {
  content: "";
}

.fa-mail-reply::before {
  content: "";
}

.fa-star-and-crescent::before {
  content: "";
}

.fa-empty-set::before {
  content: "";
}

.fa-house-fire::before {
  content: "";
}

.fa-square-minus::before {
  content: "";
}

.fa-minus-square::before {
  content: "";
}

.fa-helicopter::before {
  content: "";
}

.fa-bird::before {
  content: "";
}

.fa-compass::before {
  content: "";
}

.fa-square-caret-down::before {
  content: "";
}

.fa-caret-square-down::before {
  content: "";
}

.fa-heart-half-stroke::before {
  content: "";
}

.fa-heart-half-alt::before {
  content: "";
}

.fa-file-circle-question::before {
  content: "";
}

.fa-laptop-code::before {
  content: "";
}

.fa-joystick::before {
  content: "";
}

.fa-grill-fire::before {
  content: "";
}

.fa-rectangle-vertical-history::before {
  content: "";
}

.fa-swatchbook::before {
  content: "";
}

.fa-prescription-bottle::before {
  content: "";
}

.fa-bars::before {
  content: "";
}

.fa-navicon::before {
  content: "";
}

.fa-keyboard-left::before {
  content: "";
}

.fa-people-group::before {
  content: "";
}

.fa-hourglass-end::before {
  content: "";
}

.fa-hourglass-3::before {
  content: "";
}

.fa-heart-crack::before {
  content: "";
}

.fa-heart-broken::before {
  content: "";
}

.fa-face-beam-hand-over-mouth::before {
  content: "";
}

.fa-droplet-percent::before {
  content: "";
}

.fa-humidity::before {
  content: "";
}

.fa-square-up-right::before {
  content: "";
}

.fa-external-link-square-alt::before {
  content: "";
}

.fa-face-kiss-beam::before {
  content: "";
}

.fa-kiss-beam::before {
  content: "";
}

.fa-corn::before {
  content: "";
}

.fa-roller-coaster::before {
  content: "";
}

.fa-photo-film-music::before {
  content: "";
}

.fa-radar::before {
  content: "";
}

.fa-sickle::before {
  content: "";
}

.fa-film::before {
  content: "";
}

.fa-coconut::before {
  content: "";
}

.fa-ruler-horizontal::before {
  content: "";
}

.fa-shield-cross::before {
  content: "";
}

.fa-cassette-tape::before {
  content: "";
}

.fa-square-terminal::before {
  content: "";
}

.fa-people-robbery::before {
  content: "";
}

.fa-lightbulb::before {
  content: "";
}

.fa-caret-left::before {
  content: "";
}

.fa-comment-middle::before {
  content: "";
}

.fa-trash-can-list::before {
  content: "";
}

.fa-block::before {
  content: "";
}

.fa-circle-exclamation::before {
  content: "";
}

.fa-exclamation-circle::before {
  content: "";
}

.fa-school-circle-xmark::before {
  content: "";
}

.fa-arrow-right-from-bracket::before {
  content: "";
}

.fa-sign-out::before {
  content: "";
}

.fa-face-frown-slight::before {
  content: "";
}

.fa-circle-chevron-down::before {
  content: "";
}

.fa-chevron-circle-down::before {
  content: "";
}

.fa-sidebar-flip::before {
  content: "";
}

.fa-unlock-keyhole::before {
  content: "";
}

.fa-unlock-alt::before {
  content: "";
}

.fa-temperature-list::before {
  content: "";
}

.fa-cloud-showers-heavy::before {
  content: "";
}

.fa-headphones-simple::before {
  content: "";
}

.fa-headphones-alt::before {
  content: "";
}

.fa-sitemap::before {
  content: "";
}

.fa-pipe-section::before {
  content: "";
}

.fa-space-station-moon-construction::before {
  content: "";
}

.fa-space-station-moon-alt::before {
  content: "";
}

.fa-circle-dollar-to-slot::before {
  content: "";
}

.fa-donate::before {
  content: "";
}

.fa-memory::before {
  content: "";
}

.fa-face-sleeping::before {
  content: "";
}

.fa-road-spikes::before {
  content: "";
}

.fa-fire-burner::before {
  content: "";
}

.fa-squirrel::before {
  content: "";
}

.fa-arrow-up-to-line::before {
  content: "";
}

.fa-arrow-to-top::before {
  content: "";
}

.fa-flag::before {
  content: "";
}

.fa-face-cowboy-hat::before {
  content: "";
}

.fa-hanukiah::before {
  content: "";
}

.fa-chart-scatter-3d::before {
  content: "";
}

.fa-display-chart-up::before {
  content: "";
}

.fa-square-code::before {
  content: "";
}

.fa-feather::before {
  content: "";
}

.fa-volume-low::before {
  content: "";
}

.fa-volume-down::before {
  content: "";
}

.fa-xmark-to-slot::before {
  content: "";
}

.fa-times-to-slot::before {
  content: "";
}

.fa-vote-nay::before {
  content: "";
}

.fa-box-taped::before {
  content: "";
}

.fa-box-alt::before {
  content: "";
}

.fa-comment-slash::before {
  content: "";
}

.fa-swords::before {
  content: "";
}

.fa-cloud-sun-rain::before {
  content: "";
}

.fa-album::before {
  content: "";
}

.fa-circle-n::before {
  content: "";
}

.fa-compress::before {
  content: "";
}

.fa-wheat-awn::before {
  content: "";
}

.fa-wheat-alt::before {
  content: "";
}

.fa-ankh::before {
  content: "";
}

.fa-hands-holding-child::before {
  content: "";
}

.fa-asterisk::before {
  content: "\*";
}

.fa-key-skeleton-left-right::before {
  content: "";
}

.fa-comment-lines::before {
  content: "";
}

.fa-luchador-mask::before {
  content: "";
}

.fa-luchador::before {
  content: "";
}

.fa-mask-luchador::before {
  content: "";
}

.fa-square-check::before {
  content: "";
}

.fa-check-square::before {
  content: "";
}

.fa-shredder::before {
  content: "";
}

.fa-book-open-cover::before {
  content: "";
}

.fa-book-open-alt::before {
  content: "";
}

.fa-sandwich::before {
  content: "";
}

.fa-peseta-sign::before {
  content: "";
}

.fa-square-parking-slash::before {
  content: "";
}

.fa-parking-slash::before {
  content: "";
}

.fa-train-tunnel::before {
  content: "";
}

.fa-heading::before {
  content: "";
}

.fa-header::before {
  content: "";
}

.fa-ghost::before {
  content: "";
}

.fa-face-anguished::before {
  content: "";
}

.fa-hockey-sticks::before {
  content: "";
}

.fa-abacus::before {
  content: "";
}

.fa-film-simple::before {
  content: "";
}

.fa-film-alt::before {
  content: "";
}

.fa-list::before {
  content: "";
}

.fa-list-squares::before {
  content: "";
}

.fa-tree-palm::before {
  content: "";
}

.fa-square-phone-flip::before {
  content: "";
}

.fa-phone-square-alt::before {
  content: "";
}

.fa-cart-plus::before {
  content: "";
}

.fa-gamepad::before {
  content: "";
}

.fa-border-center-v::before {
  content: "";
}

.fa-circle-dot::before {
  content: "";
}

.fa-dot-circle::before {
  content: "";
}

.fa-clipboard-medical::before {
  content: "";
}

.fa-face-dizzy::before {
  content: "";
}

.fa-dizzy::before {
  content: "";
}

.fa-egg::before {
  content: "";
}

.fa-up-to-line::before {
  content: "";
}

.fa-arrow-alt-to-top::before {
  content: "";
}

.fa-house-medical-circle-xmark::before {
  content: "";
}

.fa-watch-fitness::before {
  content: "";
}

.fa-clock-nine-thirty::before {
  content: "";
}

.fa-campground::before {
  content: "";
}

.fa-folder-plus::before {
  content: "";
}

.fa-jug::before {
  content: "";
}

.fa-futbol::before {
  content: "";
}

.fa-futbol-ball::before {
  content: "";
}

.fa-soccer-ball::before {
  content: "";
}

.fa-snow-blowing::before {
  content: "";
}

.fa-paintbrush::before {
  content: "";
}

.fa-paint-brush::before {
  content: "";
}

.fa-lock::before {
  content: "";
}

.fa-arrow-down-from-line::before {
  content: "";
}

.fa-arrow-from-top::before {
  content: "";
}

.fa-gas-pump::before {
  content: "";
}

.fa-signal-bars-slash::before {
  content: "";
}

.fa-signal-alt-slash::before {
  content: "";
}

.fa-monkey::before {
  content: "";
}

.fa-rectangle-pro::before {
  content: "";
}

.fa-pro::before {
  content: "";
}

.fa-house-night::before {
  content: "";
}

.fa-hot-tub-person::before {
  content: "";
}

.fa-hot-tub::before {
  content: "";
}

.fa-blanket::before {
  content: "";
}

.fa-map-location::before {
  content: "";
}

.fa-map-marked::before {
  content: "";
}

.fa-house-flood-water::before {
  content: "";
}

.fa-comments-question-check::before {
  content: "";
}

.fa-tree::before {
  content: "";
}

.fa-arrows-cross::before {
  content: "";
}

.fa-backpack::before {
  content: "";
}

.fa-square-small::before {
  content: "";
}

.fa-folder-arrow-up::before {
  content: "";
}

.fa-folder-upload::before {
  content: "";
}

.fa-bridge-lock::before {
  content: "";
}

.fa-crosshairs-simple::before {
  content: "";
}

.fa-sack-dollar::before {
  content: "";
}

.fa-pen-to-square::before {
  content: "";
}

.fa-edit::before {
  content: "";
}

.fa-square-sliders::before {
  content: "";
}

.fa-sliders-h-square::before {
  content: "";
}

.fa-car-side::before {
  content: "";
}

.fa-message-middle-top::before {
  content: "";
}

.fa-comment-middle-top-alt::before {
  content: "";
}

.fa-lightbulb-on::before {
  content: "";
}

.fa-knife::before {
  content: "";
}

.fa-utensil-knife::before {
  content: "";
}

.fa-share-nodes::before {
  content: "";
}

.fa-share-alt::before {
  content: "";
}

.fa-display-chart-up-circle-dollar::before {
  content: "";
}

.fa-wave-sine::before {
  content: "";
}

.fa-heart-circle-minus::before {
  content: "";
}

.fa-circle-w::before {
  content: "";
}

.fa-circle-calendar::before {
  content: "";
}

.fa-calendar-circle::before {
  content: "";
}

.fa-hourglass-half::before {
  content: "";
}

.fa-hourglass-2::before {
  content: "";
}

.fa-microscope::before {
  content: "";
}

.fa-sunset::before {
  content: "";
}

.fa-sink::before {
  content: "";
}

.fa-calendar-exclamation::before {
  content: "";
}

.fa-truck-container-empty::before {
  content: "";
}

.fa-hand-heart::before {
  content: "";
}

.fa-bag-shopping::before {
  content: "";
}

.fa-shopping-bag::before {
  content: "";
}

.fa-arrow-down-z-a::before {
  content: "";
}

.fa-sort-alpha-desc::before {
  content: "";
}

.fa-sort-alpha-down-alt::before {
  content: "";
}

.fa-mitten::before {
  content: "";
}

.fa-reply-clock::before {
  content: "";
}

.fa-reply-time::before {
  content: "";
}

.fa-person-rays::before {
  content: "";
}

.fa-right::before {
  content: "";
}

.fa-arrow-alt-right::before {
  content: "";
}

.fa-circle-f::before {
  content: "";
}

.fa-users::before {
  content: "";
}

.fa-face-pleading::before {
  content: "";
}

.fa-eye-slash::before {
  content: "";
}

.fa-flask-vial::before {
  content: "";
}

.fa-police-box::before {
  content: "";
}

.fa-cucumber::before {
  content: "";
}

.fa-head-side-brain::before {
  content: "";
}

.fa-hand::before {
  content: "";
}

.fa-hand-paper::before {
  content: "";
}

.fa-person-biking-mountain::before {
  content: "";
}

.fa-biking-mountain::before {
  content: "";
}

.fa-utensils-slash::before {
  content: "";
}

.fa-print-magnifying-glass::before {
  content: "";
}

.fa-print-search::before {
  content: "";
}

.fa-folder-bookmark::before {
  content: "";
}

.fa-om::before {
  content: "";
}

.fa-pi::before {
  content: "";
}

.fa-flask-round-potion::before {
  content: "";
}

.fa-flask-potion::before {
  content: "";
}

.fa-face-shush::before {
  content: "";
}

.fa-worm::before {
  content: "";
}

.fa-house-circle-xmark::before {
  content: "";
}

.fa-plug::before {
  content: "";
}

.fa-calendar-circle-exclamation::before {
  content: "";
}

.fa-square-i::before {
  content: "";
}

.fa-chevron-up::before {
  content: "";
}

.fa-face-saluting::before {
  content: "";
}

.fa-gauge-simple-low::before {
  content: "";
}

.fa-tachometer-slow::before {
  content: "";
}

.fa-face-persevering::before {
  content: "";
}

.fa-circle-camera::before {
  content: "";
}

.fa-camera-circle::before {
  content: "";
}

.fa-hand-spock::before {
  content: "";
}

.fa-spider-web::before {
  content: "";
}

.fa-circle-microphone::before {
  content: "";
}

.fa-microphone-circle::before {
  content: "";
}

.fa-book-arrow-up::before {
  content: "";
}

.fa-popsicle::before {
  content: "";
}

.fa-command::before {
  content: "";
}

.fa-blinds::before {
  content: "";
}

.fa-stopwatch::before {
  content: "";
}

.fa-saxophone::before {
  content: "";
}

.fa-square-2::before {
  content: "";
}

.fa-field-hockey-stick-ball::before {
  content: "";
}

.fa-field-hockey::before {
  content: "";
}

.fa-arrow-up-square-triangle::before {
  content: "";
}

.fa-sort-shapes-up-alt::before {
  content: "";
}

.fa-face-scream::before {
  content: "";
}

.fa-square-m::before {
  content: "";
}

.fa-camera-web::before {
  content: "";
}

.fa-webcam::before {
  content: "";
}

.fa-comment-arrow-down::before {
  content: "";
}

.fa-lightbulb-cfl::before {
  content: "";
}

.fa-window-frame-open::before {
  content: "";
}

.fa-face-kiss::before {
  content: "";
}

.fa-kiss::before {
  content: "";
}

.fa-bridge-circle-xmark::before {
  content: "";
}

.fa-period::before {
  content: "\.";
}

.fa-face-grin-tongue::before {
  content: "";
}

.fa-grin-tongue::before {
  content: "";
}

.fa-up-to-dotted-line::before {
  content: "";
}

.fa-thought-bubble::before {
  content: "";
}

.fa-skeleton-ribs::before {
  content: "";
}

.fa-raygun::before {
  content: "";
}

.fa-flute::before {
  content: "";
}

.fa-acorn::before {
  content: "";
}

.fa-video-arrow-up-right::before {
  content: "";
}

.fa-grate-droplet::before {
  content: "";
}

.fa-seal-exclamation::before {
  content: "";
}

.fa-chess-bishop::before {
  content: "";
}

.fa-message-sms::before {
  content: "";
}

.fa-coffee-beans::before {
  content: "";
}

.fa-hat-witch::before {
  content: "";
}

.fa-face-grin-wink::before {
  content: "";
}

.fa-grin-wink::before {
  content: "";
}

.fa-clock-three-thirty::before {
  content: "";
}

.fa-ear-deaf::before {
  content: "";
}

.fa-deaf::before {
  content: "";
}

.fa-deafness::before {
  content: "";
}

.fa-hard-of-hearing::before {
  content: "";
}

.fa-alarm-clock::before {
  content: "";
}

.fa-eclipse::before {
  content: "";
}

.fa-face-relieved::before {
  content: "";
}

.fa-road-circle-check::before {
  content: "";
}

.fa-dice-five::before {
  content: "";
}

.fa-octagon-minus::before {
  content: "";
}

.fa-minus-octagon::before {
  content: "";
}

.fa-square-rss::before {
  content: "";
}

.fa-rss-square::before {
  content: "";
}

.fa-face-zany::before {
  content: "";
}

.fa-tricycle::before {
  content: "";
}

.fa-land-mine-on::before {
  content: "";
}

.fa-square-arrow-up-left::before {
  content: "";
}

.fa-i-cursor::before {
  content: "";
}

.fa-chart-mixed-up-circle-dollar::before {
  content: "";
}

.fa-salt-shaker::before {
  content: "";
}

.fa-stamp::before {
  content: "";
}

.fa-file-plus::before {
  content: "";
}

.fa-draw-square::before {
  content: "";
}

.fa-toilet-paper-under-slash::before {
  content: "";
}

.fa-toilet-paper-reverse-slash::before {
  content: "";
}

.fa-stairs::before {
  content: "";
}

.fa-drone-front::before {
  content: "";
}

.fa-drone-alt::before {
  content: "";
}

.fa-glass-empty::before {
  content: "";
}

.fa-dial-high::before {
  content: "";
}

.fa-user-helmet-safety::before {
  content: "";
}

.fa-user-construction::before {
  content: "";
}

.fa-user-hard-hat::before {
  content: "";
}

.fa-i::before {
  content: "I";
}

.fa-hryvnia-sign::before {
  content: "";
}

.fa-hryvnia::before {
  content: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "";
}

.fa-pills::before {
  content: "";
}

.fa-face-grin-wide::before {
  content: "";
}

.fa-grin-alt::before {
  content: "";
}

.fa-tooth::before {
  content: "";
}

.fa-basketball-hoop::before {
  content: "";
}

.fa-objects-align-bottom::before {
  content: "";
}

.fa-v::before {
  content: "V";
}

.fa-sparkles::before {
  content: "";
}

.fa-squid::before {
  content: "";
}

.fa-leafy-green::before {
  content: "";
}

.fa-circle-arrow-up-right::before {
  content: "";
}

.fa-calendars::before {
  content: "";
}

.fa-bangladeshi-taka-sign::before {
  content: "";
}

.fa-bicycle::before {
  content: "";
}

.fa-hammer-war::before {
  content: "";
}

.fa-circle-d::before {
  content: "";
}

.fa-spider-black-widow::before {
  content: "";
}

.fa-staff-snake::before {
  content: "";
}

.fa-rod-asclepius::before {
  content: "";
}

.fa-rod-snake::before {
  content: "";
}

.fa-staff-aesculapius::before {
  content: "";
}

.fa-pear::before {
  content: "";
}

.fa-head-side-cough-slash::before {
  content: "";
}

.fa-triangle::before {
  content: "";
}

.fa-apartment::before {
  content: "";
}

.fa-truck-medical::before {
  content: "";
}

.fa-ambulance::before {
  content: "";
}

.fa-pepper::before {
  content: "";
}

.fa-piano::before {
  content: "";
}

.fa-gun-squirt::before {
  content: "";
}

.fa-wheat-awn-circle-exclamation::before {
  content: "";
}

.fa-snowman::before {
  content: "";
}

.fa-user-alien::before {
  content: "";
}

.fa-shield-check::before {
  content: "";
}

.fa-mortar-pestle::before {
  content: "";
}

.fa-road-barrier::before {
  content: "";
}

.fa-chart-candlestick::before {
  content: "";
}

.fa-briefcase-blank::before {
  content: "";
}

.fa-school::before {
  content: "";
}

.fa-igloo::before {
  content: "";
}

.fa-bracket-round::before {
  content: "\(";
}

.fa-parenthesis::before {
  content: "\(";
}

.fa-joint::before {
  content: "";
}

.fa-horse-saddle::before {
  content: "";
}

.fa-mug-marshmallows::before {
  content: "";
}

.fa-filters::before {
  content: "";
}

.fa-bell-on::before {
  content: "";
}

.fa-angle-right::before {
  content: "";
}

.fa-dial-med::before {
  content: "";
}

.fa-horse::before {
  content: "";
}

.fa-q::before {
  content: "Q";
}

.fa-monitor-waveform::before {
  content: "";
}

.fa-monitor-heart-rate::before {
  content: "";
}

.fa-link-simple::before {
  content: "";
}

.fa-whistle::before {
  content: "";
}

.fa-g::before {
  content: "G";
}

.fa-wine-glass-crack::before {
  content: "";
}

.fa-fragile::before {
  content: "";
}

.fa-slot-machine::before {
  content: "";
}

.fa-notes-medical::before {
  content: "";
}

.fa-car-wash::before {
  content: "";
}

.fa-escalator::before {
  content: "";
}

.fa-comment-image::before {
  content: "";
}

.fa-temperature-half::before {
  content: "";
}

.fa-temperature-2::before {
  content: "";
}

.fa-thermometer-2::before {
  content: "";
}

.fa-thermometer-half::before {
  content: "";
}

.fa-dong-sign::before {
  content: "";
}

.fa-donut::before {
  content: "";
}

.fa-doughnut::before {
  content: "";
}

.fa-capsules::before {
  content: "";
}

.fa-poo-storm::before {
  content: "";
}

.fa-poo-bolt::before {
  content: "";
}

.fa-tally-1::before {
  content: "";
}

.fa-face-frown-open::before {
  content: "";
}

.fa-frown-open::before {
  content: "";
}

.fa-square-dashed::before {
  content: "";
}

.fa-square-j::before {
  content: "";
}

.fa-hand-point-up::before {
  content: "";
}

.fa-money-bill::before {
  content: "";
}

.fa-arrow-up-big-small::before {
  content: "";
}

.fa-sort-size-up::before {
  content: "";
}

.fa-barcode-read::before {
  content: "";
}

.fa-baguette::before {
  content: "";
}

.fa-bowl-soft-serve::before {
  content: "";
}

.fa-face-holding-back-tears::before {
  content: "";
}

.fa-square-up::before {
  content: "";
}

.fa-arrow-alt-square-up::before {
  content: "";
}

.fa-train-subway-tunnel::before {
  content: "";
}

.fa-subway-tunnel::before {
  content: "";
}

.fa-square-exclamation::before {
  content: "";
}

.fa-exclamation-square::before {
  content: "";
}

.fa-semicolon::before {
  content: "\;";
}

.fa-bookmark::before {
  content: "";
}

.fa-fan-table::before {
  content: "";
}

.fa-align-justify::before {
  content: "";
}

.fa-battery-low::before {
  content: "";
}

.fa-battery-1::before {
  content: "";
}

.fa-credit-card-front::before {
  content: "";
}

.fa-brain-arrow-curved-right::before {
  content: "";
}

.fa-mind-share::before {
  content: "";
}

.fa-umbrella-beach::before {
  content: "";
}

.fa-helmet-un::before {
  content: "";
}

.fa-location-smile::before {
  content: "";
}

.fa-map-marker-smile::before {
  content: "";
}

.fa-arrow-left-to-line::before {
  content: "";
}

.fa-arrow-to-left::before {
  content: "";
}

.fa-bullseye::before {
  content: "";
}

.fa-sushi::before {
  content: "";
}

.fa-nigiri::before {
  content: "";
}

.fa-message-captions::before {
  content: "";
}

.fa-comment-alt-captions::before {
  content: "";
}

.fa-trash-list::before {
  content: "";
}

.fa-bacon::before {
  content: "";
}

.fa-option::before {
  content: "";
}

.fa-hand-point-down::before {
  content: "";
}

.fa-arrow-up-from-bracket::before {
  content: "";
}

.fa-trash-plus::before {
  content: "";
}

.fa-objects-align-top::before {
  content: "";
}

.fa-folder::before {
  content: "";
}

.fa-folder-blank::before {
  content: "";
}

.fa-face-anxious-sweat::before {
  content: "";
}

.fa-credit-card-blank::before {
  content: "";
}

.fa-file-waveform::before {
  content: "";
}

.fa-file-medical-alt::before {
  content: "";
}

.fa-microchip-ai::before {
  content: "";
}

.fa-mug::before {
  content: "";
}

.fa-plane-up-slash::before {
  content: "";
}

.fa-radiation::before {
  content: "";
}

.fa-pen-circle::before {
  content: "";
}

.fa-bag-seedling::before {
  content: "";
}

.fa-chart-simple::before {
  content: "";
}

.fa-crutches::before {
  content: "";
}

.fa-circle-parking::before {
  content: "";
}

.fa-parking-circle::before {
  content: "";
}

.fa-mars-stroke::before {
  content: "";
}

.fa-leaf-oak::before {
  content: "";
}

.fa-square-bolt::before {
  content: "";
}

.fa-vial::before {
  content: "";
}

.fa-gauge::before {
  content: "";
}

.fa-dashboard::before {
  content: "";
}

.fa-gauge-med::before {
  content: "";
}

.fa-tachometer-alt-average::before {
  content: "";
}

.fa-wand-magic-sparkles::before {
  content: "";
}

.fa-magic-wand-sparkles::before {
  content: "";
}

.fa-lambda::before {
  content: "";
}

.fa-e::before {
  content: "E";
}

.fa-pizza::before {
  content: "";
}

.fa-bowl-chopsticks-noodles::before {
  content: "";
}

.fa-h3::before {
  content: "";
}

.fa-pen-clip::before {
  content: "";
}

.fa-pen-alt::before {
  content: "";
}

.fa-bridge-circle-exclamation::before {
  content: "";
}

.fa-badge-percent::before {
  content: "";
}

.fa-user::before {
  content: "";
}

.fa-sensor::before {
  content: "";
}

.fa-comma::before {
  content: "\,";
}

.fa-school-circle-check::before {
  content: "";
}

.fa-toilet-paper-under::before {
  content: "";
}

.fa-toilet-paper-reverse::before {
  content: "";
}

.fa-light-emergency::before {
  content: "";
}

.fa-arrow-down-to-arc::before {
  content: "";
}

.fa-dumpster::before {
  content: "";
}

.fa-van-shuttle::before {
  content: "";
}

.fa-shuttle-van::before {
  content: "";
}

.fa-building-user::before {
  content: "";
}

.fa-light-switch::before {
  content: "";
}

.fa-square-caret-left::before {
  content: "";
}

.fa-caret-square-left::before {
  content: "";
}

.fa-highlighter::before {
  content: "";
}

.fa-wave-pulse::before {
  content: "";
}

.fa-heart-rate::before {
  content: "";
}

.fa-key::before {
  content: "";
}

.fa-hat-santa::before {
  content: "";
}

.fa-tamale::before {
  content: "";
}

.fa-box-check::before {
  content: "";
}

.fa-bullhorn::before {
  content: "";
}

.fa-steak::before {
  content: "";
}

.fa-location-crosshairs-slash::before {
  content: "";
}

.fa-location-slash::before {
  content: "";
}

.fa-person-dolly::before {
  content: "";
}

.fa-globe::before {
  content: "";
}

.fa-synagogue::before {
  content: "";
}

.fa-file-chart-column::before {
  content: "";
}

.fa-file-chart-line::before {
  content: "";
}

.fa-person-half-dress::before {
  content: "";
}

.fa-folder-image::before {
  content: "";
}

.fa-calendar-pen::before {
  content: "";
}

.fa-calendar-edit::before {
  content: "";
}

.fa-road-bridge::before {
  content: "";
}

.fa-face-smile-tear::before {
  content: "";
}

.fa-message-plus::before {
  content: "";
}

.fa-comment-alt-plus::before {
  content: "";
}

.fa-location-arrow::before {
  content: "";
}

.fa-c::before {
  content: "C";
}

.fa-tablet-button::before {
  content: "";
}

.fa-rectangle-history-circle-user::before {
  content: "";
}

.fa-building-lock::before {
  content: "";
}

.fa-chart-line-up::before {
  content: "";
}

.fa-mailbox::before {
  content: "";
}

.fa-truck-bolt::before {
  content: "";
}

.fa-pizza-slice::before {
  content: "";
}

.fa-money-bill-wave::before {
  content: "";
}

.fa-chart-area::before {
  content: "";
}

.fa-area-chart::before {
  content: "";
}

.fa-house-flag::before {
  content: "";
}

.fa-circle-three-quarters-stroke::before {
  content: "";
}

.fa-person-circle-minus::before {
  content: "";
}

.fa-scalpel::before {
  content: "";
}

.fa-ban::before {
  content: "";
}

.fa-cancel::before {
  content: "";
}

.fa-bell-exclamation::before {
  content: "";
}

.fa-circle-bookmark::before {
  content: "";
}

.fa-bookmark-circle::before {
  content: "";
}

.fa-egg-fried::before {
  content: "";
}

.fa-face-weary::before {
  content: "";
}

.fa-uniform-martial-arts::before {
  content: "";
}

.fa-camera-rotate::before {
  content: "";
}

.fa-sun-dust::before {
  content: "";
}

.fa-comment-text::before {
  content: "";
}

.fa-spray-can-sparkles::before {
  content: "";
}

.fa-air-freshener::before {
  content: "";
}

.fa-signal-bars::before {
  content: "";
}

.fa-signal-alt::before {
  content: "";
}

.fa-signal-alt-4::before {
  content: "";
}

.fa-signal-bars-strong::before {
  content: "";
}

.fa-diamond-exclamation::before {
  content: "";
}

.fa-star::before {
  content: "";
}

.fa-dial-min::before {
  content: "";
}

.fa-repeat::before {
  content: "";
}

.fa-cross::before {
  content: "";
}

.fa-page-caret-down::before {
  content: "";
}

.fa-file-caret-down::before {
  content: "";
}

.fa-box::before {
  content: "";
}

.fa-venus-mars::before {
  content: "";
}

.fa-clock-seven-thirty::before {
  content: "";
}

.fa-arrow-pointer::before {
  content: "";
}

.fa-mouse-pointer::before {
  content: "";
}

.fa-clock-four-thirty::before {
  content: "";
}

.fa-signal-bars-good::before {
  content: "";
}

.fa-signal-alt-3::before {
  content: "";
}

.fa-cactus::before {
  content: "";
}

.fa-lightbulb-gear::before {
  content: "";
}

.fa-maximize::before {
  content: "";
}

.fa-expand-arrows-alt::before {
  content: "";
}

.fa-charging-station::before {
  content: "";
}

.fa-shapes::before {
  content: "";
}

.fa-triangle-circle-square::before {
  content: "";
}

.fa-plane-tail::before {
  content: "";
}

.fa-gauge-simple-max::before {
  content: "";
}

.fa-tachometer-fastest::before {
  content: "";
}

.fa-circle-u::before {
  content: "";
}

.fa-shield-slash::before {
  content: "";
}

.fa-square-phone-hangup::before {
  content: "";
}

.fa-phone-square-down::before {
  content: "";
}

.fa-arrow-up-left::before {
  content: "";
}

.fa-transporter-1::before {
  content: "";
}

.fa-peanuts::before {
  content: "";
}

.fa-shuffle::before {
  content: "";
}

.fa-random::before {
  content: "";
}

.fa-person-running::before {
  content: "";
}

.fa-running::before {
  content: "";
}

.fa-mobile-retro::before {
  content: "";
}

.fa-grip-lines-vertical::before {
  content: "";
}

.fa-bin-bottles-recycle::before {
  content: "";
}

.fa-arrow-up-from-square::before {
  content: "";
}

.fa-file-dashed-line::before {
  content: "";
}

.fa-page-break::before {
  content: "";
}

.fa-bracket-curly-right::before {
  content: "\}";
}

.fa-spider::before {
  content: "";
}

.fa-clock-three::before {
  content: "";
}

.fa-hands-bound::before {
  content: "";
}

.fa-scalpel-line-dashed::before {
  content: "";
}

.fa-scalpel-path::before {
  content: "";
}

.fa-file-invoice-dollar::before {
  content: "";
}

.fa-pipe-smoking::before {
  content: "";
}

.fa-face-astonished::before {
  content: "";
}

.fa-window::before {
  content: "";
}

.fa-plane-circle-exclamation::before {
  content: "";
}

.fa-ear::before {
  content: "";
}

.fa-file-lock::before {
  content: "";
}

.fa-diagram-venn::before {
  content: "";
}

.fa-x-ray::before {
  content: "";
}

.fa-goal-net::before {
  content: "";
}

.fa-coffin-cross::before {
  content: "";
}

.fa-spell-check::before {
  content: "";
}

.fa-location-xmark::before {
  content: "";
}

.fa-map-marker-times::before {
  content: "";
}

.fa-map-marker-xmark::before {
  content: "";
}

.fa-circle-quarter-stroke::before {
  content: "";
}

.fa-lasso::before {
  content: "";
}

.fa-slash::before {
  content: "";
}

.fa-person-to-portal::before {
  content: "";
}

.fa-portal-enter::before {
  content: "";
}

.fa-calendar-star::before {
  content: "";
}

.fa-computer-mouse::before {
  content: "";
}

.fa-mouse::before {
  content: "";
}

.fa-arrow-right-to-bracket::before {
  content: "";
}

.fa-sign-in::before {
  content: "";
}

.fa-pegasus::before {
  content: "";
}

.fa-files-medical::before {
  content: "";
}

.fa-nfc-lock::before {
  content: "";
}

.fa-person-ski-lift::before {
  content: "";
}

.fa-ski-lift::before {
  content: "";
}

.fa-square-6::before {
  content: "";
}

.fa-shop-slash::before {
  content: "";
}

.fa-store-alt-slash::before {
  content: "";
}

.fa-wind-turbine::before {
  content: "";
}

.fa-sliders-simple::before {
  content: "";
}

.fa-grid-round::before {
  content: "";
}

.fa-badge-sheriff::before {
  content: "";
}

.fa-server::before {
  content: "";
}

.fa-virus-covid-slash::before {
  content: "";
}

.fa-intersection::before {
  content: "";
}

.fa-shop-lock::before {
  content: "";
}

.fa-family::before {
  content: "";
}

.fa-hourglass-start::before {
  content: "";
}

.fa-hourglass-1::before {
  content: "";
}

.fa-user-hair-buns::before {
  content: "";
}

.fa-blender-phone::before {
  content: "";
}

.fa-hourglass-clock::before {
  content: "";
}

.fa-person-seat-reclined::before {
  content: "";
}

.fa-paper-plane-top::before {
  content: "";
}

.fa-paper-plane-alt::before {
  content: "";
}

.fa-send::before {
  content: "";
}

.fa-message-arrow-up::before {
  content: "";
}

.fa-comment-alt-arrow-up::before {
  content: "";
}

.fa-lightbulb-exclamation::before {
  content: "";
}

.fa-layer-minus::before {
  content: "";
}

.fa-layer-group-minus::before {
  content: "";
}

.fa-chart-pie-simple-circle-currency::before {
  content: "";
}

.fa-circle-e::before {
  content: "";
}

.fa-building-wheat::before {
  content: "";
}

.fa-gauge-max::before {
  content: "";
}

.fa-tachometer-alt-fastest::before {
  content: "";
}

.fa-person-breastfeeding::before {
  content: "";
}

.fa-apostrophe::before {
  content: "\'";
}

.fa-fire-hydrant::before {
  content: "";
}

.fa-right-to-bracket::before {
  content: "";
}

.fa-sign-in-alt::before {
  content: "";
}

.fa-video-plus::before {
  content: "";
}

.fa-square-right::before {
  content: "";
}

.fa-arrow-alt-square-right::before {
  content: "";
}

.fa-comment-smile::before {
  content: "";
}

.fa-venus::before {
  content: "";
}

.fa-passport::before {
  content: "";
}

.fa-inbox-in::before {
  content: "";
}

.fa-inbox-arrow-down::before {
  content: "";
}

.fa-heart-pulse::before {
  content: "";
}

.fa-heartbeat::before {
  content: "";
}

.fa-circle-8::before {
  content: "";
}

.fa-clouds-moon::before {
  content: "";
}

.fa-clock-ten-thirty::before {
  content: "";
}

.fa-people-carry-box::before {
  content: "";
}

.fa-people-carry::before {
  content: "";
}

.fa-folder-user::before {
  content: "";
}

.fa-trash-can-xmark::before {
  content: "";
}

.fa-temperature-high::before {
  content: "";
}

.fa-microchip::before {
  content: "";
}

.fa-left-long-to-line::before {
  content: "";
}

.fa-crown::before {
  content: "";
}

.fa-weight-hanging::before {
  content: "";
}

.fa-xmarks-lines::before {
  content: "";
}

.fa-file-prescription::before {
  content: "";
}

.fa-calendar-range::before {
  content: "";
}

.fa-flower-daffodil::before {
  content: "";
}

.fa-hand-back-point-up::before {
  content: "";
}

.fa-weight-scale::before {
  content: "";
}

.fa-weight::before {
  content: "";
}

.fa-star-exclamation::before {
  content: "";
}

.fa-books::before {
  content: "";
}

.fa-user-group::before {
  content: "";
}

.fa-user-friends::before {
  content: "";
}

.fa-arrow-up-a-z::before {
  content: "";
}

.fa-sort-alpha-up::before {
  content: "";
}

.fa-layer-plus::before {
  content: "";
}

.fa-layer-group-plus::before {
  content: "";
}

.fa-play-pause::before {
  content: "";
}

.fa-block-question::before {
  content: "";
}

.fa-snooze::before {
  content: "";
}

.fa-zzz::before {
  content: "";
}

.fa-scanner-image::before {
  content: "";
}

.fa-tv-retro::before {
  content: "";
}

.fa-square-t::before {
  content: "";
}

.fa-farm::before {
  content: "";
}

.fa-barn-silo::before {
  content: "";
}

.fa-chess-knight::before {
  content: "";
}

.fa-bars-sort::before {
  content: "";
}

.fa-pallet-boxes::before {
  content: "";
}

.fa-palette-boxes::before {
  content: "";
}

.fa-pallet-alt::before {
  content: "";
}

.fa-face-laugh-squint::before {
  content: "";
}

.fa-laugh-squint::before {
  content: "";
}

.fa-code-simple::before {
  content: "";
}

.fa-bolt-slash::before {
  content: "";
}

.fa-panel-fire::before {
  content: "";
}

.fa-binary-circle-check::before {
  content: "";
}

.fa-comment-minus::before {
  content: "";
}

.fa-burrito::before {
  content: "";
}

.fa-violin::before {
  content: "";
}

.fa-objects-column::before {
  content: "";
}

.fa-square-chevron-down::before {
  content: "";
}

.fa-chevron-square-down::before {
  content: "";
}

.fa-comment-plus::before {
  content: "";
}

.fa-triangle-instrument::before {
  content: "";
}

.fa-triangle-music::before {
  content: "";
}

.fa-wheelchair::before {
  content: "";
}

.fa-user-pilot-tie::before {
  content: "";
}

.fa-piano-keyboard::before {
  content: "";
}

.fa-bed-empty::before {
  content: "";
}

.fa-circle-arrow-up::before {
  content: "";
}

.fa-arrow-circle-up::before {
  content: "";
}

.fa-toggle-on::before {
  content: "";
}

.fa-rectangle-vertical::before {
  content: "";
}

.fa-rectangle-portrait::before {
  content: "";
}

.fa-person-walking::before {
  content: "";
}

.fa-walking::before {
  content: "";
}

.fa-l::before {
  content: "L";
}

.fa-signal-stream::before {
  content: "";
}

.fa-down-to-bracket::before {
  content: "";
}

.fa-circle-z::before {
  content: "";
}

.fa-stars::before {
  content: "";
}

.fa-fire::before {
  content: "";
}

.fa-bed-pulse::before {
  content: "";
}

.fa-procedures::before {
  content: "";
}

.fa-house-day::before {
  content: "";
}

.fa-shuttle-space::before {
  content: "";
}

.fa-space-shuttle::before {
  content: "";
}

.fa-shirt-long-sleeve::before {
  content: "";
}

.fa-chart-pie-simple::before {
  content: "";
}

.fa-chart-pie-alt::before {
  content: "";
}

.fa-face-laugh::before {
  content: "";
}

.fa-laugh::before {
  content: "";
}

.fa-folder-open::before {
  content: "";
}

.fa-album-collection-circle-user::before {
  content: "";
}

.fa-candy::before {
  content: "";
}

.fa-bowl-hot::before {
  content: "";
}

.fa-soup::before {
  content: "";
}

.fa-flatbread::before {
  content: "";
}

.fa-heart-circle-plus::before {
  content: "";
}

.fa-code-fork::before {
  content: "";
}

.fa-city::before {
  content: "";
}

.fa-signal-bars-weak::before {
  content: "";
}

.fa-signal-alt-1::before {
  content: "";
}

.fa-microphone-lines::before {
  content: "";
}

.fa-microphone-alt::before {
  content: "";
}

.fa-clock-twelve::before {
  content: "";
}

.fa-pepper-hot::before {
  content: "";
}

.fa-citrus-slice::before {
  content: "";
}

.fa-sheep::before {
  content: "";
}

.fa-unlock::before {
  content: "";
}

.fa-colon-sign::before {
  content: "";
}

.fa-headset::before {
  content: "";
}

.fa-badger-honey::before {
  content: "";
}

.fa-h4::before {
  content: "";
}

.fa-store-slash::before {
  content: "";
}

.fa-road-circle-xmark::before {
  content: "";
}

.fa-signal-slash::before {
  content: "";
}

.fa-user-minus::before {
  content: "";
}

.fa-mars-stroke-up::before {
  content: "";
}

.fa-mars-stroke-v::before {
  content: "";
}

.fa-champagne-glasses::before {
  content: "";
}

.fa-glass-cheers::before {
  content: "";
}

.fa-taco::before {
  content: "";
}

.fa-hexagon-plus::before {
  content: "";
}

.fa-plus-hexagon::before {
  content: "";
}

.fa-clipboard::before {
  content: "";
}

.fa-house-circle-exclamation::before {
  content: "";
}

.fa-file-arrow-up::before {
  content: "";
}

.fa-file-upload::before {
  content: "";
}

.fa-wifi::before {
  content: "";
}

.fa-wifi-3::before {
  content: "";
}

.fa-wifi-strong::before {
  content: "";
}

.fa-messages::before {
  content: "";
}

.fa-comments-alt::before {
  content: "";
}

.fa-bath::before {
  content: "";
}

.fa-bathtub::before {
  content: "";
}

.fa-umbrella-simple::before {
  content: "";
}

.fa-umbrella-alt::before {
  content: "";
}

.fa-rectangle-history-circle-plus::before {
  content: "";
}

.fa-underline::before {
  content: "";
}

.fa-prescription-bottle-pill::before {
  content: "";
}

.fa-user-pen::before {
  content: "";
}

.fa-user-edit::before {
  content: "";
}

.fa-binary-slash::before {
  content: "";
}

.fa-square-o::before {
  content: "";
}

.fa-signature::before {
  content: "";
}

.fa-stroopwafel::before {
  content: "";
}

.fa-bold::before {
  content: "";
}

.fa-anchor-lock::before {
  content: "";
}

.fa-building-ngo::before {
  content: "";
}

.fa-transporter-3::before {
  content: "";
}

.fa-engine-warning::before {
  content: "";
}

.fa-engine-exclamation::before {
  content: "";
}

.fa-circle-down-right::before {
  content: "";
}

.fa-square-k::before {
  content: "";
}

.fa-manat-sign::before {
  content: "";
}

.fa-money-check-pen::before {
  content: "";
}

.fa-money-check-edit::before {
  content: "";
}

.fa-not-equal::before {
  content: "";
}

.fa-border-top-left::before {
  content: "";
}

.fa-border-style::before {
  content: "";
}

.fa-map-location-dot::before {
  content: "";
}

.fa-map-marked-alt::before {
  content: "";
}

.fa-tilde::before {
  content: "\~";
}

.fa-jedi::before {
  content: "";
}

.fa-square-poll-vertical::before {
  content: "";
}

.fa-poll::before {
  content: "";
}

.fa-arrow-down-square-triangle::before {
  content: "";
}

.fa-sort-shapes-down-alt::before {
  content: "";
}

.fa-mug-hot::before {
  content: "";
}

.fa-dog-leashed::before {
  content: "";
}

.fa-car-battery::before {
  content: "";
}

.fa-battery-car::before {
  content: "";
}

.fa-face-downcast-sweat::before {
  content: "";
}

.fa-mailbox-flag-up::before {
  content: "";
}

.fa-memo-circle-info::before {
  content: "";
}

.fa-gift::before {
  content: "";
}

.fa-dice-two::before {
  content: "";
}

.fa-volume::before {
  content: "";
}

.fa-volume-medium::before {
  content: "";
}

.fa-transporter-5::before {
  content: "";
}

.fa-gauge-circle-bolt::before {
  content: "";
}

.fa-coin-front::before {
  content: "";
}

.fa-file-slash::before {
  content: "";
}

.fa-message-arrow-up-right::before {
  content: "";
}

.fa-treasure-chest::before {
  content: "";
}

.fa-chess-queen::before {
  content: "";
}

.fa-paintbrush-fine::before {
  content: "";
}

.fa-paint-brush-alt::before {
  content: "";
}

.fa-paint-brush-fine::before {
  content: "";
}

.fa-paintbrush-alt::before {
  content: "";
}

.fa-glasses::before {
  content: "";
}

.fa-hood-cloak::before {
  content: "";
}

.fa-square-quote::before {
  content: "";
}

.fa-up-left::before {
  content: "";
}

.fa-bring-front::before {
  content: "";
}

.fa-chess-board::before {
  content: "";
}

.fa-burger-cheese::before {
  content: "";
}

.fa-cheeseburger::before {
  content: "";
}

.fa-building-circle-check::before {
  content: "";
}

.fa-repeat-1::before {
  content: "";
}

.fa-arrow-down-to-line::before {
  content: "";
}

.fa-arrow-to-bottom::before {
  content: "";
}

.fa-grid-5::before {
  content: "";
}

.fa-right-long-to-line::before {
  content: "";
}

.fa-person-chalkboard::before {
  content: "";
}

.fa-mars-stroke-right::before {
  content: "";
}

.fa-mars-stroke-h::before {
  content: "";
}

.fa-hand-back-fist::before {
  content: "";
}

.fa-hand-rock::before {
  content: "";
}

.fa-grid-round-5::before {
  content: "";
}

.fa-tally::before {
  content: "";
}

.fa-tally-5::before {
  content: "";
}

.fa-square-caret-up::before {
  content: "";
}

.fa-caret-square-up::before {
  content: "";
}

.fa-cloud-showers-water::before {
  content: "";
}

.fa-chart-bar::before {
  content: "";
}

.fa-bar-chart::before {
  content: "";
}

.fa-hands-bubbles::before {
  content: "";
}

.fa-hands-wash::before {
  content: "";
}

.fa-less-than-equal::before {
  content: "";
}

.fa-train::before {
  content: "";
}

.fa-up-from-dotted-line::before {
  content: "";
}

.fa-eye-low-vision::before {
  content: "";
}

.fa-low-vision::before {
  content: "";
}

.fa-traffic-light-go::before {
  content: "";
}

.fa-face-exhaling::before {
  content: "";
}

.fa-sensor-fire::before {
  content: "";
}

.fa-user-unlock::before {
  content: "";
}

.fa-hexagon-divide::before {
  content: "";
}

.fa-00::before {
  content: "";
}

.fa-crow::before {
  content: "";
}

.fa-cassette-betamax::before {
  content: "";
}

.fa-betamax::before {
  content: "";
}

.fa-sailboat::before {
  content: "";
}

.fa-window-restore::before {
  content: "";
}

.fa-nfc-magnifying-glass::before {
  content: "";
}

.fa-file-binary::before {
  content: "";
}

.fa-circle-v::before {
  content: "";
}

.fa-square-plus::before {
  content: "";
}

.fa-plus-square::before {
  content: "";
}

.fa-bowl-scoops::before {
  content: "";
}

.fa-mistletoe::before {
  content: "";
}

.fa-custard::before {
  content: "";
}

.fa-lacrosse-stick::before {
  content: "";
}

.fa-hockey-mask::before {
  content: "";
}

.fa-sunrise::before {
  content: "";
}

.fa-panel-ews::before {
  content: "";
}

.fa-torii-gate::before {
  content: "";
}

.fa-cloud-exclamation::before {
  content: "";
}

.fa-message-lines::before {
  content: "";
}

.fa-comment-alt-lines::before {
  content: "";
}

.fa-frog::before {
  content: "";
}

.fa-bucket::before {
  content: "";
}

.fa-floppy-disk-pen::before {
  content: "";
}

.fa-image::before {
  content: "";
}

.fa-window-frame::before {
  content: "";
}

.fa-microphone::before {
  content: "";
}

.fa-cow::before {
  content: "";
}

.fa-file-zip::before {
  content: "";
}

.fa-square-ring::before {
  content: "";
}

.fa-down-from-line::before {
  content: "";
}

.fa-arrow-alt-from-top::before {
  content: "";
}

.fa-caret-up::before {
  content: "";
}

.fa-shield-xmark::before {
  content: "";
}

.fa-shield-times::before {
  content: "";
}

.fa-screwdriver::before {
  content: "";
}

.fa-circle-sort-down::before {
  content: "";
}

.fa-sort-circle-down::before {
  content: "";
}

.fa-folder-closed::before {
  content: "";
}

.fa-house-tsunami::before {
  content: "";
}

.fa-square-nfi::before {
  content: "";
}

.fa-forklift::before {
  content: "";
}

.fa-arrow-up-from-ground-water::before {
  content: "";
}

.fa-bracket-square-right::before {
  content: "\]";
}

.fa-martini-glass::before {
  content: "";
}

.fa-glass-martini-alt::before {
  content: "";
}

.fa-rotate-left::before {
  content: "";
}

.fa-rotate-back::before {
  content: "";
}

.fa-rotate-backward::before {
  content: "";
}

.fa-undo-alt::before {
  content: "";
}

.fa-table-columns::before {
  content: "";
}

.fa-columns::before {
  content: "";
}

.fa-square-a::before {
  content: "";
}

.fa-tick::before {
  content: "";
}

.fa-lemon::before {
  content: "";
}

.fa-head-side-mask::before {
  content: "";
}

.fa-handshake::before {
  content: "";
}

.fa-gem::before {
  content: "";
}

.fa-dolly::before {
  content: "";
}

.fa-dolly-box::before {
  content: "";
}

.fa-smoking::before {
  content: "";
}

.fa-minimize::before {
  content: "";
}

.fa-compress-arrows-alt::before {
  content: "";
}

.fa-refrigerator::before {
  content: "";
}

.fa-monument::before {
  content: "";
}

.fa-octagon-xmark::before {
  content: "";
}

.fa-times-octagon::before {
  content: "";
}

.fa-xmark-octagon::before {
  content: "";
}

.fa-align-slash::before {
  content: "";
}

.fa-snowplow::before {
  content: "";
}

.fa-angles-right::before {
  content: "";
}

.fa-angle-double-right::before {
  content: "";
}

.fa-truck-ramp-couch::before {
  content: "";
}

.fa-truck-couch::before {
  content: "";
}

.fa-cannabis::before {
  content: "";
}

.fa-circle-play::before {
  content: "";
}

.fa-play-circle::before {
  content: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "";
}

.fa-tablets::before {
  content: "";
}

.fa-360-degrees::before {
  content: "";
}

.fa-ethernet::before {
  content: "";
}

.fa-euro-sign::before {
  content: "";
}

.fa-eur::before {
  content: "";
}

.fa-euro::before {
  content: "";
}

.fa-chair::before {
  content: "";
}

.fa-circle-check::before {
  content: "";
}

.fa-check-circle::before {
  content: "";
}

.fa-square-dashed-circle-plus::before {
  content: "";
}

.fa-money-simple-from-bracket::before {
  content: "";
}

.fa-bat::before {
  content: "";
}

.fa-circle-stop::before {
  content: "";
}

.fa-stop-circle::before {
  content: "";
}

.fa-head-side-headphones::before {
  content: "";
}

.fa-phone-rotary::before {
  content: "";
}

.fa-compass-drafting::before {
  content: "";
}

.fa-drafting-compass::before {
  content: "";
}

.fa-plate-wheat::before {
  content: "";
}

.fa-calendar-circle-minus::before {
  content: "";
}

.fa-chopsticks::before {
  content: "";
}

.fa-car-wrench::before {
  content: "";
}

.fa-car-mechanic::before {
  content: "";
}

.fa-icicles::before {
  content: "";
}

.fa-person-shelter::before {
  content: "";
}

.fa-neuter::before {
  content: "";
}

.fa-id-badge::before {
  content: "";
}

.fa-kazoo::before {
  content: "";
}

.fa-marker::before {
  content: "";
}

.fa-bin-bottles::before {
  content: "";
}

.fa-face-laugh-beam::before {
  content: "";
}

.fa-laugh-beam::before {
  content: "";
}

.fa-square-arrow-down-left::before {
  content: "";
}

.fa-battery-bolt::before {
  content: "";
}

.fa-tree-large::before {
  content: "";
}

.fa-helicopter-symbol::before {
  content: "";
}

.fa-aperture::before {
  content: "";
}

.fa-universal-access::before {
  content: "";
}

.fa-gear-complex::before {
  content: "";
}

.fa-file-magnifying-glass::before {
  content: "";
}

.fa-file-search::before {
  content: "";
}

.fa-up-right::before {
  content: "";
}

.fa-circle-chevron-up::before {
  content: "";
}

.fa-chevron-circle-up::before {
  content: "";
}

.fa-user-police::before {
  content: "";
}

.fa-lari-sign::before {
  content: "";
}

.fa-volcano::before {
  content: "";
}

.fa-teddy-bear::before {
  content: "";
}

.fa-stocking::before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right::before {
  content: "";
}

.fa-image-slash::before {
  content: "";
}

.fa-mask-snorkel::before {
  content: "";
}

.fa-smoke::before {
  content: "";
}

.fa-sterling-sign::before {
  content: "";
}

.fa-gbp::before {
  content: "";
}

.fa-pound-sign::before {
  content: "";
}

.fa-battery-exclamation::before {
  content: "";
}

.fa-viruses::before {
  content: "";
}

.fa-square-person-confined::before {
  content: "";
}

.fa-user-tie::before {
  content: "";
}

.fa-arrow-down-long::before {
  content: "";
}

.fa-long-arrow-down::before {
  content: "";
}

.fa-tent-arrow-down-to-line::before {
  content: "";
}

.fa-certificate::before {
  content: "";
}

.fa-crystal-ball::before {
  content: "";
}

.fa-reply-all::before {
  content: "";
}

.fa-mail-reply-all::before {
  content: "";
}

.fa-suitcase::before {
  content: "";
}

.fa-person-skating::before {
  content: "";
}

.fa-skating::before {
  content: "";
}

.fa-star-shooting::before {
  content: "";
}

.fa-binary-lock::before {
  content: "";
}

.fa-filter-circle-dollar::before {
  content: "";
}

.fa-funnel-dollar::before {
  content: "";
}

.fa-camera-retro::before {
  content: "";
}

.fa-circle-arrow-down::before {
  content: "";
}

.fa-arrow-circle-down::before {
  content: "";
}

.fa-comment-pen::before {
  content: "";
}

.fa-comment-edit::before {
  content: "";
}

.fa-file-import::before {
  content: "";
}

.fa-arrow-right-to-file::before {
  content: "";
}

.fa-banjo::before {
  content: "";
}

.fa-square-arrow-up-right::before {
  content: "";
}

.fa-external-link-square::before {
  content: "";
}

.fa-light-emergency-on::before {
  content: "";
}

.fa-kerning::before {
  content: "";
}

.fa-box-open::before {
  content: "";
}

.fa-square-f::before {
  content: "";
}

.fa-scroll::before {
  content: "";
}

.fa-spa::before {
  content: "";
}

.fa-arrow-left-from-line::before {
  content: "";
}

.fa-arrow-from-right::before {
  content: "";
}

.fa-strawberry::before {
  content: "";
}

.fa-location-pin-lock::before {
  content: "";
}

.fa-pause::before {
  content: "";
}

.fa-clock-eight-thirty::before {
  content: "";
}

.fa-plane-engines::before {
  content: "";
}

.fa-plane-alt::before {
  content: "";
}

.fa-hill-avalanche::before {
  content: "";
}

.fa-temperature-empty::before {
  content: "";
}

.fa-temperature-0::before {
  content: "";
}

.fa-thermometer-0::before {
  content: "";
}

.fa-thermometer-empty::before {
  content: "";
}

.fa-bomb::before {
  content: "";
}

.fa-gauge-low::before {
  content: "";
}

.fa-tachometer-alt-slow::before {
  content: "";
}

.fa-registered::before {
  content: "";
}

.fa-trash-can-plus::before {
  content: "";
}

.fa-address-card::before {
  content: "";
}

.fa-contact-card::before {
  content: "";
}

.fa-vcard::before {
  content: "";
}

.fa-scale-unbalanced-flip::before {
  content: "";
}

.fa-balance-scale-right::before {
  content: "";
}

.fa-globe-snow::before {
  content: "";
}

.fa-subscript::before {
  content: "";
}

.fa-diamond-turn-right::before {
  content: "";
}

.fa-directions::before {
  content: "";
}

.fa-integral::before {
  content: "";
}

.fa-burst::before {
  content: "";
}

.fa-house-laptop::before {
  content: "";
}

.fa-laptop-house::before {
  content: "";
}

.fa-face-tired::before {
  content: "";
}

.fa-tired::before {
  content: "";
}

.fa-money-bills::before {
  content: "";
}

.fa-blinds-raised::before {
  content: "";
}

.fa-smog::before {
  content: "";
}

.fa-ufo-beam::before {
  content: "";
}

.fa-circle-caret-up::before {
  content: "";
}

.fa-caret-circle-up::before {
  content: "";
}

.fa-user-vneck-hair-long::before {
  content: "";
}

.fa-square-a-lock::before {
  content: "";
}

.fa-crutch::before {
  content: "";
}

.fa-gas-pump-slash::before {
  content: "";
}

.fa-cloud-arrow-up::before {
  content: "";
}

.fa-cloud-upload::before {
  content: "";
}

.fa-cloud-upload-alt::before {
  content: "";
}

.fa-palette::before {
  content: "";
}

.fa-transporter-4::before {
  content: "";
}

.fa-chart-mixed-up-circle-currency::before {
  content: "";
}

.fa-objects-align-right::before {
  content: "";
}

.fa-arrows-turn-right::before {
  content: "";
}

.fa-vest::before {
  content: "";
}

.fa-pig::before {
  content: "";
}

.fa-inbox-full::before {
  content: "";
}

.fa-circle-envelope::before {
  content: "";
}

.fa-envelope-circle::before {
  content: "";
}

.fa-triangle-person-digging::before {
  content: "";
}

.fa-construction::before {
  content: "";
}

.fa-ferry::before {
  content: "";
}

.fa-bullseye-arrow::before {
  content: "";
}

.fa-arrows-down-to-people::before {
  content: "";
}

.fa-seedling::before {
  content: "";
}

.fa-sprout::before {
  content: "";
}

.fa-clock-seven::before {
  content: "";
}

.fa-left-right::before {
  content: "";
}

.fa-arrows-alt-h::before {
  content: "";
}

.fa-boxes-packing::before {
  content: "";
}

.fa-circle-arrow-left::before {
  content: "";
}

.fa-arrow-circle-left::before {
  content: "";
}

.fa-flashlight::before {
  content: "";
}

.fa-group-arrows-rotate::before {
  content: "";
}

.fa-bowl-food::before {
  content: "";
}

.fa-square-9::before {
  content: "";
}

.fa-candy-cane::before {
  content: "";
}

.fa-arrow-down-wide-short::before {
  content: "";
}

.fa-sort-amount-asc::before {
  content: "";
}

.fa-sort-amount-down::before {
  content: "";
}

.fa-square-dollar::before {
  content: "";
}

.fa-dollar-square::before {
  content: "";
}

.fa-usd-square::before {
  content: "";
}

.fa-phone-arrow-right::before {
  content: "";
}

.fa-hand-holding-seedling::before {
  content: "";
}

.fa-message-check::before {
  content: "";
}

.fa-comment-alt-check::before {
  content: "";
}

.fa-cloud-bolt::before {
  content: "";
}

.fa-thunderstorm::before {
  content: "";
}

.fa-chart-line-up-down::before {
  content: "";
}

.fa-text-slash::before {
  content: "";
}

.fa-remove-format::before {
  content: "";
}

.fa-watch::before {
  content: "";
}

.fa-circle-down-left::before {
  content: "";
}

.fa-text::before {
  content: "";
}

.fa-projector::before {
  content: "";
}

.fa-face-smile-wink::before {
  content: "";
}

.fa-smile-wink::before {
  content: "";
}

.fa-tombstone-blank::before {
  content: "";
}

.fa-tombstone-alt::before {
  content: "";
}

.fa-chess-king-piece::before {
  content: "";
}

.fa-chess-king-alt::before {
  content: "";
}

.fa-circle-6::before {
  content: "";
}

.fa-left::before {
  content: "";
}

.fa-arrow-alt-left::before {
  content: "";
}

.fa-file-word::before {
  content: "";
}

.fa-file-powerpoint::before {
  content: "";
}

.fa-square-down::before {
  content: "";
}

.fa-arrow-alt-square-down::before {
  content: "";
}

.fa-objects-align-center-vertical::before {
  content: "";
}

.fa-arrows-left-right::before {
  content: "";
}

.fa-arrows-h::before {
  content: "";
}

.fa-house-lock::before {
  content: "";
}

.fa-cloud-arrow-down::before {
  content: "";
}

.fa-cloud-download::before {
  content: "";
}

.fa-cloud-download-alt::before {
  content: "";
}

.fa-wreath::before {
  content: "";
}

.fa-children::before {
  content: "";
}

.fa-meter-droplet::before {
  content: "";
}

.fa-chalkboard::before {
  content: "";
}

.fa-blackboard::before {
  content: "";
}

.fa-user-large-slash::before {
  content: "";
}

.fa-user-alt-slash::before {
  content: "";
}

.fa-signal-strong::before {
  content: "";
}

.fa-signal-4::before {
  content: "";
}

.fa-lollipop::before {
  content: "";
}

.fa-lollypop::before {
  content: "";
}

.fa-list-tree::before {
  content: "";
}

.fa-envelope-open::before {
  content: "";
}

.fa-draw-circle::before {
  content: "";
}

.fa-cat-space::before {
  content: "";
}

.fa-handshake-simple-slash::before {
  content: "";
}

.fa-handshake-alt-slash::before {
  content: "";
}

.fa-rabbit-running::before {
  content: "";
}

.fa-rabbit-fast::before {
  content: "";
}

.fa-memo-pad::before {
  content: "";
}

.fa-mattress-pillow::before {
  content: "";
}

.fa-alarm-plus::before {
  content: "";
}

.fa-alicorn::before {
  content: "";
}

.fa-comment-question::before {
  content: "";
}

.fa-gingerbread-man::before {
  content: "";
}

.fa-guarani-sign::before {
  content: "";
}

.fa-burger-fries::before {
  content: "";
}

.fa-mug-tea::before {
  content: "";
}

.fa-border-top::before {
  content: "";
}

.fa-arrows-rotate::before {
  content: "";
}

.fa-refresh::before {
  content: "";
}

.fa-sync::before {
  content: "";
}

.fa-circle-book-open::before {
  content: "";
}

.fa-book-circle::before {
  content: "";
}

.fa-arrows-to-dotted-line::before {
  content: "";
}

.fa-fire-extinguisher::before {
  content: "";
}

.fa-garage-open::before {
  content: "";
}

.fa-shelves-empty::before {
  content: "";
}

.fa-cruzeiro-sign::before {
  content: "";
}

.fa-watch-apple::before {
  content: "";
}

.fa-watch-calculator::before {
  content: "";
}

.fa-list-dropdown::before {
  content: "";
}

.fa-cabinet-filing::before {
  content: "";
}

.fa-burger-soda::before {
  content: "";
}

.fa-square-arrow-up::before {
  content: "";
}

.fa-arrow-square-up::before {
  content: "";
}

.fa-greater-than-equal::before {
  content: "";
}

.fa-pallet-box::before {
  content: "";
}

.fa-face-confounded::before {
  content: "";
}

.fa-shield-halved::before {
  content: "";
}

.fa-shield-alt::before {
  content: "";
}

.fa-truck-plow::before {
  content: "";
}

.fa-book-atlas::before {
  content: "";
}

.fa-atlas::before {
  content: "";
}

.fa-virus::before {
  content: "";
}

.fa-grid-round-2::before {
  content: "";
}

.fa-comment-middle-top::before {
  content: "";
}

.fa-envelope-circle-check::before {
  content: "";
}

.fa-layer-group::before {
  content: "";
}

.fa-restroom-simple::before {
  content: "";
}

.fa-arrows-to-dot::before {
  content: "";
}

.fa-border-outer::before {
  content: "";
}

.fa-hashtag-lock::before {
  content: "";
}

.fa-clock-two-thirty::before {
  content: "";
}

.fa-archway::before {
  content: "";
}

.fa-heart-circle-check::before {
  content: "";
}

.fa-house-chimney-crack::before {
  content: "";
}

.fa-house-damage::before {
  content: "";
}

.fa-file-zipper::before {
  content: "";
}

.fa-file-archive::before {
  content: "";
}

.fa-heart-half::before {
  content: "";
}

.fa-comment-check::before {
  content: "";
}

.fa-square::before {
  content: "";
}

.fa-memo::before {
  content: "";
}

.fa-martini-glass-empty::before {
  content: "";
}

.fa-glass-martini::before {
  content: "";
}

.fa-couch::before {
  content: "";
}

.fa-cedi-sign::before {
  content: "";
}

.fa-italic::before {
  content: "";
}

.fa-glass-citrus::before {
  content: "";
}

.fa-calendar-lines-pen::before {
  content: "";
}

.fa-church::before {
  content: "";
}

.fa-person-snowmobiling::before {
  content: "";
}

.fa-snowmobile::before {
  content: "";
}

.fa-face-hushed::before {
  content: "";
}

.fa-comments-dollar::before {
  content: "";
}

.fa-pickaxe::before {
  content: "";
}

.fa-link-simple-slash::before {
  content: "";
}

.fa-democrat::before {
  content: "";
}

.fa-face-confused::before {
  content: "";
}

.fa-pinball::before {
  content: "";
}

.fa-z::before {
  content: "Z";
}

.fa-person-skiing::before {
  content: "";
}

.fa-skiing::before {
  content: "";
}

.fa-deer::before {
  content: "";
}

.fa-input-pipe::before {
  content: "";
}

.fa-road-lock::before {
  content: "";
}

.fa-a::before {
  content: "A";
}

.fa-bookmark-slash::before {
  content: "";
}

.fa-temperature-arrow-down::before {
  content: "";
}

.fa-temperature-down::before {
  content: "";
}

.fa-mace::before {
  content: "";
}

.fa-feather-pointed::before {
  content: "";
}

.fa-feather-alt::before {
  content: "";
}

.fa-sausage::before {
  content: "";
}

.fa-trash-can-clock::before {
  content: "";
}

.fa-p::before {
  content: "P";
}

.fa-broom-wide::before {
  content: "";
}

.fa-snowflake::before {
  content: "";
}

.fa-stomach::before {
  content: "";
}

.fa-newspaper::before {
  content: "";
}

.fa-rectangle-ad::before {
  content: "";
}

.fa-ad::before {
  content: "";
}

.fa-guitar-electric::before {
  content: "";
}

.fa-arrow-turn-down-right::before {
  content: "";
}

.fa-moon-cloud::before {
  content: "";
}

.fa-bread-slice-butter::before {
  content: "";
}

.fa-circle-arrow-right::before {
  content: "";
}

.fa-arrow-circle-right::before {
  content: "";
}

.fa-user-group-crown::before {
  content: "";
}

.fa-users-crown::before {
  content: "";
}

.fa-circle-i::before {
  content: "";
}

.fa-toilet-paper-check::before {
  content: "";
}

.fa-filter-circle-xmark::before {
  content: "";
}

.fa-locust::before {
  content: "";
}

.fa-sort::before {
  content: "";
}

.fa-unsorted::before {
  content: "";
}

.fa-list-ol::before {
  content: "";
}

.fa-list-1-2::before {
  content: "";
}

.fa-list-numeric::before {
  content: "";
}

.fa-chart-waterfall::before {
  content: "";
}

.fa-sparkle::before {
  content: "";
}

.fa-face-party::before {
  content: "";
}

.fa-kidneys::before {
  content: "";
}

.fa-wifi-exclamation::before {
  content: "";
}

.fa-chart-network::before {
  content: "";
}

.fa-person-dress-burst::before {
  content: "";
}

.fa-dice-d4::before {
  content: "";
}

.fa-money-check-dollar::before {
  content: "";
}

.fa-money-check-alt::before {
  content: "";
}

.fa-vector-square::before {
  content: "";
}

.fa-bread-slice::before {
  content: "";
}

.fa-language::before {
  content: "";
}

.fa-wheat-awn-slash::before {
  content: "";
}

.fa-face-kiss-wink-heart::before {
  content: "";
}

.fa-kiss-wink-heart::before {
  content: "";
}

.fa-dagger::before {
  content: "";
}

.fa-podium::before {
  content: "";
}

.fa-memo-circle-check::before {
  content: "";
}

.fa-route-highway::before {
  content: "";
}

.fa-down-to-line::before {
  content: "";
}

.fa-arrow-alt-to-bottom::before {
  content: "";
}

.fa-filter::before {
  content: "";
}

.fa-square-g::before {
  content: "";
}

.fa-circle-phone::before {
  content: "";
}

.fa-phone-circle::before {
  content: "";
}

.fa-clipboard-prescription::before {
  content: "";
}

.fa-user-nurse-hair::before {
  content: "";
}

.fa-question::before {
  content: "\?";
}

.fa-file-signature::before {
  content: "";
}

.fa-toggle-large-on::before {
  content: "";
}

.fa-up-down-left-right::before {
  content: "";
}

.fa-arrows-alt::before {
  content: "";
}

.fa-dryer-heat::before {
  content: "";
}

.fa-dryer-alt::before {
  content: "";
}

.fa-house-chimney-user::before {
  content: "";
}

.fa-hand-holding-heart::before {
  content: "";
}

.fa-arrow-up-small-big::before {
  content: "";
}

.fa-sort-size-up-alt::before {
  content: "";
}

.fa-train-track::before {
  content: "";
}

.fa-puzzle-piece::before {
  content: "";
}

.fa-money-check::before {
  content: "";
}

.fa-star-half-stroke::before {
  content: "";
}

.fa-star-half-alt::before {
  content: "";
}

.fa-file-exclamation::before {
  content: "";
}

.fa-code::before {
  content: "";
}

.fa-whiskey-glass::before {
  content: "";
}

.fa-glass-whiskey::before {
  content: "";
}

.fa-moon-stars::before {
  content: "";
}

.fa-building-circle-exclamation::before {
  content: "";
}

.fa-clothes-hanger::before {
  content: "";
}

.fa-mobile-notch::before {
  content: "";
}

.fa-mobile-iphone::before {
  content: "";
}

.fa-magnifying-glass-chart::before {
  content: "";
}

.fa-arrow-up-right-from-square::before {
  content: "";
}

.fa-external-link::before {
  content: "";
}

.fa-cubes-stacked::before {
  content: "";
}

.fa-images-user::before {
  content: "";
}

.fa-won-sign::before {
  content: "";
}

.fa-krw::before {
  content: "";
}

.fa-won::before {
  content: "";
}

.fa-image-polaroid-user::before {
  content: "";
}

.fa-virus-covid::before {
  content: "";
}

.fa-square-ellipsis::before {
  content: "";
}

.fa-pie::before {
  content: "";
}

.fa-chess-knight-piece::before {
  content: "";
}

.fa-chess-knight-alt::before {
  content: "";
}

.fa-austral-sign::before {
  content: "";
}

.fa-cloud-plus::before {
  content: "";
}

.fa-f::before {
  content: "F";
}

.fa-leaf::before {
  content: "";
}

.fa-bed-bunk::before {
  content: "";
}

.fa-road::before {
  content: "";
}

.fa-taxi::before {
  content: "";
}

.fa-cab::before {
  content: "";
}

.fa-person-circle-plus::before {
  content: "";
}

.fa-chart-pie::before {
  content: "";
}

.fa-pie-chart::before {
  content: "";
}

.fa-bolt-lightning::before {
  content: "";
}

.fa-clock-eight::before {
  content: "";
}

.fa-sack-xmark::before {
  content: "";
}

.fa-file-excel::before {
  content: "";
}

.fa-file-contract::before {
  content: "";
}

.fa-fish-fins::before {
  content: "";
}

.fa-circle-q::before {
  content: "";
}

.fa-building-flag::before {
  content: "";
}

.fa-face-grin-beam::before {
  content: "";
}

.fa-grin-beam::before {
  content: "";
}

.fa-object-ungroup::before {
  content: "";
}

.fa-face-disguise::before {
  content: "";
}

.fa-circle-arrow-down-right::before {
  content: "";
}

.fa-alien-8bit::before {
  content: "";
}

.fa-alien-monster::before {
  content: "";
}

.fa-hand-point-ribbon::before {
  content: "";
}

.fa-poop::before {
  content: "";
}

.fa-object-exclude::before {
  content: "";
}

.fa-telescope::before {
  content: "";
}

.fa-location-pin::before {
  content: "";
}

.fa-map-marker::before {
  content: "";
}

.fa-square-list::before {
  content: "";
}

.fa-kaaba::before {
  content: "";
}

.fa-toilet-paper::before {
  content: "";
}

.fa-helmet-safety::before {
  content: "";
}

.fa-hard-hat::before {
  content: "";
}

.fa-hat-hard::before {
  content: "";
}

.fa-comment-code::before {
  content: "";
}

.fa-sim-cards::before {
  content: "";
}

.fa-starship::before {
  content: "";
}

.fa-eject::before {
  content: "";
}

.fa-circle-right::before {
  content: "";
}

.fa-arrow-alt-circle-right::before {
  content: "";
}

.fa-plane-circle-check::before {
  content: "";
}

.fa-seal::before {
  content: "";
}

.fa-user-cowboy::before {
  content: "";
}

.fa-hexagon-vertical-nft::before {
  content: "";
}

.fa-face-rolling-eyes::before {
  content: "";
}

.fa-meh-rolling-eyes::before {
  content: "";
}

.fa-bread-loaf::before {
  content: "";
}

.fa-rings-wedding::before {
  content: "";
}

.fa-object-group::before {
  content: "";
}

.fa-french-fries::before {
  content: "";
}

.fa-chart-line::before {
  content: "";
}

.fa-line-chart::before {
  content: "";
}

.fa-calendar-arrow-down::before {
  content: "";
}

.fa-calendar-download::before {
  content: "";
}

.fa-send-back::before {
  content: "";
}

.fa-mask-ventilator::before {
  content: "";
}

.fa-signature-lock::before {
  content: "";
}

.fa-arrow-right::before {
  content: "";
}

.fa-signs-post::before {
  content: "";
}

.fa-map-signs::before {
  content: "";
}

.fa-octagon-plus::before {
  content: "";
}

.fa-plus-octagon::before {
  content: "";
}

.fa-cash-register::before {
  content: "";
}

.fa-person-circle-question::before {
  content: "";
}

.fa-melon-slice::before {
  content: "";
}

.fa-space-station-moon::before {
  content: "";
}

.fa-message-smile::before {
  content: "";
}

.fa-comment-alt-smile::before {
  content: "";
}

.fa-cup-straw::before {
  content: "";
}

.fa-left-from-line::before {
  content: "";
}

.fa-arrow-alt-from-right::before {
  content: "";
}

.fa-h::before {
  content: "H";
}

.fa-basket-shopping-simple::before {
  content: "";
}

.fa-shopping-basket-alt::before {
  content: "";
}

.fa-hands-holding-heart::before {
  content: "";
}

.fa-hands-heart::before {
  content: "";
}

.fa-clock-nine::before {
  content: "";
}

.fa-tarp::before {
  content: "";
}

.fa-face-sleepy::before {
  content: "";
}

.fa-hand-horns::before {
  content: "";
}

.fa-screwdriver-wrench::before {
  content: "";
}

.fa-tools::before {
  content: "";
}

.fa-arrows-to-eye::before {
  content: "";
}

.fa-circle-three-quarters::before {
  content: "";
}

.fa-trophy-star::before {
  content: "";
}

.fa-trophy-alt::before {
  content: "";
}

.fa-plug-circle-bolt::before {
  content: "";
}

.fa-face-thermometer::before {
  content: "";
}

.fa-grid-round-4::before {
  content: "";
}

.fa-shirt-running::before {
  content: "";
}

.fa-book-circle-arrow-up::before {
  content: "";
}

.fa-face-nauseated::before {
  content: "";
}

.fa-heart::before {
  content: "";
}

.fa-file-chart-pie::before {
  content: "";
}

.fa-mars-and-venus::before {
  content: "";
}

.fa-house-user::before {
  content: "";
}

.fa-home-user::before {
  content: "";
}

.fa-circle-arrow-down-left::before {
  content: "";
}

.fa-dumpster-fire::before {
  content: "";
}

.fa-hexagon-minus::before {
  content: "";
}

.fa-minus-hexagon::before {
  content: "";
}

.fa-left-to-line::before {
  content: "";
}

.fa-arrow-alt-to-left::before {
  content: "";
}

.fa-house-crack::before {
  content: "";
}

.fa-paw-simple::before {
  content: "";
}

.fa-paw-alt::before {
  content: "";
}

.fa-arrow-left-long-to-line::before {
  content: "";
}

.fa-brackets-round::before {
  content: "";
}

.fa-parentheses::before {
  content: "";
}

.fa-martini-glass-citrus::before {
  content: "";
}

.fa-cocktail::before {
  content: "";
}

.fa-user-shakespeare::before {
  content: "";
}

.fa-arrow-right-to-arc::before {
  content: "";
}

.fa-face-surprise::before {
  content: "";
}

.fa-surprise::before {
  content: "";
}

.fa-bottle-water::before {
  content: "";
}

.fa-circle-pause::before {
  content: "";
}

.fa-pause-circle::before {
  content: "";
}

.fa-gauge-circle-plus::before {
  content: "";
}

.fa-folders::before {
  content: "";
}

.fa-angel::before {
  content: "";
}

.fa-value-absolute::before {
  content: "";
}

.fa-rabbit::before {
  content: "";
}

.fa-toilet-paper-slash::before {
  content: "";
}

.fa-circle-euro::before {
  content: "";
}

.fa-apple-whole::before {
  content: "";
}

.fa-apple-alt::before {
  content: "";
}

.fa-kitchen-set::before {
  content: "";
}

.fa-diamond-half::before {
  content: "";
}

.fa-lock-keyhole::before {
  content: "";
}

.fa-lock-alt::before {
  content: "";
}

.fa-r::before {
  content: "R";
}

.fa-temperature-quarter::before {
  content: "";
}

.fa-temperature-1::before {
  content: "";
}

.fa-thermometer-1::before {
  content: "";
}

.fa-thermometer-quarter::before {
  content: "";
}

.fa-square-info::before {
  content: "";
}

.fa-info-square::before {
  content: "";
}

.fa-wifi-slash::before {
  content: "";
}

.fa-toilet-paper-xmark::before {
  content: "";
}

.fa-hands-holding-dollar::before {
  content: "";
}

.fa-hands-usd::before {
  content: "";
}

.fa-cube::before {
  content: "";
}

.fa-arrow-down-triangle-square::before {
  content: "";
}

.fa-sort-shapes-down::before {
  content: "";
}

.fa-bitcoin-sign::before {
  content: "";
}

.fa-shutters::before {
  content: "";
}

.fa-shield-dog::before {
  content: "";
}

.fa-solar-panel::before {
  content: "";
}

.fa-lock-open::before {
  content: "";
}

.fa-table-tree::before {
  content: "";
}

.fa-house-chimney-heart::before {
  content: "";
}

.fa-tally-3::before {
  content: "";
}

.fa-elevator::before {
  content: "";
}

.fa-money-bill-transfer::before {
  content: "";
}

.fa-money-bill-trend-up::before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right::before {
  content: "";
}

.fa-square-poll-horizontal::before {
  content: "";
}

.fa-poll-h::before {
  content: "";
}

.fa-circle::before {
  content: "";
}

.fa-cart-circle-exclamation::before {
  content: "";
}

.fa-sword::before {
  content: "";
}

.fa-backward-fast::before {
  content: "";
}

.fa-fast-backward::before {
  content: "";
}

.fa-recycle::before {
  content: "";
}

.fa-user-astronaut::before {
  content: "";
}

.fa-interrobang::before {
  content: "";
}

.fa-plane-slash::before {
  content: "";
}

.fa-circle-dashed::before {
  content: "";
}

.fa-trademark::before {
  content: "";
}

.fa-basketball::before {
  content: "";
}

.fa-basketball-ball::before {
  content: "";
}

.fa-fork-knife::before {
  content: "";
}

.fa-utensils-alt::before {
  content: "";
}

.fa-satellite-dish::before {
  content: "";
}

.fa-badge-check::before {
  content: "";
}

.fa-circle-up::before {
  content: "";
}

.fa-arrow-alt-circle-up::before {
  content: "";
}

.fa-slider::before {
  content: "";
}

.fa-mobile-screen-button::before {
  content: "";
}

.fa-mobile-alt::before {
  content: "";
}

.fa-clock-one-thirty::before {
  content: "";
}

.fa-inbox-out::before {
  content: "";
}

.fa-inbox-arrow-up::before {
  content: "";
}

.fa-cloud-slash::before {
  content: "";
}

.fa-volume-high::before {
  content: "";
}

.fa-volume-up::before {
  content: "";
}

.fa-users-rays::before {
  content: "";
}

.fa-wallet::before {
  content: "";
}

.fa-octagon-check::before {
  content: "";
}

.fa-flatbread-stuffed::before {
  content: "";
}

.fa-clipboard-check::before {
  content: "";
}

.fa-cart-circle-plus::before {
  content: "";
}

.fa-truck-clock::before {
  content: "";
}

.fa-shipping-timed::before {
  content: "";
}

.fa-pool-8-ball::before {
  content: "";
}

.fa-file-audio::before {
  content: "";
}

.fa-turn-down-left::before {
  content: "";
}

.fa-lock-hashtag::before {
  content: "";
}

.fa-chart-radar::before {
  content: "";
}

.fa-staff::before {
  content: "";
}

.fa-burger::before {
  content: "";
}

.fa-hamburger::before {
  content: "";
}

.fa-utility-pole::before {
  content: "";
}

.fa-transporter-6::before {
  content: "";
}

.fa-wrench::before {
  content: "";
}

.fa-bugs::before {
  content: "";
}

.fa-vector-polygon::before {
  content: "";
}

.fa-diagram-nested::before {
  content: "";
}

.fa-rupee-sign::before {
  content: "";
}

.fa-rupee::before {
  content: "";
}

.fa-file-image::before {
  content: "";
}

.fa-circle-question::before {
  content: "";
}

.fa-question-circle::before {
  content: "";
}

.fa-image-user::before {
  content: "";
}

.fa-buoy::before {
  content: "";
}

.fa-plane-departure::before {
  content: "";
}

.fa-handshake-slash::before {
  content: "";
}

.fa-book-bookmark::before {
  content: "";
}

.fa-border-center-h::before {
  content: "";
}

.fa-can-food::before {
  content: "";
}

.fa-typewriter::before {
  content: "";
}

.fa-arrow-right-from-arc::before {
  content: "";
}

.fa-circle-k::before {
  content: "";
}

.fa-face-hand-over-mouth::before {
  content: "";
}

.fa-popcorn::before {
  content: "";
}

.fa-house-water::before {
  content: "";
}

.fa-house-flood::before {
  content: "";
}

.fa-object-subtract::before {
  content: "";
}

.fa-code-branch::before {
  content: "";
}

.fa-warehouse-full::before {
  content: "";
}

.fa-warehouse-alt::before {
  content: "";
}

.fa-hat-cowboy::before {
  content: "";
}

.fa-bridge::before {
  content: "";
}

.fa-phone-flip::before {
  content: "";
}

.fa-phone-alt::before {
  content: "";
}

.fa-arrow-down-from-dotted-line::before {
  content: "";
}

.fa-file-doc::before {
  content: "";
}

.fa-square-quarters::before {
  content: "";
}

.fa-truck-front::before {
  content: "";
}

.fa-cat::before {
  content: "";
}

.fa-trash-xmark::before {
  content: "";
}

.fa-circle-caret-left::before {
  content: "";
}

.fa-caret-circle-left::before {
  content: "";
}

.fa-files::before {
  content: "";
}

.fa-anchor-circle-exclamation::before {
  content: "";
}

.fa-face-clouds::before {
  content: "";
}

.fa-user-crown::before {
  content: "";
}

.fa-truck-field::before {
  content: "";
}

.fa-route::before {
  content: "";
}

.fa-cart-circle-check::before {
  content: "";
}

.fa-clipboard-question::before {
  content: "";
}

.fa-panorama::before {
  content: "";
}

.fa-comment-medical::before {
  content: "";
}

.fa-teeth-open::before {
  content: "";
}

.fa-user-tie-hair-long::before {
  content: "";
}

.fa-file-circle-minus::before {
  content: "";
}

.fa-head-side-medical::before {
  content: "";
}

.fa-tags::before {
  content: "";
}

.fa-wine-glass::before {
  content: "";
}

.fa-forward-fast::before {
  content: "";
}

.fa-fast-forward::before {
  content: "";
}

.fa-face-meh-blank::before {
  content: "";
}

.fa-meh-blank::before {
  content: "";
}

.fa-user-robot::before {
  content: "";
}

.fa-square-parking::before {
  content: "";
}

.fa-parking::before {
  content: "";
}

.fa-card-diamond::before {
  content: "";
}

.fa-face-zipper::before {
  content: "";
}

.fa-face-raised-eyebrow::before {
  content: "";
}

.fa-house-signal::before {
  content: "";
}

.fa-square-chevron-up::before {
  content: "";
}

.fa-chevron-square-up::before {
  content: "";
}

.fa-bars-progress::before {
  content: "";
}

.fa-tasks-alt::before {
  content: "";
}

.fa-faucet-drip::before {
  content: "";
}

.fa-arrows-to-line::before {
  content: "";
}

.fa-dolphin::before {
  content: "";
}

.fa-arrow-up-right::before {
  content: "";
}

.fa-circle-r::before {
  content: "";
}

.fa-cart-flatbed::before {
  content: "";
}

.fa-dolly-flatbed::before {
  content: "";
}

.fa-ban-smoking::before {
  content: "";
}

.fa-smoking-ban::before {
  content: "";
}

.fa-circle-sort-up::before {
  content: "";
}

.fa-sort-circle-up::before {
  content: "";
}

.fa-terminal::before {
  content: "";
}

.fa-mobile-button::before {
  content: "";
}

.fa-house-medical-flag::before {
  content: "";
}

.fa-basket-shopping::before {
  content: "";
}

.fa-shopping-basket::before {
  content: "";
}

.fa-tape::before {
  content: "";
}

.fa-chestnut::before {
  content: "";
}

.fa-bus-simple::before {
  content: "";
}

.fa-bus-alt::before {
  content: "";
}

.fa-eye::before {
  content: "";
}

.fa-face-sad-cry::before {
  content: "";
}

.fa-sad-cry::before {
  content: "";
}

.fa-heat::before {
  content: "";
}

.fa-ticket-airline::before {
  content: "";
}

.fa-boot-heeled::before {
  content: "";
}

.fa-arrows-minimize::before {
  content: "";
}

.fa-compress-arrows::before {
  content: "";
}

.fa-audio-description::before {
  content: "";
}

.fa-person-military-to-person::before {
  content: "";
}

.fa-file-shield::before {
  content: "";
}

.fa-hexagon::before {
  content: "";
}

.fa-manhole::before {
  content: "";
}

.fa-user-slash::before {
  content: "";
}

.fa-pen::before {
  content: "";
}

.fa-tower-observation::before {
  content: "";
}

.fa-floppy-disks::before {
  content: "";
}

.fa-toilet-paper-blank-under::before {
  content: "";
}

.fa-toilet-paper-reverse-alt::before {
  content: "";
}

.fa-file-code::before {
  content: "";
}

.fa-signal::before {
  content: "";
}

.fa-signal-5::before {
  content: "";
}

.fa-signal-perfect::before {
  content: "";
}

.fa-pump::before {
  content: "";
}

.fa-bus::before {
  content: "";
}

.fa-heart-circle-xmark::before {
  content: "";
}

.fa-arrow-up-left-from-circle::before {
  content: "";
}

.fa-house-chimney::before {
  content: "";
}

.fa-home-lg::before {
  content: "";
}

.fa-window-maximize::before {
  content: "";
}

.fa-dryer::before {
  content: "";
}

.fa-face-frown::before {
  content: "";
}

.fa-frown::before {
  content: "";
}

.fa-chess-bishop-piece::before {
  content: "";
}

.fa-chess-bishop-alt::before {
  content: "";
}

.fa-shirt-tank-top::before {
  content: "";
}

.fa-diploma::before {
  content: "";
}

.fa-scroll-ribbon::before {
  content: "";
}

.fa-screencast::before {
  content: "";
}

.fa-walker::before {
  content: "";
}

.fa-prescription::before {
  content: "";
}

.fa-shop::before {
  content: "";
}

.fa-store-alt::before {
  content: "";
}

.fa-floppy-disk::before {
  content: "";
}

.fa-save::before {
  content: "";
}

.fa-vihara::before {
  content: "";
}

.fa-face-kiss-closed-eyes::before {
  content: "";
}

.fa-scale-unbalanced::before {
  content: "";
}

.fa-balance-scale-left::before {
  content: "";
}

.fa-file-user::before {
  content: "";
}

.fa-user-police-tie::before {
  content: "";
}

.fa-face-tongue-money::before {
  content: "";
}

.fa-tennis-ball::before {
  content: "";
}

.fa-square-l::before {
  content: "";
}

.fa-sort-up::before {
  content: "";
}

.fa-sort-asc::before {
  content: "";
}

.fa-calendar-arrow-up::before {
  content: "";
}

.fa-calendar-upload::before {
  content: "";
}

.fa-comment-dots::before {
  content: "";
}

.fa-commenting::before {
  content: "";
}

.fa-plant-wilt::before {
  content: "";
}

.fa-scarf::before {
  content: "";
}

.fa-album-circle-plus::before {
  content: "";
}

.fa-user-nurse-hair-long::before {
  content: "";
}

.fa-diamond::before {
  content: "";
}

.fa-square-left::before {
  content: "";
}

.fa-arrow-alt-square-left::before {
  content: "";
}

.fa-face-grin-squint::before {
  content: "";
}

.fa-grin-squint::before {
  content: "";
}

.fa-circle-ellipsis-vertical::before {
  content: "";
}

.fa-hand-holding-dollar::before {
  content: "";
}

.fa-hand-holding-usd::before {
  content: "";
}

.fa-grid-dividers::before {
  content: "";
}

.fa-bacterium::before {
  content: "";
}

.fa-hand-pointer::before {
  content: "";
}

.fa-drum-steelpan::before {
  content: "";
}

.fa-hand-scissors::before {
  content: "";
}

.fa-hands-praying::before {
  content: "";
}

.fa-praying-hands::before {
  content: "";
}

.fa-face-pensive::before {
  content: "";
}

.fa-user-music::before {
  content: "";
}

.fa-arrow-rotate-right::before {
  content: "";
}

.fa-arrow-right-rotate::before {
  content: "";
}

.fa-arrow-rotate-forward::before {
  content: "";
}

.fa-redo::before {
  content: "";
}

.fa-messages-dollar::before {
  content: "";
}

.fa-comments-alt-dollar::before {
  content: "";
}

.fa-sensor-on::before {
  content: "";
}

.fa-balloon::before {
  content: "";
}

.fa-biohazard::before {
  content: "";
}

.fa-chess-queen-piece::before {
  content: "";
}

.fa-chess-queen-alt::before {
  content: "";
}

.fa-location-crosshairs::before {
  content: "";
}

.fa-location::before {
  content: "";
}

.fa-mars-double::before {
  content: "";
}

.fa-house-person-leave::before {
  content: "";
}

.fa-house-leave::before {
  content: "";
}

.fa-house-person-depart::before {
  content: "";
}

.fa-ruler-triangle::before {
  content: "";
}

.fa-card-club::before {
  content: "";
}

.fa-child-dress::before {
  content: "";
}

.fa-users-between-lines::before {
  content: "";
}

.fa-lungs-virus::before {
  content: "";
}

.fa-spinner-third::before {
  content: "";
}

.fa-face-grin-tears::before {
  content: "";
}

.fa-grin-tears::before {
  content: "";
}

.fa-phone::before {
  content: "";
}

.fa-computer-mouse-scrollwheel::before {
  content: "";
}

.fa-mouse-alt::before {
  content: "";
}

.fa-calendar-xmark::before {
  content: "";
}

.fa-calendar-times::before {
  content: "";
}

.fa-child-reaching::before {
  content: "";
}

.fa-table-layout::before {
  content: "";
}

.fa-narwhal::before {
  content: "";
}

.fa-ramp-loading::before {
  content: "";
}

.fa-calendar-circle-plus::before {
  content: "";
}

.fa-toothbrush::before {
  content: "";
}

.fa-border-inner::before {
  content: "";
}

.fa-paw-claws::before {
  content: "";
}

.fa-kiwi-fruit::before {
  content: "";
}

.fa-traffic-light-slow::before {
  content: "";
}

.fa-rectangle-code::before {
  content: "";
}

.fa-head-side-virus::before {
  content: "";
}

.fa-keyboard-brightness::before {
  content: "";
}

.fa-books-medical::before {
  content: "";
}

.fa-lightbulb-slash::before {
  content: "";
}

.fa-house-blank::before {
  content: "";
}

.fa-home-blank::before {
  content: "";
}

.fa-square-5::before {
  content: "";
}

.fa-square-heart::before {
  content: "";
}

.fa-heart-square::before {
  content: "";
}

.fa-puzzle::before {
  content: "";
}

.fa-user-gear::before {
  content: "";
}

.fa-user-cog::before {
  content: "";
}

.fa-pipe-circle-check::before {
  content: "";
}

.fa-arrow-up-1-9::before {
  content: "";
}

.fa-sort-numeric-up::before {
  content: "";
}

.fa-octagon-exclamation::before {
  content: "";
}

.fa-dial-low::before {
  content: "";
}

.fa-door-closed::before {
  content: "";
}

.fa-laptop-mobile::before {
  content: "";
}

.fa-phone-laptop::before {
  content: "";
}

.fa-conveyor-belt-boxes::before {
  content: "";
}

.fa-conveyor-belt-alt::before {
  content: "";
}

.fa-shield-virus::before {
  content: "";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "";
}

.fa-starfighter-alt-advanced::before {
  content: "";
}

.fa-dice-six::before {
  content: "";
}

.fa-starfighter-twin-ion-engine::before {
  content: "";
}

.fa-starfighter-alt::before {
  content: "";
}

.fa-rocket-launch::before {
  content: "";
}

.fa-mosquito-net::before {
  content: "";
}

.fa-vent-damper::before {
  content: "";
}

.fa-bridge-water::before {
  content: "";
}

.fa-ban-bug::before {
  content: "";
}

.fa-debug::before {
  content: "";
}

.fa-person-booth::before {
  content: "";
}

.fa-text-width::before {
  content: "";
}

.fa-garage-car::before {
  content: "";
}

.fa-square-kanban::before {
  content: "";
}

.fa-hat-wizard::before {
  content: "";
}

.fa-pen-fancy::before {
  content: "";
}

.fa-coffee-pot::before {
  content: "";
}

.fa-mouse-field::before {
  content: "";
}

.fa-person-digging::before {
  content: "";
}

.fa-digging::before {
  content: "";
}

.fa-shower-down::before {
  content: "";
}

.fa-shower-alt::before {
  content: "";
}

.fa-box-circle-check::before {
  content: "";
}

.fa-brightness::before {
  content: "";
}

.fa-car-side-bolt::before {
  content: "";
}

.fa-ornament::before {
  content: "";
}

.fa-phone-arrow-down-left::before {
  content: "";
}

.fa-phone-arrow-down::before {
  content: "";
}

.fa-phone-incoming::before {
  content: "";
}

.fa-cloud-word::before {
  content: "";
}

.fa-hand-fingers-crossed::before {
  content: "";
}

.fa-trash::before {
  content: "";
}

.fa-gauge-simple::before {
  content: "";
}

.fa-gauge-simple-med::before {
  content: "";
}

.fa-tachometer-average::before {
  content: "";
}

.fa-arrow-down-small-big::before {
  content: "";
}

.fa-sort-size-down-alt::before {
  content: "";
}

.fa-book-medical::before {
  content: "";
}

.fa-face-melting::before {
  content: "";
}

.fa-poo::before {
  content: "";
}

.fa-pen-clip-slash::before {
  content: "";
}

.fa-pen-alt-slash::before {
  content: "";
}

.fa-quote-right::before {
  content: "";
}

.fa-quote-right-alt::before {
  content: "";
}

.fa-scroll-old::before {
  content: "";
}

.fa-guitars::before {
  content: "";
}

.fa-phone-xmark::before {
  content: "";
}

.fa-hose::before {
  content: "";
}

.fa-clock-six::before {
  content: "";
}

.fa-shirt::before {
  content: "";
}

.fa-t-shirt::before {
  content: "";
}

.fa-tshirt::before {
  content: "";
}

.fa-billboard::before {
  content: "";
}

.fa-square-r::before {
  content: "";
}

.fa-cubes::before {
  content: "";
}

.fa-envelope-open-dollar::before {
  content: "";
}

.fa-divide::before {
  content: "";
}

.fa-sun-cloud::before {
  content: "";
}

.fa-lamp-floor::before {
  content: "";
}

.fa-square-7::before {
  content: "";
}

.fa-tenge-sign::before {
  content: "";
}

.fa-tenge::before {
  content: "";
}

.fa-headphones::before {
  content: "";
}

.fa-hands-holding::before {
  content: "";
}

.fa-campfire::before {
  content: "";
}

.fa-circle-ampersand::before {
  content: "";
}

.fa-snowflakes::before {
  content: "";
}

.fa-hands-clapping::before {
  content: "";
}

.fa-republican::before {
  content: "";
}

.fa-leaf-maple::before {
  content: "";
}

.fa-arrow-left::before {
  content: "";
}

.fa-person-circle-xmark::before {
  content: "";
}

.fa-ruler::before {
  content: "";
}

.fa-cup-straw-swoosh::before {
  content: "";
}

.fa-temperature-sun::before {
  content: "";
}

.fa-temperature-hot::before {
  content: "";
}

.fa-align-left::before {
  content: "";
}

.fa-dice-d6::before {
  content: "";
}

.fa-restroom::before {
  content: "";
}

.fa-high-definition::before {
  content: "";
}

.fa-rectangle-hd::before {
  content: "";
}

.fa-j::before {
  content: "J";
}

.fa-galaxy::before {
  content: "";
}

.fa-users-viewfinder::before {
  content: "";
}

.fa-file-video::before {
  content: "";
}

.fa-cherries::before {
  content: "";
}

.fa-up-right-from-square::before {
  content: "";
}

.fa-external-link-alt::before {
  content: "";
}

.fa-circle-sort::before {
  content: "";
}

.fa-sort-circle::before {
  content: "";
}

.fa-table-cells::before {
  content: "";
}

.fa-th::before {
  content: "";
}

.fa-file-pdf::before {
  content: "";
}

.fa-siren::before {
  content: "";
}

.fa-arrow-up-to-dotted-line::before {
  content: "";
}

.fa-image-landscape::before {
  content: "";
}

.fa-landscape::before {
  content: "";
}

.fa-tank-water::before {
  content: "";
}

.fa-curling-stone::before {
  content: "";
}

.fa-curling::before {
  content: "";
}

.fa-gamepad-modern::before {
  content: "";
}

.fa-gamepad-alt::before {
  content: "";
}

.fa-messages-question::before {
  content: "";
}

.fa-book-bible::before {
  content: "";
}

.fa-bible::before {
  content: "";
}

.fa-o::before {
  content: "O";
}

.fa-suitcase-medical::before {
  content: "";
}

.fa-medkit::before {
  content: "";
}

.fa-briefcase-arrow-right::before {
  content: "";
}

.fa-expand-wide::before {
  content: "";
}

.fa-clock-eleven-thirty::before {
  content: "";
}

.fa-rv::before {
  content: "";
}

.fa-user-secret::before {
  content: "";
}

.fa-otter::before {
  content: "";
}

.fa-dreidel::before {
  content: "";
}

.fa-person-dress::before {
  content: "";
}

.fa-female::before {
  content: "";
}

.fa-comment-dollar::before {
  content: "";
}

.fa-business-time::before {
  content: "";
}

.fa-briefcase-clock::before {
  content: "";
}

.fa-flower-tulip::before {
  content: "";
}

.fa-people-pants-simple::before {
  content: "";
}

.fa-cloud-drizzle::before {
  content: "";
}

.fa-table-cells-large::before {
  content: "";
}

.fa-th-large::before {
  content: "";
}

.fa-book-tanakh::before {
  content: "";
}

.fa-tanakh::before {
  content: "";
}

.fa-solar-system::before {
  content: "";
}

.fa-seal-question::before {
  content: "";
}

.fa-phone-volume::before {
  content: "";
}

.fa-volume-control-phone::before {
  content: "";
}

.fa-disc-drive::before {
  content: "";
}

.fa-hat-cowboy-side::before {
  content: "";
}

.fa-table-rows::before {
  content: "";
}

.fa-rows::before {
  content: "";
}

.fa-location-exclamation::before {
  content: "";
}

.fa-map-marker-exclamation::before {
  content: "";
}

.fa-face-fearful::before {
  content: "";
}

.fa-clipboard-user::before {
  content: "";
}

.fa-bus-school::before {
  content: "";
}

.fa-film-slash::before {
  content: "";
}

.fa-square-arrow-down-right::before {
  content: "";
}

.fa-book-sparkles::before {
  content: "";
}

.fa-book-spells::before {
  content: "";
}

.fa-washing-machine::before {
  content: "";
}

.fa-washer::before {
  content: "";
}

.fa-child::before {
  content: "";
}

.fa-lira-sign::before {
  content: "";
}

.fa-user-visor::before {
  content: "";
}

.fa-file-plus-minus::before {
  content: "";
}

.fa-chess-clock-flip::before {
  content: "";
}

.fa-chess-clock-alt::before {
  content: "";
}

.fa-satellite::before {
  content: "";
}

.fa-plane-lock::before {
  content: "";
}

.fa-steering-wheel::before {
  content: "";
}

.fa-tag::before {
  content: "";
}

.fa-stretcher::before {
  content: "";
}

.fa-book-section::before {
  content: "";
}

.fa-book-law::before {
  content: "";
}

.fa-inboxes::before {
  content: "";
}

.fa-coffee-bean::before {
  content: "";
}

.fa-circle-yen::before {
  content: "";
}

.fa-brackets-curly::before {
  content: "";
}

.fa-ellipsis-stroke-vertical::before {
  content: "";
}

.fa-ellipsis-v-alt::before {
  content: "";
}

.fa-comment::before {
  content: "";
}

.fa-square-1::before {
  content: "";
}

.fa-cake-candles::before {
  content: "";
}

.fa-birthday-cake::before {
  content: "";
}

.fa-cake::before {
  content: "";
}

.fa-head-side::before {
  content: "";
}

.fa-envelope::before {
  content: "";
}

.fa-dolly-empty::before {
  content: "";
}

.fa-face-tissue::before {
  content: "";
}

.fa-angles-up::before {
  content: "";
}

.fa-angle-double-up::before {
  content: "";
}

.fa-bin-recycle::before {
  content: "";
}

.fa-paperclip::before {
  content: "";
}

.fa-chart-line-down::before {
  content: "";
}

.fa-arrow-right-to-city::before {
  content: "";
}

.fa-lock-a::before {
  content: "";
}

.fa-ribbon::before {
  content: "";
}

.fa-lungs::before {
  content: "";
}

.fa-person-pinball::before {
  content: "";
}

.fa-arrow-up-9-1::before {
  content: "";
}

.fa-sort-numeric-up-alt::before {
  content: "";
}

.fa-apple-core::before {
  content: "";
}

.fa-circle-y::before {
  content: "";
}

.fa-h6::before {
  content: "";
}

.fa-litecoin-sign::before {
  content: "";
}

.fa-circle-small::before {
  content: "";
}

.fa-border-none::before {
  content: "";
}

.fa-arrow-turn-down-left::before {
  content: "";
}

.fa-circle-nodes::before {
  content: "";
}

.fa-parachute-box::before {
  content: "";
}

.fa-message-medical::before {
  content: "";
}

.fa-comment-alt-medical::before {
  content: "";
}

.fa-rugby-ball::before {
  content: "";
}

.fa-comment-music::before {
  content: "";
}

.fa-indent::before {
  content: "";
}

.fa-tree-deciduous::before {
  content: "";
}

.fa-tree-alt::before {
  content: "";
}

.fa-puzzle-piece-simple::before {
  content: "";
}

.fa-puzzle-piece-alt::before {
  content: "";
}

.fa-truck-field-un::before {
  content: "";
}

.fa-nfc-trash::before {
  content: "";
}

.fa-hourglass::before {
  content: "";
}

.fa-hourglass-empty::before {
  content: "";
}

.fa-mountain::before {
  content: "";
}

.fa-file-xmark::before {
  content: "";
}

.fa-file-times::before {
  content: "";
}

.fa-house-heart::before {
  content: "";
}

.fa-home-heart::before {
  content: "";
}

.fa-house-chimney-blank::before {
  content: "";
}

.fa-meter-bolt::before {
  content: "";
}

.fa-user-doctor::before {
  content: "";
}

.fa-user-md::before {
  content: "";
}

.fa-slash-back::before {
  content: "\\";
}

.fa-circle-info::before {
  content: "";
}

.fa-info-circle::before {
  content: "";
}

.fa-fishing-rod::before {
  content: "";
}

.fa-hammer-crash::before {
  content: "";
}

.fa-message-heart::before {
  content: "";
}

.fa-cloud-meatball::before {
  content: "";
}

.fa-camera-polaroid::before {
  content: "";
}

.fa-camera::before {
  content: "";
}

.fa-camera-alt::before {
  content: "";
}

.fa-square-virus::before {
  content: "";
}

.fa-cart-arrow-up::before {
  content: "";
}

.fa-meteor::before {
  content: "";
}

.fa-car-on::before {
  content: "";
}

.fa-sleigh::before {
  content: "";
}

.fa-arrow-down-1-9::before {
  content: "";
}

.fa-sort-numeric-asc::before {
  content: "";
}

.fa-sort-numeric-down::before {
  content: "";
}

.fa-buoy-mooring::before {
  content: "";
}

.fa-square-4::before {
  content: "";
}

.fa-hand-holding-droplet::before {
  content: "";
}

.fa-hand-holding-water::before {
  content: "";
}

.fa-tricycle-adult::before {
  content: "";
}

.fa-waveform::before {
  content: "";
}

.fa-water::before {
  content: "";
}

.fa-star-sharp-half-stroke::before {
  content: "";
}

.fa-star-sharp-half-alt::before {
  content: "";
}

.fa-nfc-signal::before {
  content: "";
}

.fa-plane-prop::before {
  content: "";
}

.fa-calendar-check::before {
  content: "";
}

.fa-clock-desk::before {
  content: "";
}

.fa-calendar-clock::before {
  content: "";
}

.fa-calendar-time::before {
  content: "";
}

.fa-braille::before {
  content: "";
}

.fa-prescription-bottle-medical::before {
  content: "";
}

.fa-prescription-bottle-alt::before {
  content: "";
}

.fa-plate-utensils::before {
  content: "";
}

.fa-family-pants::before {
  content: "";
}

.fa-hose-reel::before {
  content: "";
}

.fa-house-window::before {
  content: "";
}

.fa-landmark::before {
  content: "";
}

.fa-truck::before {
  content: "";
}

.fa-crosshairs::before {
  content: "";
}

.fa-cloud-rainbow::before {
  content: "";
}

.fa-person-cane::before {
  content: "";
}

.fa-alien::before {
  content: "";
}

.fa-tent::before {
  content: "";
}

.fa-laptop-binary::before {
  content: "";
}

.fa-vest-patches::before {
  content: "";
}

.fa-people-dress-simple::before {
  content: "";
}

.fa-check-double::before {
  content: "";
}

.fa-arrow-down-a-z::before {
  content: "";
}

.fa-sort-alpha-asc::before {
  content: "";
}

.fa-sort-alpha-down::before {
  content: "";
}

.fa-bowling-ball-pin::before {
  content: "";
}

.fa-bell-school-slash::before {
  content: "";
}

.fa-plus-large::before {
  content: "";
}

.fa-money-bill-wheat::before {
  content: "";
}

.fa-camera-viewfinder::before {
  content: "";
}

.fa-screenshot::before {
  content: "";
}

.fa-message-music::before {
  content: "";
}

.fa-comment-alt-music::before {
  content: "";
}

.fa-car-building::before {
  content: "";
}

.fa-border-bottom-right::before {
  content: "";
}

.fa-border-style-alt::before {
  content: "";
}

.fa-octagon::before {
  content: "";
}

.fa-comment-arrow-up-right::before {
  content: "";
}

.fa-octagon-divide::before {
  content: "";
}

.fa-cookie::before {
  content: "";
}

.fa-arrow-rotate-left::before {
  content: "";
}

.fa-arrow-left-rotate::before {
  content: "";
}

.fa-arrow-rotate-back::before {
  content: "";
}

.fa-arrow-rotate-backward::before {
  content: "";
}

.fa-undo::before {
  content: "";
}

.fa-tv-music::before {
  content: "";
}

.fa-hard-drive::before {
  content: "";
}

.fa-hdd::before {
  content: "";
}

.fa-reel::before {
  content: "";
}

.fa-face-grin-squint-tears::before {
  content: "";
}

.fa-grin-squint-tears::before {
  content: "";
}

.fa-dumbbell::before {
  content: "";
}

.fa-rectangle-list::before {
  content: "";
}

.fa-list-alt::before {
  content: "";
}

.fa-tarp-droplet::before {
  content: "";
}

.fa-alarm-exclamation::before {
  content: "";
}

.fa-house-medical-circle-check::before {
  content: "";
}

.fa-traffic-cone::before {
  content: "";
}

.fa-grate::before {
  content: "";
}

.fa-arrow-down-right::before {
  content: "";
}

.fa-person-skiing-nordic::before {
  content: "";
}

.fa-skiing-nordic::before {
  content: "";
}

.fa-calendar-plus::before {
  content: "";
}

.fa-person-from-portal::before {
  content: "";
}

.fa-portal-exit::before {
  content: "";
}

.fa-plane-arrival::before {
  content: "";
}

.fa-cowbell-circle-plus::before {
  content: "";
}

.fa-cowbell-more::before {
  content: "";
}

.fa-circle-left::before {
  content: "";
}

.fa-arrow-alt-circle-left::before {
  content: "";
}

.fa-distribute-spacing-vertical::before {
  content: "";
}

.fa-signal-bars-fair::before {
  content: "";
}

.fa-signal-alt-2::before {
  content: "";
}

.fa-sportsball::before {
  content: "";
}

.fa-game-console-handheld-crank::before {
  content: "";
}

.fa-train-subway::before {
  content: "";
}

.fa-subway::before {
  content: "";
}

.fa-chart-gantt::before {
  content: "";
}

.fa-face-smile-upside-down::before {
  content: "";
}

.fa-ball-pile::before {
  content: "";
}

.fa-badge-dollar::before {
  content: "";
}

.fa-money-bills-simple::before {
  content: "";
}

.fa-money-bills-alt::before {
  content: "";
}

.fa-list-timeline::before {
  content: "";
}

.fa-indian-rupee-sign::before {
  content: "";
}

.fa-indian-rupee::before {
  content: "";
}

.fa-inr::before {
  content: "";
}

.fa-crop-simple::before {
  content: "";
}

.fa-crop-alt::before {
  content: "";
}

.fa-money-bill-1::before {
  content: "";
}

.fa-money-bill-alt::before {
  content: "";
}

.fa-left-long::before {
  content: "";
}

.fa-long-arrow-alt-left::before {
  content: "";
}

.fa-keyboard-down::before {
  content: "";
}

.fa-circle-up-right::before {
  content: "";
}

.fa-cloud-bolt-moon::before {
  content: "";
}

.fa-thunderstorm-moon::before {
  content: "";
}

.fa-dna::before {
  content: "";
}

.fa-virus-slash::before {
  content: "";
}

.fa-bracket-round-right::before {
  content: "\)";
}

.fa-circle-sterling::before {
  content: "";
}

.fa-circle-5::before {
  content: "";
}

.fa-minus::before {
  content: "";
}

.fa-subtract::before {
  content: "";
}

.fa-fire-flame::before {
  content: "";
}

.fa-flame::before {
  content: "";
}

.fa-right-to-line::before {
  content: "";
}

.fa-arrow-alt-to-right::before {
  content: "";
}

.fa-gif::before {
  content: "";
}

.fa-chess::before {
  content: "";
}

.fa-trash-slash::before {
  content: "";
}

.fa-arrow-left-long::before {
  content: "";
}

.fa-long-arrow-left::before {
  content: "";
}

.fa-plug-circle-check::before {
  content: "";
}

.fa-font-case::before {
  content: "";
}

.fa-street-view::before {
  content: "";
}

.fa-arrow-down-left::before {
  content: "";
}

.fa-franc-sign::before {
  content: "";
}

.fa-flask-round-poison::before {
  content: "";
}

.fa-flask-poison::before {
  content: "";
}

.fa-volume-off::before {
  content: "";
}

.fa-book-circle-arrow-right::before {
  content: "";
}

.fa-chart-user::before {
  content: "";
}

.fa-user-chart::before {
  content: "";
}

.fa-hands-asl-interpreting::before {
  content: "";
}

.fa-american-sign-language-interpreting::before {
  content: "";
}

.fa-asl-interpreting::before {
  content: "";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "";
}

.fa-presentation-screen::before {
  content: "";
}

.fa-presentation::before {
  content: "";
}

.fa-circle-bolt::before {
  content: "";
}

.fa-face-smile-halo::before {
  content: "";
}

.fa-cart-circle-arrow-down::before {
  content: "";
}

.fa-house-person-return::before {
  content: "";
}

.fa-house-person-arrive::before {
  content: "";
}

.fa-house-return::before {
  content: "";
}

.fa-message-xmark::before {
  content: "";
}

.fa-comment-alt-times::before {
  content: "";
}

.fa-message-times::before {
  content: "";
}

.fa-file-certificate::before {
  content: "";
}

.fa-file-award::before {
  content: "";
}

.fa-user-doctor-hair-long::before {
  content: "";
}

.fa-camera-security::before {
  content: "";
}

.fa-camera-home::before {
  content: "";
}

.fa-gear::before {
  content: "";
}

.fa-cog::before {
  content: "";
}

.fa-droplet-slash::before {
  content: "";
}

.fa-tint-slash::before {
  content: "";
}

.fa-book-heart::before {
  content: "";
}

.fa-mosque::before {
  content: "";
}

.fa-duck::before {
  content: "";
}

.fa-mosquito::before {
  content: "";
}

.fa-star-of-david::before {
  content: "";
}

.fa-flag-swallowtail::before {
  content: "";
}

.fa-flag-alt::before {
  content: "";
}

.fa-person-military-rifle::before {
  content: "";
}

.fa-car-garage::before {
  content: "";
}

.fa-cart-shopping::before {
  content: "";
}

.fa-shopping-cart::before {
  content: "";
}

.fa-book-font::before {
  content: "";
}

.fa-shield-plus::before {
  content: "";
}

.fa-vials::before {
  content: "";
}

.fa-eye-dropper-full::before {
  content: "";
}

.fa-distribute-spacing-horizontal::before {
  content: "";
}

.fa-tablet-rugged::before {
  content: "";
}

.fa-temperature-snow::before {
  content: "";
}

.fa-temperature-frigid::before {
  content: "";
}

.fa-moped::before {
  content: "";
}

.fa-face-smile-plus::before {
  content: "";
}

.fa-smile-plus::before {
  content: "";
}

.fa-radio-tuner::before {
  content: "";
}

.fa-radio-alt::before {
  content: "";
}

.fa-face-swear::before {
  content: "";
}

.fa-water-arrow-down::before {
  content: "";
}

.fa-water-lower::before {
  content: "";
}

.fa-scanner-touchscreen::before {
  content: "";
}

.fa-circle-7::before {
  content: "";
}

.fa-plug-circle-plus::before {
  content: "";
}

.fa-person-ski-jumping::before {
  content: "";
}

.fa-ski-jump::before {
  content: "";
}

.fa-place-of-worship::before {
  content: "";
}

.fa-water-arrow-up::before {
  content: "";
}

.fa-water-rise::before {
  content: "";
}

.fa-waveform-lines::before {
  content: "";
}

.fa-waveform-path::before {
  content: "";
}

.fa-split::before {
  content: "";
}

.fa-film-canister::before {
  content: "";
}

.fa-film-cannister::before {
  content: "";
}

.fa-folder-xmark::before {
  content: "";
}

.fa-folder-times::before {
  content: "";
}

.fa-toilet-paper-blank::before {
  content: "";
}

.fa-toilet-paper-alt::before {
  content: "";
}

.fa-tablet-screen::before {
  content: "";
}

.fa-tablet-android-alt::before {
  content: "";
}

.fa-hexagon-vertical-nft-slanted::before {
  content: "";
}

.fa-folder-music::before {
  content: "";
}

.fa-display-medical::before {
  content: "";
}

.fa-desktop-medical::before {
  content: "";
}

.fa-share-all::before {
  content: "";
}

.fa-peapod::before {
  content: "";
}

.fa-chess-clock::before {
  content: "";
}

.fa-axe::before {
  content: "";
}

.fa-square-d::before {
  content: "";
}

.fa-grip-vertical::before {
  content: "";
}

.fa-mobile-signal-out::before {
  content: "";
}

.fa-arrow-turn-up::before {
  content: "";
}

.fa-level-up::before {
  content: "";
}

.fa-u::before {
  content: "U";
}

.fa-arrow-up-from-dotted-line::before {
  content: "";
}

.fa-square-root-variable::before {
  content: "";
}

.fa-square-root-alt::before {
  content: "";
}

.fa-light-switch-on::before {
  content: "";
}

.fa-arrow-down-arrow-up::before {
  content: "";
}

.fa-sort-alt::before {
  content: "";
}

.fa-raindrops::before {
  content: "";
}

.fa-dash::before {
  content: "";
}

.fa-minus-large::before {
  content: "";
}

.fa-clock::before {
  content: "";
}

.fa-clock-four::before {
  content: "";
}

.fa-input-numeric::before {
  content: "";
}

.fa-truck-tow::before {
  content: "";
}

.fa-backward-step::before {
  content: "";
}

.fa-step-backward::before {
  content: "";
}

.fa-pallet::before {
  content: "";
}

.fa-car-bolt::before {
  content: "";
}

.fa-arrows-maximize::before {
  content: "";
}

.fa-expand-arrows::before {
  content: "";
}

.fa-faucet::before {
  content: "";
}

.fa-cloud-sleet::before {
  content: "";
}

.fa-lamp-street::before {
  content: "";
}

.fa-list-radio::before {
  content: "";
}

.fa-pen-nib-slash::before {
  content: "";
}

.fa-baseball-bat-ball::before {
  content: "";
}

.fa-square-up-left::before {
  content: "";
}

.fa-overline::before {
  content: "";
}

.fa-s::before {
  content: "S";
}

.fa-timeline::before {
  content: "";
}

.fa-keyboard::before {
  content: "";
}

.fa-arrows-from-dotted-line::before {
  content: "";
}

.fa-usb-drive::before {
  content: "";
}

.fa-ballot::before {
  content: "";
}

.fa-caret-down::before {
  content: "";
}

.fa-location-dot-slash::before {
  content: "";
}

.fa-map-marker-alt-slash::before {
  content: "";
}

.fa-cards::before {
  content: "";
}

.fa-house-chimney-medical::before {
  content: "";
}

.fa-clinic-medical::before {
  content: "";
}

.fa-boxing-glove::before {
  content: "";
}

.fa-glove-boxing::before {
  content: "";
}

.fa-temperature-three-quarters::before {
  content: "";
}

.fa-temperature-3::before {
  content: "";
}

.fa-thermometer-3::before {
  content: "";
}

.fa-thermometer-three-quarters::before {
  content: "";
}

.fa-bell-school::before {
  content: "";
}

.fa-mobile-screen::before {
  content: "";
}

.fa-mobile-android-alt::before {
  content: "";
}

.fa-plane-up::before {
  content: "";
}

.fa-folder-heart::before {
  content: "";
}

.fa-circle-location-arrow::before {
  content: "";
}

.fa-location-circle::before {
  content: "";
}

.fa-face-head-bandage::before {
  content: "";
}

.fa-sushi-roll::before {
  content: "";
}

.fa-maki-roll::before {
  content: "";
}

.fa-makizushi::before {
  content: "";
}

.fa-car-bump::before {
  content: "";
}

.fa-piggy-bank::before {
  content: "";
}

.fa-racquet::before {
  content: "";
}

.fa-car-mirrors::before {
  content: "";
}

.fa-industry-windows::before {
  content: "";
}

.fa-industry-alt::before {
  content: "";
}

.fa-bolt-auto::before {
  content: "";
}

.fa-battery-half::before {
  content: "";
}

.fa-battery-3::before {
  content: "";
}

.fa-flux-capacitor::before {
  content: "";
}

.fa-mountain-city::before {
  content: "";
}

.fa-coins::before {
  content: "";
}

.fa-honey-pot::before {
  content: "";
}

.fa-olive::before {
  content: "";
}

.fa-khanda::before {
  content: "";
}

.fa-filter-list::before {
  content: "";
}

.fa-outlet::before {
  content: "";
}

.fa-sliders::before {
  content: "";
}

.fa-sliders-h::before {
  content: "";
}

.fa-cauldron::before {
  content: "";
}

.fa-people::before {
  content: "";
}

.fa-folder-tree::before {
  content: "";
}

.fa-network-wired::before {
  content: "";
}

.fa-croissant::before {
  content: "";
}

.fa-map-pin::before {
  content: "";
}

.fa-hamsa::before {
  content: "";
}

.fa-cent-sign::before {
  content: "";
}

.fa-swords-laser::before {
  content: "";
}

.fa-flask::before {
  content: "";
}

.fa-person-pregnant::before {
  content: "";
}

.fa-square-u::before {
  content: "";
}

.fa-wand-sparkles::before {
  content: "";
}

.fa-router::before {
  content: "";
}

.fa-ellipsis-vertical::before {
  content: "";
}

.fa-ellipsis-v::before {
  content: "";
}

.fa-sword-laser-alt::before {
  content: "";
}

.fa-ticket::before {
  content: "";
}

.fa-power-off::before {
  content: "";
}

.fa-coin::before {
  content: "";
}

.fa-laptop-slash::before {
  content: "";
}

.fa-right-long::before {
  content: "";
}

.fa-long-arrow-alt-right::before {
  content: "";
}

.fa-circle-b::before {
  content: "";
}

.fa-person-dress-simple::before {
  content: "";
}

.fa-pipe-collar::before {
  content: "";
}

.fa-lights-holiday::before {
  content: "";
}

.fa-citrus::before {
  content: "";
}

.fa-flag-usa::before {
  content: "";
}

.fa-laptop-file::before {
  content: "";
}

.fa-tty::before {
  content: "";
}

.fa-teletype::before {
  content: "";
}

.fa-chart-tree-map::before {
  content: "";
}

.fa-diagram-next::before {
  content: "";
}

.fa-person-rifle::before {
  content: "";
}

.fa-clock-five-thirty::before {
  content: "";
}

.fa-pipe-valve::before {
  content: "";
}

.fa-arrow-up-from-arc::before {
  content: "";
}

.fa-face-spiral-eyes::before {
  content: "";
}

.fa-compress-wide::before {
  content: "";
}

.fa-circle-phone-hangup::before {
  content: "";
}

.fa-phone-circle-down::before {
  content: "";
}

.fa-gear-complex-code::before {
  content: "";
}

.fa-house-medical-circle-exclamation::before {
  content: "";
}

.fa-badminton::before {
  content: "";
}

.fa-closed-captioning::before {
  content: "";
}

.fa-person-hiking::before {
  content: "";
}

.fa-hiking::before {
  content: "";
}

.fa-right-from-line::before {
  content: "";
}

.fa-arrow-alt-from-left::before {
  content: "";
}

.fa-venus-double::before {
  content: "";
}

.fa-images::before {
  content: "";
}

.fa-calculator::before {
  content: "";
}

.fa-shuttlecock::before {
  content: "";
}

.fa-user-hair::before {
  content: "";
}

.fa-eye-evil::before {
  content: "";
}

.fa-people-pulling::before {
  content: "";
}

.fa-n::before {
  content: "N";
}

.fa-garage::before {
  content: "";
}

.fa-cable-car::before {
  content: "";
}

.fa-tram::before {
  content: "";
}

.fa-shovel-snow::before {
  content: "";
}

.fa-cloud-rain::before {
  content: "";
}

.fa-face-lying::before {
  content: "";
}

.fa-sprinkler::before {
  content: "";
}

.fa-building-circle-xmark::before {
  content: "";
}

.fa-person-sledding::before {
  content: "";
}

.fa-sledding::before {
  content: "";
}

.fa-game-console-handheld::before {
  content: "";
}

.fa-ship::before {
  content: "";
}

.fa-clock-six-thirty::before {
  content: "";
}

.fa-battery-slash::before {
  content: "";
}

.fa-tugrik-sign::before {
  content: "";
}

.fa-arrows-down-to-line::before {
  content: "";
}

.fa-download::before {
  content: "";
}

.fa-shelves::before {
  content: "";
}

.fa-inventory::before {
  content: "";
}

.fa-cloud-snow::before {
  content: "";
}

.fa-face-grin::before {
  content: "";
}

.fa-grin::before {
  content: "";
}

.fa-delete-left::before {
  content: "";
}

.fa-backspace::before {
  content: "";
}

.fa-oven::before {
  content: "";
}

.fa-cloud-binary::before {
  content: "";
}

.fa-eye-dropper::before {
  content: "";
}

.fa-eye-dropper-empty::before {
  content: "";
}

.fa-eyedropper::before {
  content: "";
}

.fa-comment-captions::before {
  content: "";
}

.fa-comments-question::before {
  content: "";
}

.fa-scribble::before {
  content: "";
}

.fa-rotate-exclamation::before {
  content: "";
}

.fa-file-circle-check::before {
  content: "";
}

.fa-glass::before {
  content: "";
}

.fa-loader::before {
  content: "";
}

.fa-forward::before {
  content: "";
}

.fa-user-pilot::before {
  content: "";
}

.fa-mobile::before {
  content: "";
}

.fa-mobile-android::before {
  content: "";
}

.fa-mobile-phone::before {
  content: "";
}

.fa-code-pull-request-closed::before {
  content: "";
}

.fa-face-meh::before {
  content: "";
}

.fa-meh::before {
  content: "";
}

.fa-align-center::before {
  content: "";
}

.fa-book-skull::before {
  content: "";
}

.fa-book-dead::before {
  content: "";
}

.fa-id-card::before {
  content: "";
}

.fa-drivers-license::before {
  content: "";
}

.fa-face-dotted::before {
  content: "";
}

.fa-face-worried::before {
  content: "";
}

.fa-outdent::before {
  content: "";
}

.fa-dedent::before {
  content: "";
}

.fa-heart-circle-exclamation::before {
  content: "";
}

.fa-house::before {
  content: "";
}

.fa-home::before {
  content: "";
}

.fa-home-alt::before {
  content: "";
}

.fa-home-lg-alt::before {
  content: "";
}

.fa-vector-circle::before {
  content: "";
}

.fa-car-circle-bolt::before {
  content: "";
}

.fa-calendar-week::before {
  content: "";
}

.fa-flying-disc::before {
  content: "";
}

.fa-laptop-medical::before {
  content: "";
}

.fa-square-down-right::before {
  content: "";
}

.fa-b::before {
  content: "B";
}

.fa-seat-airline::before {
  content: "";
}

.fa-moon-over-sun::before {
  content: "";
}

.fa-eclipse-alt::before {
  content: "";
}

.fa-pipe::before {
  content: "\|";
}

.fa-file-medical::before {
  content: "";
}

.fa-potato::before {
  content: "";
}

.fa-dice-one::before {
  content: "";
}

.fa-circle-a::before {
  content: "";
}

.fa-helmet-battle::before {
  content: "";
}

.fa-butter::before {
  content: "";
}

.fa-blanket-fire::before {
  content: "";
}

.fa-kiwi-bird::before {
  content: "";
}

.fa-castle::before {
  content: "";
}

.fa-golf-club::before {
  content: "";
}

.fa-arrow-right-arrow-left::before {
  content: "";
}

.fa-exchange::before {
  content: "";
}

.fa-rotate-right::before {
  content: "";
}

.fa-redo-alt::before {
  content: "";
}

.fa-rotate-forward::before {
  content: "";
}

.fa-utensils::before {
  content: "";
}

.fa-cutlery::before {
  content: "";
}

.fa-arrow-up-wide-short::before {
  content: "";
}

.fa-sort-amount-up::before {
  content: "";
}

.fa-chart-pie-simple-circle-dollar::before {
  content: "";
}

.fa-balloons::before {
  content: "";
}

.fa-mill-sign::before {
  content: "";
}

.fa-bowl-rice::before {
  content: "";
}

.fa-timeline-arrow::before {
  content: "";
}

.fa-skull::before {
  content: "";
}

.fa-game-board-simple::before {
  content: "";
}

.fa-game-board-alt::before {
  content: "";
}

.fa-circle-video::before {
  content: "";
}

.fa-video-circle::before {
  content: "";
}

.fa-chart-scatter-bubble::before {
  content: "";
}

.fa-house-turret::before {
  content: "";
}

.fa-banana::before {
  content: "";
}

.fa-hand-holding-skull::before {
  content: "";
}

.fa-people-dress::before {
  content: "";
}

.fa-loveseat::before {
  content: "";
}

.fa-couch-small::before {
  content: "";
}

.fa-tower-broadcast::before {
  content: "";
}

.fa-broadcast-tower::before {
  content: "";
}

.fa-truck-pickup::before {
  content: "";
}

.fa-block-quote::before {
  content: "";
}

.fa-up-long::before {
  content: "";
}

.fa-long-arrow-alt-up::before {
  content: "";
}

.fa-stop::before {
  content: "";
}

.fa-code-merge::before {
  content: "";
}

.fa-money-check-dollar-pen::before {
  content: "";
}

.fa-money-check-edit-alt::before {
  content: "";
}

.fa-up-from-line::before {
  content: "";
}

.fa-arrow-alt-from-bottom::before {
  content: "";
}

.fa-upload::before {
  content: "";
}

.fa-hurricane::before {
  content: "";
}

.fa-grid-round-2-plus::before {
  content: "";
}

.fa-people-pants::before {
  content: "";
}

.fa-mound::before {
  content: "";
}

.fa-windsock::before {
  content: "";
}

.fa-circle-half::before {
  content: "";
}

.fa-brake-warning::before {
  content: "";
}

.fa-toilet-portable::before {
  content: "";
}

.fa-compact-disc::before {
  content: "";
}

.fa-file-arrow-down::before {
  content: "";
}

.fa-file-download::before {
  content: "";
}

.fa-saxophone-fire::before {
  content: "";
}

.fa-sax-hot::before {
  content: "";
}

.fa-camera-web-slash::before {
  content: "";
}

.fa-webcam-slash::before {
  content: "";
}

.fa-folder-medical::before {
  content: "";
}

.fa-folder-gear::before {
  content: "";
}

.fa-folder-cog::before {
  content: "";
}

.fa-hand-wave::before {
  content: "";
}

.fa-arrow-up-arrow-down::before {
  content: "";
}

.fa-sort-up-down::before {
  content: "";
}

.fa-caravan::before {
  content: "";
}

.fa-shield-cat::before {
  content: "";
}

.fa-message-slash::before {
  content: "";
}

.fa-comment-alt-slash::before {
  content: "";
}

.fa-bolt::before {
  content: "";
}

.fa-zap::before {
  content: "";
}

.fa-trash-can-check::before {
  content: "";
}

.fa-glass-water::before {
  content: "";
}

.fa-oil-well::before {
  content: "";
}

.fa-person-simple::before {
  content: "";
}

.fa-vault::before {
  content: "";
}

.fa-mars::before {
  content: "";
}

.fa-toilet::before {
  content: "";
}

.fa-plane-circle-xmark::before {
  content: "";
}

.fa-yen-sign::before {
  content: "";
}

.fa-cny::before {
  content: "";
}

.fa-jpy::before {
  content: "";
}

.fa-rmb::before {
  content: "";
}

.fa-yen::before {
  content: "";
}

.fa-gear-code::before {
  content: "";
}

.fa-notes::before {
  content: "";
}

.fa-ruble-sign::before {
  content: "";
}

.fa-rouble::before {
  content: "";
}

.fa-rub::before {
  content: "";
}

.fa-ruble::before {
  content: "";
}

.fa-trash-undo::before {
  content: "";
}

.fa-trash-arrow-turn-left::before {
  content: "";
}

.fa-champagne-glass::before {
  content: "";
}

.fa-glass-champagne::before {
  content: "";
}

.fa-objects-align-center-horizontal::before {
  content: "";
}

.fa-sun::before {
  content: "";
}

.fa-trash-can-slash::before {
  content: "";
}

.fa-trash-alt-slash::before {
  content: "";
}

.fa-screen-users::before {
  content: "";
}

.fa-users-class::before {
  content: "";
}

.fa-guitar::before {
  content: "";
}

.fa-square-arrow-left::before {
  content: "";
}

.fa-arrow-square-left::before {
  content: "";
}

.fa-square-8::before {
  content: "";
}

.fa-face-smile-hearts::before {
  content: "";
}

.fa-brackets-square::before {
  content: "";
}

.fa-brackets::before {
  content: "";
}

.fa-laptop-arrow-down::before {
  content: "";
}

.fa-hockey-stick-puck::before {
  content: "";
}

.fa-house-tree::before {
  content: "";
}

.fa-signal-fair::before {
  content: "";
}

.fa-signal-2::before {
  content: "";
}

.fa-face-laugh-wink::before {
  content: "";
}

.fa-laugh-wink::before {
  content: "";
}

.fa-circle-dollar::before {
  content: "";
}

.fa-dollar-circle::before {
  content: "";
}

.fa-usd-circle::before {
  content: "";
}

.fa-horse-head::before {
  content: "";
}

.fa-arrows-repeat::before {
  content: "";
}

.fa-repeat-alt::before {
  content: "";
}

.fa-bore-hole::before {
  content: "";
}

.fa-industry::before {
  content: "";
}

.fa-image-polaroid::before {
  content: "";
}

.fa-wave-triangle::before {
  content: "";
}

.fa-person-running-fast::before {
  content: "";
}

.fa-circle-down::before {
  content: "";
}

.fa-arrow-alt-circle-down::before {
  content: "";
}

.fa-grill::before {
  content: "";
}

.fa-arrows-turn-to-dots::before {
  content: "";
}

.fa-chart-mixed::before {
  content: "";
}

.fa-analytics::before {
  content: "";
}

.fa-florin-sign::before {
  content: "";
}

.fa-arrow-down-short-wide::before {
  content: "";
}

.fa-sort-amount-desc::before {
  content: "";
}

.fa-sort-amount-down-alt::before {
  content: "";
}

.fa-less-than::before {
  content: "\<";
}

.fa-display-code::before {
  content: "";
}

.fa-desktop-code::before {
  content: "";
}

.fa-face-drooling::before {
  content: "";
}

.fa-oil-temperature::before {
  content: "";
}

.fa-oil-temp::before {
  content: "";
}

.fa-square-question::before {
  content: "";
}

.fa-question-square::before {
  content: "";
}

.fa-air-conditioner::before {
  content: "";
}

.fa-angle-down::before {
  content: "";
}

.fa-mountains::before {
  content: "";
}

.fa-omega::before {
  content: "";
}

.fa-car-tunnel::before {
  content: "";
}

.fa-person-dolly-empty::before {
  content: "";
}

.fa-pan-food::before {
  content: "";
}

.fa-head-side-cough::before {
  content: "";
}

.fa-grip-lines::before {
  content: "";
}

.fa-thumbs-down::before {
  content: "";
}

.fa-user-lock::before {
  content: "";
}

.fa-arrow-right-long::before {
  content: "";
}

.fa-long-arrow-right::before {
  content: "";
}

.fa-tickets-airline::before {
  content: "";
}

.fa-anchor-circle-xmark::before {
  content: "";
}

.fa-ellipsis::before {
  content: "";
}

.fa-ellipsis-h::before {
  content: "";
}

.fa-nfc-slash::before {
  content: "";
}

.fa-chess-pawn::before {
  content: "";
}

.fa-kit-medical::before {
  content: "";
}

.fa-first-aid::before {
  content: "";
}

.fa-grid-2-plus::before {
  content: "";
}

.fa-bells::before {
  content: "";
}

.fa-person-through-window::before {
  content: "";
}

.fa-toolbox::before {
  content: "";
}

.fa-envelope-dot::before {
  content: "";
}

.fa-envelope-badge::before {
  content: "";
}

.fa-hands-holding-circle::before {
  content: "";
}

.fa-bug::before {
  content: "";
}

.fa-bowl-chopsticks::before {
  content: "";
}

.fa-credit-card::before {
  content: "";
}

.fa-credit-card-alt::before {
  content: "";
}

.fa-circle-s::before {
  content: "";
}

.fa-box-ballot::before {
  content: "";
}

.fa-car::before {
  content: "";
}

.fa-automobile::before {
  content: "";
}

.fa-hand-holding-hand::before {
  content: "";
}

.fa-user-tie-hair::before {
  content: "";
}

.fa-podium-star::before {
  content: "";
}

.fa-user-hair-mullet::before {
  content: "";
}

.fa-business-front::before {
  content: "";
}

.fa-party-back::before {
  content: "";
}

.fa-trian-balbot::before {
  content: "";
}

.fa-microphone-stand::before {
  content: "";
}

.fa-book-open-reader::before {
  content: "";
}

.fa-book-reader::before {
  content: "";
}

.fa-family-dress::before {
  content: "";
}

.fa-circle-x::before {
  content: "";
}

.fa-cabin::before {
  content: "";
}

.fa-mountain-sun::before {
  content: "";
}

.fa-chart-simple-horizontal::before {
  content: "";
}

.fa-arrows-left-right-to-line::before {
  content: "";
}

.fa-hand-back-point-left::before {
  content: "";
}

.fa-message-dots::before {
  content: "";
}

.fa-comment-alt-dots::before {
  content: "";
}

.fa-messaging::before {
  content: "";
}

.fa-file-heart::before {
  content: "";
}

.fa-beer-mug::before {
  content: "";
}

.fa-beer-foam::before {
  content: "";
}

.fa-dice-d20::before {
  content: "";
}

.fa-drone::before {
  content: "";
}

.fa-truck-droplet::before {
  content: "";
}

.fa-file-circle-xmark::before {
  content: "";
}

.fa-temperature-arrow-up::before {
  content: "";
}

.fa-temperature-up::before {
  content: "";
}

.fa-medal::before {
  content: "";
}

.fa-bed::before {
  content: "";
}

.fa-book-copy::before {
  content: "";
}

.fa-square-h::before {
  content: "";
}

.fa-h-square::before {
  content: "";
}

.fa-square-c::before {
  content: "";
}

.fa-clock-two::before {
  content: "";
}

.fa-square-ellipsis-vertical::before {
  content: "";
}

.fa-calendar-users::before {
  content: "";
}

.fa-podcast::before {
  content: "";
}

.fa-bee::before {
  content: "";
}

.fa-temperature-full::before {
  content: "";
}

.fa-temperature-4::before {
  content: "";
}

.fa-thermometer-4::before {
  content: "";
}

.fa-thermometer-full::before {
  content: "";
}

.fa-bell::before {
  content: "";
}

.fa-candy-bar::before {
  content: "";
}

.fa-chocolate-bar::before {
  content: "";
}

.fa-xmark-large::before {
  content: "";
}

.fa-pinata::before {
  content: "";
}

.fa-arrows-from-line::before {
  content: "";
}

.fa-superscript::before {
  content: "";
}

.fa-bowl-spoon::before {
  content: "";
}

.fa-hexagon-check::before {
  content: "";
}

.fa-plug-circle-xmark::before {
  content: "";
}

.fa-star-of-life::before {
  content: "";
}

.fa-phone-slash::before {
  content: "";
}

.fa-traffic-light-stop::before {
  content: "";
}

.fa-paint-roller::before {
  content: "";
}

.fa-accent-grave::before {
  content: "\`";
}

.fa-handshake-angle::before {
  content: "";
}

.fa-hands-helping::before {
  content: "";
}

.fa-circle-0::before {
  content: "";
}

.fa-dial-med-low::before {
  content: "";
}

.fa-location-dot::before {
  content: "";
}

.fa-map-marker-alt::before {
  content: "";
}

.fa-crab::before {
  content: "";
}

.fa-box-open-full::before {
  content: "";
}

.fa-box-full::before {
  content: "";
}

.fa-file::before {
  content: "";
}

.fa-greater-than::before {
  content: "\>";
}

.fa-quotes::before {
  content: "";
}

.fa-pretzel::before {
  content: "";
}

.fa-person-swimming::before {
  content: "";
}

.fa-swimmer::before {
  content: "";
}

.fa-arrow-down::before {
  content: "";
}

.fa-user-robot-xmarks::before {
  content: "";
}

.fa-message-quote::before {
  content: "";
}

.fa-comment-alt-quote::before {
  content: "";
}

.fa-candy-corn::before {
  content: "";
}

.fa-folder-magnifying-glass::before {
  content: "";
}

.fa-folder-search::before {
  content: "";
}

.fa-notebook::before {
  content: "";
}

.fa-droplet::before {
  content: "";
}

.fa-tint::before {
  content: "";
}

.fa-bullseye-pointer::before {
  content: "";
}

.fa-eraser::before {
  content: "";
}

.fa-hexagon-image::before {
  content: "";
}

.fa-earth-americas::before {
  content: "";
}

.fa-earth::before {
  content: "";
}

.fa-earth-america::before {
  content: "";
}

.fa-globe-americas::before {
  content: "";
}

.fa-crate-apple::before {
  content: "";
}

.fa-apple-crate::before {
  content: "";
}

.fa-person-burst::before {
  content: "";
}

.fa-game-board::before {
  content: "";
}

.fa-hat-chef::before {
  content: "";
}

.fa-hand-back-point-right::before {
  content: "";
}

.fa-dove::before {
  content: "";
}

.fa-snowflake-droplets::before {
  content: "";
}

.fa-battery-empty::before {
  content: "";
}

.fa-battery-0::before {
  content: "";
}

.fa-grid-4::before {
  content: "";
}

.fa-socks::before {
  content: "";
}

.fa-face-sunglasses::before {
  content: "";
}

.fa-inbox::before {
  content: "";
}

.fa-square-0::before {
  content: "";
}

.fa-section::before {
  content: "";
}

.fa-square-this-way-up::before {
  content: "";
}

.fa-box-up::before {
  content: "";
}

.fa-gauge-high::before {
  content: "";
}

.fa-tachometer-alt::before {
  content: "";
}

.fa-tachometer-alt-fast::before {
  content: "";
}

.fa-square-ampersand::before {
  content: "";
}

.fa-envelope-open-text::before {
  content: "";
}

.fa-lamp-desk::before {
  content: "";
}

.fa-hospital::before {
  content: "";
}

.fa-hospital-alt::before {
  content: "";
}

.fa-hospital-wide::before {
  content: "";
}

.fa-poll-people::before {
  content: "";
}

.fa-whiskey-glass-ice::before {
  content: "";
}

.fa-glass-whiskey-rocks::before {
  content: "";
}

.fa-wine-bottle::before {
  content: "";
}

.fa-chess-rook::before {
  content: "";
}

.fa-user-bounty-hunter::before {
  content: "";
}

.fa-bars-staggered::before {
  content: "";
}

.fa-reorder::before {
  content: "";
}

.fa-stream::before {
  content: "";
}

.fa-diagram-sankey::before {
  content: "";
}

.fa-cloud-hail-mixed::before {
  content: "";
}

.fa-circle-up-left::before {
  content: "";
}

.fa-dharmachakra::before {
  content: "";
}

.fa-objects-align-left::before {
  content: "";
}

.fa-oil-can-drip::before {
  content: "";
}

.fa-face-smiling-hands::before {
  content: "";
}

.fa-broccoli::before {
  content: "";
}

.fa-route-interstate::before {
  content: "";
}

.fa-ear-muffs::before {
  content: "";
}

.fa-hotdog::before {
  content: "";
}

.fa-transporter-empty::before {
  content: "";
}

.fa-person-walking-with-cane::before {
  content: "";
}

.fa-blind::before {
  content: "";
}

.fa-angle-90::before {
  content: "";
}

.fa-rectangle-terminal::before {
  content: "";
}

.fa-kite::before {
  content: "";
}

.fa-drum::before {
  content: "";
}

.fa-scrubber::before {
  content: "";
}

.fa-ice-cream::before {
  content: "";
}

.fa-heart-circle-bolt::before {
  content: "";
}

.fa-fish-bones::before {
  content: "";
}

.fa-deer-rudolph::before {
  content: "";
}

.fa-fax::before {
  content: "";
}

.fa-paragraph::before {
  content: "";
}

.fa-head-side-heart::before {
  content: "";
}

.fa-square-e::before {
  content: "";
}

.fa-meter-fire::before {
  content: "";
}

.fa-cloud-hail::before {
  content: "";
}

.fa-check-to-slot::before {
  content: "";
}

.fa-vote-yea::before {
  content: "";
}

.fa-money-from-bracket::before {
  content: "";
}

.fa-star-half::before {
  content: "";
}

.fa-car-bus::before {
  content: "";
}

.fa-speaker::before {
  content: "";
}

.fa-timer::before {
  content: "";
}

.fa-boxes-stacked::before {
  content: "";
}

.fa-boxes::before {
  content: "";
}

.fa-boxes-alt::before {
  content: "";
}

.fa-grill-hot::before {
  content: "";
}

.fa-ballot-check::before {
  content: "";
}

.fa-link::before {
  content: "";
}

.fa-chain::before {
  content: "";
}

.fa-ear-listen::before {
  content: "";
}

.fa-assistive-listening-systems::before {
  content: "";
}

.fa-file-minus::before {
  content: "";
}

.fa-tree-city::before {
  content: "";
}

.fa-play::before {
  content: "";
}

.fa-font::before {
  content: "";
}

.fa-cup-togo::before {
  content: "";
}

.fa-coffee-togo::before {
  content: "";
}

.fa-square-down-left::before {
  content: "";
}

.fa-burger-lettuce::before {
  content: "";
}

.fa-rupiah-sign::before {
  content: "";
}

.fa-magnifying-glass::before {
  content: "";
}

.fa-search::before {
  content: "";
}

.fa-table-tennis-paddle-ball::before {
  content: "";
}

.fa-ping-pong-paddle-ball::before {
  content: "";
}

.fa-table-tennis::before {
  content: "";
}

.fa-person-dots-from-line::before {
  content: "";
}

.fa-diagnoses::before {
  content: "";
}

.fa-chevrons-down::before {
  content: "";
}

.fa-chevron-double-down::before {
  content: "";
}

.fa-trash-can-arrow-up::before {
  content: "";
}

.fa-trash-restore-alt::before {
  content: "";
}

.fa-signal-good::before {
  content: "";
}

.fa-signal-3::before {
  content: "";
}

.fa-location-question::before {
  content: "";
}

.fa-map-marker-question::before {
  content: "";
}

.fa-floppy-disk-circle-xmark::before {
  content: "";
}

.fa-floppy-disk-times::before {
  content: "";
}

.fa-save-circle-xmark::before {
  content: "";
}

.fa-save-times::before {
  content: "";
}

.fa-naira-sign::before {
  content: "";
}

.fa-peach::before {
  content: "";
}

.fa-taxi-bus::before {
  content: "";
}

.fa-bracket-curly::before {
  content: "\{";
}

.fa-bracket-curly-left::before {
  content: "\{";
}

.fa-lobster::before {
  content: "";
}

.fa-cart-flatbed-empty::before {
  content: "";
}

.fa-dolly-flatbed-empty::before {
  content: "";
}

.fa-colon::before {
  content: "\:";
}

.fa-cart-arrow-down::before {
  content: "";
}

.fa-wand::before {
  content: "";
}

.fa-walkie-talkie::before {
  content: "";
}

.fa-file-pen::before {
  content: "";
}

.fa-file-edit::before {
  content: "";
}

.fa-receipt::before {
  content: "";
}

.fa-table-picnic::before {
  content: "";
}

.fa-square-pen::before {
  content: "";
}

.fa-pen-square::before {
  content: "";
}

.fa-pencil-square::before {
  content: "";
}

.fa-circle-microphone-lines::before {
  content: "";
}

.fa-microphone-circle-alt::before {
  content: "";
}

.fa-display-slash::before {
  content: "";
}

.fa-desktop-slash::before {
  content: "";
}

.fa-suitcase-rolling::before {
  content: "";
}

.fa-person-circle-exclamation::before {
  content: "";
}

.fa-transporter-2::before {
  content: "";
}

.fa-hands-holding-diamond::before {
  content: "";
}

.fa-hand-receiving::before {
  content: "";
}

.fa-money-bill-simple-wave::before {
  content: "";
}

.fa-chevron-down::before {
  content: "";
}

.fa-battery-full::before {
  content: "";
}

.fa-battery::before {
  content: "";
}

.fa-battery-5::before {
  content: "";
}

.fa-bell-plus::before {
  content: "";
}

.fa-book-arrow-right::before {
  content: "";
}

.fa-hospitals::before {
  content: "";
}

.fa-club::before {
  content: "";
}

.fa-skull-crossbones::before {
  content: "";
}

.fa-droplet-degree::before {
  content: "";
}

.fa-dewpoint::before {
  content: "";
}

.fa-code-compare::before {
  content: "";
}

.fa-list-ul::before {
  content: "";
}

.fa-list-dots::before {
  content: "";
}

.fa-hand-holding-magic::before {
  content: "";
}

.fa-watermelon-slice::before {
  content: "";
}

.fa-circle-ellipsis::before {
  content: "";
}

.fa-school-lock::before {
  content: "";
}

.fa-tower-cell::before {
  content: "";
}

.fa-sd-cards::before {
  content: "";
}

.fa-jug-bottle::before {
  content: "";
}

.fa-down-long::before {
  content: "";
}

.fa-long-arrow-alt-down::before {
  content: "";
}

.fa-envelopes::before {
  content: "";
}

.fa-phone-office::before {
  content: "";
}

.fa-ranking-star::before {
  content: "";
}

.fa-chess-king::before {
  content: "";
}

.fa-nfc-pen::before {
  content: "";
}

.fa-person-harassing::before {
  content: "";
}

.fa-hat-winter::before {
  content: "";
}

.fa-brazilian-real-sign::before {
  content: "";
}

.fa-landmark-dome::before {
  content: "";
}

.fa-landmark-alt::before {
  content: "";
}

.fa-bone-break::before {
  content: "";
}

.fa-arrow-up::before {
  content: "";
}

.fa-down-from-dotted-line::before {
  content: "";
}

.fa-tv::before {
  content: "";
}

.fa-television::before {
  content: "";
}

.fa-tv-alt::before {
  content: "";
}

.fa-border-left::before {
  content: "";
}

.fa-circle-divide::before {
  content: "";
}

.fa-shrimp::before {
  content: "";
}

.fa-list-check::before {
  content: "";
}

.fa-tasks::before {
  content: "";
}

.fa-diagram-subtask::before {
  content: "";
}

.fa-jug-detergent::before {
  content: "";
}

.fa-circle-user::before {
  content: "";
}

.fa-user-circle::before {
  content: "";
}

.fa-square-y::before {
  content: "";
}

.fa-user-doctor-hair::before {
  content: "";
}

.fa-planet-ringed::before {
  content: "";
}

.fa-mushroom::before {
  content: "";
}

.fa-user-shield::before {
  content: "";
}

.fa-megaphone::before {
  content: "";
}

.fa-wreath-laurel::before {
  content: "";
}

.fa-circle-exclamation-check::before {
  content: "";
}

.fa-wind::before {
  content: "";
}

.fa-box-dollar::before {
  content: "";
}

.fa-box-usd::before {
  content: "";
}

.fa-car-burst::before {
  content: "";
}

.fa-car-crash::before {
  content: "";
}

.fa-y::before {
  content: "Y";
}

.fa-user-headset::before {
  content: "";
}

.fa-arrows-retweet::before {
  content: "";
}

.fa-retweet-alt::before {
  content: "";
}

.fa-person-snowboarding::before {
  content: "";
}

.fa-snowboarding::before {
  content: "";
}

.fa-square-chevron-right::before {
  content: "";
}

.fa-chevron-square-right::before {
  content: "";
}

.fa-lacrosse-stick-ball::before {
  content: "";
}

.fa-truck-fast::before {
  content: "";
}

.fa-shipping-fast::before {
  content: "";
}

.fa-user-magnifying-glass::before {
  content: "";
}

.fa-star-sharp::before {
  content: "";
}

.fa-comment-heart::before {
  content: "";
}

.fa-circle-1::before {
  content: "";
}

.fa-circle-star::before {
  content: "";
}

.fa-star-circle::before {
  content: "";
}

.fa-fish::before {
  content: "";
}

.fa-cloud-fog::before {
  content: "";
}

.fa-fog::before {
  content: "";
}

.fa-waffle::before {
  content: "";
}

.fa-music-note::before {
  content: "";
}

.fa-music-alt::before {
  content: "";
}

.fa-hexagon-exclamation::before {
  content: "";
}

.fa-cart-shopping-fast::before {
  content: "";
}

.fa-object-union::before {
  content: "";
}

.fa-user-graduate::before {
  content: "";
}

.fa-starfighter::before {
  content: "";
}

.fa-circle-half-stroke::before {
  content: "";
}

.fa-adjust::before {
  content: "";
}

.fa-arrow-right-long-to-line::before {
  content: "";
}

.fa-square-arrow-down::before {
  content: "";
}

.fa-arrow-square-down::before {
  content: "";
}

.fa-diamond-half-stroke::before {
  content: "";
}

.fa-clapperboard::before {
  content: "";
}

.fa-square-chevron-left::before {
  content: "";
}

.fa-chevron-square-left::before {
  content: "";
}

.fa-phone-intercom::before {
  content: "";
}

.fa-link-horizontal::before {
  content: "";
}

.fa-chain-horizontal::before {
  content: "";
}

.fa-mango::before {
  content: "";
}

.fa-music-note-slash::before {
  content: "";
}

.fa-music-alt-slash::before {
  content: "";
}

.fa-circle-radiation::before {
  content: "";
}

.fa-radiation-alt::before {
  content: "";
}

.fa-face-tongue-sweat::before {
  content: "";
}

.fa-globe-stand::before {
  content: "";
}

.fa-baseball::before {
  content: "";
}

.fa-baseball-ball::before {
  content: "";
}

.fa-circle-p::before {
  content: "";
}

.fa-award-simple::before {
  content: "";
}

.fa-jet-fighter-up::before {
  content: "";
}

.fa-diagram-project::before {
  content: "";
}

.fa-project-diagram::before {
  content: "";
}

.fa-pedestal::before {
  content: "";
}

.fa-chart-pyramid::before {
  content: "";
}

.fa-sidebar::before {
  content: "";
}

.fa-snowman-head::before {
  content: "";
}

.fa-frosty-head::before {
  content: "";
}

.fa-copy::before {
  content: "";
}

.fa-burger-glass::before {
  content: "";
}

.fa-volume-xmark::before {
  content: "";
}

.fa-volume-mute::before {
  content: "";
}

.fa-volume-times::before {
  content: "";
}

.fa-hand-sparkles::before {
  content: "";
}

.fa-bars-filter::before {
  content: "";
}

.fa-paintbrush-pencil::before {
  content: "";
}

.fa-party-bell::before {
  content: "";
}

.fa-user-vneck-hair::before {
  content: "";
}

.fa-jack-o-lantern::before {
  content: "";
}

.fa-grip::before {
  content: "";
}

.fa-grip-horizontal::before {
  content: "";
}

.fa-share-from-square::before {
  content: "";
}

.fa-share-square::before {
  content: "";
}

.fa-keynote::before {
  content: "";
}

.fa-child-combatant::before {
  content: "";
}

.fa-child-rifle::before {
  content: "";
}

.fa-gun::before {
  content: "";
}

.fa-square-phone::before {
  content: "";
}

.fa-phone-square::before {
  content: "";
}

.fa-hat-beach::before {
  content: "";
}

.fa-plus::before {
  content: "\+";
}

.fa-add::before {
  content: "\+";
}

.fa-expand::before {
  content: "";
}

.fa-computer::before {
  content: "";
}

.fa-fort::before {
  content: "";
}

.fa-cloud-check::before {
  content: "";
}

.fa-xmark::before {
  content: "";
}

.fa-close::before {
  content: "";
}

.fa-multiply::before {
  content: "";
}

.fa-remove::before {
  content: "";
}

.fa-times::before {
  content: "";
}

.fa-face-smirking::before {
  content: "";
}

.fa-arrows-up-down-left-right::before {
  content: "";
}

.fa-arrows::before {
  content: "";
}

.fa-chalkboard-user::before {
  content: "";
}

.fa-chalkboard-teacher::before {
  content: "";
}

.fa-rhombus::before {
  content: "";
}

.fa-claw-marks::before {
  content: "";
}

.fa-peso-sign::before {
  content: "";
}

.fa-face-smile-tongue::before {
  content: "";
}

.fa-cart-circle-xmark::before {
  content: "";
}

.fa-building-shield::before {
  content: "";
}

.fa-circle-phone-flip::before {
  content: "";
}

.fa-phone-circle-alt::before {
  content: "";
}

.fa-baby::before {
  content: "";
}

.fa-users-line::before {
  content: "";
}

.fa-quote-left::before {
  content: "";
}

.fa-quote-left-alt::before {
  content: "";
}

.fa-tractor::before {
  content: "";
}

.fa-key-skeleton::before {
  content: "";
}

.fa-trash-arrow-up::before {
  content: "";
}

.fa-trash-restore::before {
  content: "";
}

.fa-arrow-down-up-lock::before {
  content: "";
}

.fa-arrow-down-to-bracket::before {
  content: "";
}

.fa-lines-leaning::before {
  content: "";
}

.fa-square-q::before {
  content: "";
}

.fa-ruler-combined::before {
  content: "";
}

.fa-symbols::before {
  content: "";
}

.fa-icons-alt::before {
  content: "";
}

.fa-copyright::before {
  content: "";
}

.fa-flask-gear::before {
  content: "";
}

.fa-highlighter-line::before {
  content: "";
}

.fa-bracket-square::before {
  content: "\[";
}

.fa-bracket::before {
  content: "\[";
}

.fa-bracket-left::before {
  content: "\[";
}

.fa-island-tropical::before {
  content: "";
}

.fa-island-tree-palm::before {
  content: "";
}

.fa-arrow-right-from-line::before {
  content: "";
}

.fa-arrow-from-left::before {
  content: "";
}

.fa-h2::before {
  content: "";
}

.fa-equals::before {
  content: "\=";
}

.fa-cake-slice::before {
  content: "";
}

.fa-shortcake::before {
  content: "";
}

.fa-peanut::before {
  content: "";
}

.fa-wrench-simple::before {
  content: "";
}

.fa-blender::before {
  content: "";
}

.fa-teeth::before {
  content: "";
}

.fa-tally-2::before {
  content: "";
}

.fa-shekel-sign::before {
  content: "";
}

.fa-ils::before {
  content: "";
}

.fa-shekel::before {
  content: "";
}

.fa-sheqel::before {
  content: "";
}

.fa-sheqel-sign::before {
  content: "";
}

.fa-cars::before {
  content: "";
}

.fa-axe-battle::before {
  content: "";
}

.fa-user-hair-long::before {
  content: "";
}

.fa-map::before {
  content: "";
}

.fa-file-circle-info::before {
  content: "";
}

.fa-face-disappointed::before {
  content: "";
}

.fa-lasso-sparkles::before {
  content: "";
}

.fa-clock-eleven::before {
  content: "";
}

.fa-rocket::before {
  content: "";
}

.fa-siren-on::before {
  content: "";
}

.fa-clock-ten::before {
  content: "";
}

.fa-candle-holder::before {
  content: "";
}

.fa-video-arrow-down-left::before {
  content: "";
}

.fa-photo-film::before {
  content: "";
}

.fa-photo-video::before {
  content: "";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "";
}

.fa-save-circle-arrow-right::before {
  content: "";
}

.fa-folder-minus::before {
  content: "";
}

.fa-planet-moon::before {
  content: "";
}

.fa-face-eyes-xmarks::before {
  content: "";
}

.fa-chart-scatter::before {
  content: "";
}

.fa-display-arrow-down::before {
  content: "";
}

.fa-store::before {
  content: "";
}

.fa-arrow-trend-up::before {
  content: "";
}

.fa-plug-circle-minus::before {
  content: "";
}

.fa-olive-branch::before {
  content: "";
}

.fa-angle::before {
  content: "";
}

.fa-vacuum-robot::before {
  content: "";
}

.fa-sign-hanging::before {
  content: "";
}

.fa-sign::before {
  content: "";
}

.fa-square-divide::before {
  content: "";
}

.fa-signal-stream-slash::before {
  content: "";
}

.fa-bezier-curve::before {
  content: "";
}

.fa-eye-dropper-half::before {
  content: "";
}

.fa-store-lock::before {
  content: "";
}

.fa-bell-slash::before {
  content: "";
}

.fa-cloud-bolt-sun::before {
  content: "";
}

.fa-thunderstorm-sun::before {
  content: "";
}

.fa-camera-slash::before {
  content: "";
}

.fa-comment-quote::before {
  content: "";
}

.fa-tablet::before {
  content: "";
}

.fa-tablet-android::before {
  content: "";
}

.fa-school-flag::before {
  content: "";
}

.fa-message-code::before {
  content: "";
}

.fa-glass-half::before {
  content: "";
}

.fa-glass-half-empty::before {
  content: "";
}

.fa-glass-half-full::before {
  content: "";
}

.fa-fill::before {
  content: "";
}

.fa-message-minus::before {
  content: "";
}

.fa-comment-alt-minus::before {
  content: "";
}

.fa-angle-up::before {
  content: "";
}

.fa-dinosaur::before {
  content: "";
}

.fa-drumstick-bite::before {
  content: "";
}

.fa-link-horizontal-slash::before {
  content: "";
}

.fa-chain-horizontal-slash::before {
  content: "";
}

.fa-holly-berry::before {
  content: "";
}

.fa-nose::before {
  content: "";
}

.fa-chevron-left::before {
  content: "";
}

.fa-bacteria::before {
  content: "";
}

.fa-clouds::before {
  content: "";
}

.fa-money-bill-simple::before {
  content: "";
}

.fa-hand-lizard::before {
  content: "";
}

.fa-table-pivot::before {
  content: "";
}

.fa-filter-slash::before {
  content: "";
}

.fa-trash-can-undo::before {
  content: "";
}

.fa-trash-can-arrow-turn-left::before {
  content: "";
}

.fa-trash-undo-alt::before {
  content: "";
}

.fa-notdef::before {
  content: "";
}

.fa-disease::before {
  content: "";
}

.fa-person-to-door::before {
  content: "";
}

.fa-turntable::before {
  content: "";
}

.fa-briefcase-medical::before {
  content: "";
}

.fa-genderless::before {
  content: "";
}

.fa-chevron-right::before {
  content: "";
}

.fa-signal-weak::before {
  content: "";
}

.fa-signal-1::before {
  content: "";
}

.fa-clock-five::before {
  content: "";
}

.fa-retweet::before {
  content: "";
}

.fa-car-rear::before {
  content: "";
}

.fa-car-alt::before {
  content: "";
}

.fa-pump-soap::before {
  content: "";
}

.fa-computer-classic::before {
  content: "";
}

.fa-frame::before {
  content: "";
}

.fa-video-slash::before {
  content: "";
}

.fa-battery-quarter::before {
  content: "";
}

.fa-battery-2::before {
  content: "";
}

.fa-ellipsis-stroke::before {
  content: "";
}

.fa-ellipsis-h-alt::before {
  content: "";
}

.fa-radio::before {
  content: "";
}

.fa-baby-carriage::before {
  content: "";
}

.fa-carriage-baby::before {
  content: "";
}

.fa-face-expressionless::before {
  content: "";
}

.fa-down-to-dotted-line::before {
  content: "";
}

.fa-cloud-music::before {
  content: "";
}

.fa-traffic-light::before {
  content: "";
}

.fa-cloud-minus::before {
  content: "";
}

.fa-thermometer::before {
  content: "";
}

.fa-shield-minus::before {
  content: "";
}

.fa-vr-cardboard::before {
  content: "";
}

.fa-car-tilt::before {
  content: "";
}

.fa-gauge-circle-minus::before {
  content: "";
}

.fa-brightness-low::before {
  content: "";
}

.fa-hand-middle-finger::before {
  content: "";
}

.fa-percent::before {
  content: "\%";
}

.fa-percentage::before {
  content: "\%";
}

.fa-truck-moving::before {
  content: "";
}

.fa-glass-water-droplet::before {
  content: "";
}

.fa-conveyor-belt::before {
  content: "";
}

.fa-location-check::before {
  content: "";
}

.fa-map-marker-check::before {
  content: "";
}

.fa-coin-vertical::before {
  content: "";
}

.fa-display::before {
  content: "";
}

.fa-person-sign::before {
  content: "";
}

.fa-face-smile::before {
  content: "";
}

.fa-smile::before {
  content: "";
}

.fa-phone-hangup::before {
  content: "";
}

.fa-signature-slash::before {
  content: "";
}

.fa-thumbtack::before {
  content: "";
}

.fa-thumb-tack::before {
  content: "";
}

.fa-wheat-slash::before {
  content: "";
}

.fa-trophy::before {
  content: "";
}

.fa-clouds-sun::before {
  content: "";
}

.fa-person-praying::before {
  content: "";
}

.fa-pray::before {
  content: "";
}

.fa-hammer::before {
  content: "";
}

.fa-face-vomit::before {
  content: "";
}

.fa-speakers::before {
  content: "";
}

.fa-tty-answer::before {
  content: "";
}

.fa-teletype-answer::before {
  content: "";
}

.fa-mug-tea-saucer::before {
  content: "";
}

.fa-diagram-lean-canvas::before {
  content: "";
}

.fa-alt::before {
  content: "";
}

.fa-dial::before {
  content: "";
}

.fa-dial-med-high::before {
  content: "";
}

.fa-hand-peace::before {
  content: "";
}

.fa-circle-trash::before {
  content: "";
}

.fa-trash-circle::before {
  content: "";
}

.fa-rotate::before {
  content: "";
}

.fa-sync-alt::before {
  content: "";
}

.fa-circle-quarters::before {
  content: "";
}

.fa-spinner::before {
  content: "";
}

.fa-tower-control::before {
  content: "";
}

.fa-arrow-up-triangle-square::before {
  content: "";
}

.fa-sort-shapes-up::before {
  content: "";
}

.fa-whale::before {
  content: "";
}

.fa-robot::before {
  content: "";
}

.fa-peace::before {
  content: "";
}

.fa-party-horn::before {
  content: "";
}

.fa-gears::before {
  content: "";
}

.fa-cogs::before {
  content: "";
}

.fa-sun-bright::before {
  content: "";
}

.fa-sun-alt::before {
  content: "";
}

.fa-warehouse::before {
  content: "";
}

.fa-conveyor-belt-arm::before {
  content: "";
}

.fa-lock-keyhole-open::before {
  content: "";
}

.fa-lock-open-alt::before {
  content: "";
}

.fa-square-fragile::before {
  content: "";
}

.fa-box-fragile::before {
  content: "";
}

.fa-square-wine-glass-crack::before {
  content: "";
}

.fa-arrow-up-right-dots::before {
  content: "";
}

.fa-square-n::before {
  content: "";
}

.fa-splotch::before {
  content: "";
}

.fa-face-grin-hearts::before {
  content: "";
}

.fa-grin-hearts::before {
  content: "";
}

.fa-meter::before {
  content: "";
}

.fa-mandolin::before {
  content: "";
}

.fa-dice-four::before {
  content: "";
}

.fa-sim-card::before {
  content: "";
}

.fa-transgender::before {
  content: "";
}

.fa-transgender-alt::before {
  content: "";
}

.fa-mercury::before {
  content: "";
}

.fa-up-from-bracket::before {
  content: "";
}

.fa-knife-kitchen::before {
  content: "";
}

.fa-border-right::before {
  content: "";
}

.fa-arrow-turn-down::before {
  content: "";
}

.fa-level-down::before {
  content: "";
}

.fa-spade::before {
  content: "";
}

.fa-card-spade::before {
  content: "";
}

.fa-line-columns::before {
  content: "";
}

.fa-arrow-right-to-line::before {
  content: "";
}

.fa-arrow-to-right::before {
  content: "";
}

.fa-person-falling-burst::before {
  content: "";
}

.fa-flag-pennant::before {
  content: "";
}

.fa-pennant::before {
  content: "";
}

.fa-conveyor-belt-empty::before {
  content: "";
}

.fa-user-group-simple::before {
  content: "";
}

.fa-award::before {
  content: "";
}

.fa-ticket-simple::before {
  content: "";
}

.fa-ticket-alt::before {
  content: "";
}

.fa-building::before {
  content: "";
}

.fa-angles-left::before {
  content: "";
}

.fa-angle-double-left::before {
  content: "";
}

.fa-camcorder::before {
  content: "";
}

.fa-video-handheld::before {
  content: "";
}

.fa-pancakes::before {
  content: "";
}

.fa-album-circle-user::before {
  content: "";
}

.fa-qrcode::before {
  content: "";
}

.fa-dice-d10::before {
  content: "";
}

.fa-fireplace::before {
  content: "";
}

.fa-browser::before {
  content: "";
}

.fa-pen-paintbrush::before {
  content: "";
}

.fa-pencil-paintbrush::before {
  content: "";
}

.fa-fish-cooked::before {
  content: "";
}

.fa-chair-office::before {
  content: "";
}

.fa-nesting-dolls::before {
  content: "";
}

.fa-clock-rotate-left::before {
  content: "";
}

.fa-history::before {
  content: "";
}

.fa-trumpet::before {
  content: "";
}

.fa-face-grin-beam-sweat::before {
  content: "";
}

.fa-grin-beam-sweat::before {
  content: "";
}

.fa-fire-smoke::before {
  content: "";
}

.fa-phone-missed::before {
  content: "";
}

.fa-file-export::before {
  content: "";
}

.fa-arrow-right-from-file::before {
  content: "";
}

.fa-shield::before {
  content: "";
}

.fa-shield-blank::before {
  content: "";
}

.fa-arrow-up-short-wide::before {
  content: "";
}

.fa-sort-amount-up-alt::before {
  content: "";
}

.fa-arrows-repeat-1::before {
  content: "";
}

.fa-repeat-1-alt::before {
  content: "";
}

.fa-gun-slash::before {
  content: "";
}

.fa-avocado::before {
  content: "";
}

.fa-binary::before {
  content: "";
}

.fa-glasses-round::before {
  content: "";
}

.fa-glasses-alt::before {
  content: "";
}

.fa-phone-plus::before {
  content: "";
}

.fa-ditto::before {
  content: "\"";
}

.fa-person-seat::before {
  content: "";
}

.fa-house-medical::before {
  content: "";
}

.fa-golf-ball-tee::before {
  content: "";
}

.fa-golf-ball::before {
  content: "";
}

.fa-circle-chevron-left::before {
  content: "";
}

.fa-chevron-circle-left::before {
  content: "";
}

.fa-house-chimney-window::before {
  content: "";
}

.fa-scythe::before {
  content: "";
}

.fa-pen-nib::before {
  content: "";
}

.fa-ban-parking::before {
  content: "";
}

.fa-parking-circle-slash::before {
  content: "";
}

.fa-tent-arrow-turn-left::before {
  content: "";
}

.fa-face-diagonal-mouth::before {
  content: "";
}

.fa-diagram-cells::before {
  content: "";
}

.fa-cricket-bat-ball::before {
  content: "";
}

.fa-cricket::before {
  content: "";
}

.fa-tents::before {
  content: "";
}

.fa-wand-magic::before {
  content: "";
}

.fa-magic::before {
  content: "";
}

.fa-dog::before {
  content: "";
}

.fa-pen-line::before {
  content: "";
}

.fa-atom-simple::before {
  content: "";
}

.fa-atom-alt::before {
  content: "";
}

.fa-ampersand::before {
  content: "\&";
}

.fa-carrot::before {
  content: "";
}

.fa-arrow-up-from-line::before {
  content: "";
}

.fa-arrow-from-bottom::before {
  content: "";
}

.fa-moon::before {
  content: "";
}

.fa-pen-slash::before {
  content: "";
}

.fa-wine-glass-empty::before {
  content: "";
}

.fa-wine-glass-alt::before {
  content: "";
}

.fa-square-star::before {
  content: "";
}

.fa-cheese::before {
  content: "";
}

.fa-send-backward::before {
  content: "";
}

.fa-yin-yang::before {
  content: "";
}

.fa-music::before {
  content: "";
}

.fa-compass-slash::before {
  content: "";
}

.fa-clock-one::before {
  content: "";
}

.fa-file-music::before {
  content: "";
}

.fa-code-commit::before {
  content: "";
}

.fa-temperature-low::before {
  content: "";
}

.fa-person-biking::before {
  content: "";
}

.fa-biking::before {
  content: "";
}

.fa-display-chart-up-circle-currency::before {
  content: "";
}

.fa-skeleton::before {
  content: "";
}

.fa-circle-g::before {
  content: "";
}

.fa-circle-arrow-up-left::before {
  content: "";
}

.fa-coin-blank::before {
  content: "";
}

.fa-broom::before {
  content: "";
}

.fa-vacuum::before {
  content: "";
}

.fa-shield-heart::before {
  content: "";
}

.fa-card-heart::before {
  content: "";
}

.fa-lightbulb-cfl-on::before {
  content: "";
}

.fa-melon::before {
  content: "";
}

.fa-gopuram::before {
  content: "";
}

.fa-earth-oceania::before {
  content: "";
}

.fa-globe-oceania::before {
  content: "";
}

.fa-container-storage::before {
  content: "";
}

.fa-face-pouting::before {
  content: "";
}

.fa-square-xmark::before {
  content: "";
}

.fa-times-square::before {
  content: "";
}

.fa-xmark-square::before {
  content: "";
}

.fa-face-explode::before {
  content: "";
}

.fa-exploding-head::before {
  content: "";
}

.fa-hashtag::before {
  content: "\#";
}

.fa-up-right-and-down-left-from-center::before {
  content: "";
}

.fa-expand-alt::before {
  content: "";
}

.fa-oil-can::before {
  content: "";
}

.fa-t::before {
  content: "T";
}

.fa-transformer-bolt::before {
  content: "";
}

.fa-hippo::before {
  content: "";
}

.fa-chart-column::before {
  content: "";
}

.fa-cassette-vhs::before {
  content: "";
}

.fa-vhs::before {
  content: "";
}

.fa-infinity::before {
  content: "";
}

.fa-vial-circle-check::before {
  content: "";
}

.fa-chimney::before {
  content: "";
}

.fa-object-intersect::before {
  content: "";
}

.fa-person-arrow-down-to-line::before {
  content: "";
}

.fa-voicemail::before {
  content: "";
}

.fa-block-brick::before {
  content: "";
}

.fa-wall-brick::before {
  content: "";
}

.fa-fan::before {
  content: "";
}

.fa-bags-shopping::before {
  content: "";
}

.fa-paragraph-left::before {
  content: "";
}

.fa-paragraph-rtl::before {
  content: "";
}

.fa-person-walking-luggage::before {
  content: "";
}

.fa-caravan-simple::before {
  content: "";
}

.fa-caravan-alt::before {
  content: "";
}

.fa-turtle::before {
  content: "";
}

.fa-pencil-mechanical::before {
  content: "";
}

.fa-up-down::before {
  content: "";
}

.fa-arrows-alt-v::before {
  content: "";
}

.fa-cloud-moon-rain::before {
  content: "";
}

.fa-booth-curtain::before {
  content: "";
}

.fa-calendar::before {
  content: "";
}

.fa-box-heart::before {
  content: "";
}

.fa-trailer::before {
  content: "";
}

.fa-user-doctor-message::before {
  content: "";
}

.fa-user-md-chat::before {
  content: "";
}

.fa-bahai::before {
  content: "";
}

.fa-haykal::before {
  content: "";
}

.fa-amp-guitar::before {
  content: "";
}

.fa-sd-card::before {
  content: "";
}

.fa-volume-slash::before {
  content: "";
}

.fa-border-bottom::before {
  content: "";
}

.fa-wifi-weak::before {
  content: "";
}

.fa-wifi-1::before {
  content: "";
}

.fa-dragon::before {
  content: "";
}

.fa-shoe-prints::before {
  content: "";
}

.fa-circle-plus::before {
  content: "";
}

.fa-plus-circle::before {
  content: "";
}

.fa-face-grin-tongue-wink::before {
  content: "";
}

.fa-grin-tongue-wink::before {
  content: "";
}

.fa-hand-holding::before {
  content: "";
}

.fa-plug-circle-exclamation::before {
  content: "";
}

.fa-link-slash::before {
  content: "";
}

.fa-chain-broken::before {
  content: "";
}

.fa-chain-slash::before {
  content: "";
}

.fa-unlink::before {
  content: "";
}

.fa-clone::before {
  content: "";
}

.fa-person-walking-arrow-loop-left::before {
  content: "";
}

.fa-arrow-up-z-a::before {
  content: "";
}

.fa-sort-alpha-up-alt::before {
  content: "";
}

.fa-fire-flame-curved::before {
  content: "";
}

.fa-fire-alt::before {
  content: "";
}

.fa-tornado::before {
  content: "";
}

.fa-file-circle-plus::before {
  content: "";
}

.fa-delete-right::before {
  content: "";
}

.fa-book-quran::before {
  content: "";
}

.fa-quran::before {
  content: "";
}

.fa-circle-quarter::before {
  content: "";
}

.fa-anchor::before {
  content: "";
}

.fa-border-all::before {
  content: "";
}

.fa-function::before {
  content: "";
}

.fa-face-angry::before {
  content: "";
}

.fa-angry::before {
  content: "";
}

.fa-people-simple::before {
  content: "";
}

.fa-cookie-bite::before {
  content: "";
}

.fa-arrow-trend-down::before {
  content: "";
}

.fa-rss::before {
  content: "";
}

.fa-feed::before {
  content: "";
}

.fa-face-monocle::before {
  content: "";
}

.fa-draw-polygon::before {
  content: "";
}

.fa-scale-balanced::before {
  content: "";
}

.fa-balance-scale::before {
  content: "";
}

.fa-calendar-lines::before {
  content: "";
}

.fa-calendar-note::before {
  content: "";
}

.fa-arrow-down-big-small::before {
  content: "";
}

.fa-sort-size-down::before {
  content: "";
}

.fa-gauge-simple-high::before {
  content: "";
}

.fa-tachometer::before {
  content: "";
}

.fa-tachometer-fast::before {
  content: "";
}

.fa-do-not-enter::before {
  content: "";
}

.fa-shower::before {
  content: "";
}

.fa-dice-d8::before {
  content: "";
}

.fa-desktop::before {
  content: "";
}

.fa-desktop-alt::before {
  content: "";
}

.fa-m::before {
  content: "M";
}

.fa-grip-dots-vertical::before {
  content: "";
}

.fa-face-viewfinder::before {
  content: "";
}

.fa-soft-serve::before {
  content: "";
}

.fa-creemee::before {
  content: "";
}

.fa-h5::before {
  content: "";
}

.fa-hand-back-point-down::before {
  content: "";
}

.fa-table-list::before {
  content: "";
}

.fa-th-list::before {
  content: "";
}

.fa-comment-sms::before {
  content: "";
}

.fa-sms::before {
  content: "";
}

.fa-rectangle::before {
  content: "";
}

.fa-rectangle-landscape::before {
  content: "";
}

.fa-clipboard-list-check::before {
  content: "";
}

.fa-turkey::before {
  content: "";
}

.fa-book::before {
  content: "";
}

.fa-user-plus::before {
  content: "";
}

.fa-ice-skate::before {
  content: "";
}

.fa-check::before {
  content: "";
}

.fa-battery-three-quarters::before {
  content: "";
}

.fa-battery-4::before {
  content: "";
}

.fa-tomato::before {
  content: "";
}

.fa-sword-laser::before {
  content: "";
}

.fa-house-circle-check::before {
  content: "";
}

.fa-buildings::before {
  content: "";
}

.fa-angle-left::before {
  content: "";
}

.fa-cart-flatbed-boxes::before {
  content: "";
}

.fa-dolly-flatbed-alt::before {
  content: "";
}

.fa-diagram-successor::before {
  content: "";
}

.fa-truck-arrow-right::before {
  content: "";
}

.fa-square-w::before {
  content: "";
}

.fa-arrows-split-up-and-left::before {
  content: "";
}

.fa-lamp::before {
  content: "";
}

.fa-airplay::before {
  content: "";
}

.fa-hand-fist::before {
  content: "";
}

.fa-fist-raised::before {
  content: "";
}

.fa-shield-quartered::before {
  content: "";
}

.fa-slash-forward::before {
  content: "\/";
}

.fa-location-pen::before {
  content: "";
}

.fa-map-marker-edit::before {
  content: "";
}

.fa-cloud-moon::before {
  content: "";
}

.fa-pot-food::before {
  content: "";
}

.fa-briefcase::before {
  content: "";
}

.fa-person-falling::before {
  content: "";
}

.fa-image-portrait::before {
  content: "";
}

.fa-portrait::before {
  content: "";
}

.fa-user-tag::before {
  content: "";
}

.fa-rug::before {
  content: "";
}

.fa-print-slash::before {
  content: "";
}

.fa-earth-europe::before {
  content: "";
}

.fa-globe-europe::before {
  content: "";
}

.fa-cart-flatbed-suitcase::before {
  content: "";
}

.fa-luggage-cart::before {
  content: "";
}

.fa-hand-back-point-ribbon::before {
  content: "";
}

.fa-rectangle-xmark::before {
  content: "";
}

.fa-rectangle-times::before {
  content: "";
}

.fa-times-rectangle::before {
  content: "";
}

.fa-window-close::before {
  content: "";
}

.fa-tire-rugged::before {
  content: "";
}

.fa-lightbulb-dollar::before {
  content: "";
}

.fa-cowbell::before {
  content: "";
}

.fa-baht-sign::before {
  content: "";
}

.fa-corner::before {
  content: "";
}

.fa-chevrons-right::before {
  content: "";
}

.fa-chevron-double-right::before {
  content: "";
}

.fa-book-open::before {
  content: "";
}

.fa-book-journal-whills::before {
  content: "";
}

.fa-journal-whills::before {
  content: "";
}

.fa-inhaler::before {
  content: "";
}

.fa-handcuffs::before {
  content: "";
}

.fa-snake::before {
  content: "";
}

.fa-triangle-exclamation::before {
  content: "";
}

.fa-exclamation-triangle::before {
  content: "";
}

.fa-warning::before {
  content: "";
}

.fa-note-medical::before {
  content: "";
}

.fa-database::before {
  content: "";
}

.fa-down-left::before {
  content: "";
}

.fa-share::before {
  content: "";
}

.fa-arrow-turn-right::before {
  content: "";
}

.fa-mail-forward::before {
  content: "";
}

.fa-face-thinking::before {
  content: "";
}

.fa-turn-down-right::before {
  content: "";
}

.fa-bottle-droplet::before {
  content: "";
}

.fa-mask-face::before {
  content: "";
}

.fa-hill-rockslide::before {
  content: "";
}

.fa-scanner-keyboard::before {
  content: "";
}

.fa-circle-o::before {
  content: "";
}

.fa-grid-horizontal::before {
  content: "";
}

.fa-message-dollar::before {
  content: "";
}

.fa-comment-alt-dollar::before {
  content: "";
}

.fa-right-left::before {
  content: "";
}

.fa-exchange-alt::before {
  content: "";
}

.fa-columns-3::before {
  content: "";
}

.fa-paper-plane::before {
  content: "";
}

.fa-road-circle-exclamation::before {
  content: "";
}

.fa-dungeon::before {
  content: "";
}

.fa-hand-holding-box::before {
  content: "";
}

.fa-input-text::before {
  content: "";
}

.fa-window-flip::before {
  content: "";
}

.fa-window-alt::before {
  content: "";
}

.fa-align-right::before {
  content: "";
}

.fa-scanner-gun::before {
  content: "";
}

.fa-scanner::before {
  content: "";
}

.fa-tire::before {
  content: "";
}

.fa-engine::before {
  content: "";
}

.fa-money-bill-1-wave::before {
  content: "";
}

.fa-money-bill-wave-alt::before {
  content: "";
}

.fa-life-ring::before {
  content: "";
}

.fa-hands::before {
  content: "";
}

.fa-sign-language::before {
  content: "";
}

.fa-signing::before {
  content: "";
}

.fa-circle-caret-right::before {
  content: "";
}

.fa-caret-circle-right::before {
  content: "";
}

.fa-wheat::before {
  content: "";
}

.fa-file-spreadsheet::before {
  content: "";
}

.fa-audio-description-slash::before {
  content: "";
}

.fa-calendar-day::before {
  content: "";
}

.fa-water-ladder::before {
  content: "";
}

.fa-ladder-water::before {
  content: "";
}

.fa-swimming-pool::before {
  content: "";
}

.fa-arrows-up-down::before {
  content: "";
}

.fa-arrows-v::before {
  content: "";
}

.fa-chess-pawn-piece::before {
  content: "";
}

.fa-chess-pawn-alt::before {
  content: "";
}

.fa-face-grimace::before {
  content: "";
}

.fa-grimace::before {
  content: "";
}

.fa-wheelchair-move::before {
  content: "";
}

.fa-wheelchair-alt::before {
  content: "";
}

.fa-turn-down::before {
  content: "";
}

.fa-level-down-alt::before {
  content: "";
}

.fa-square-s::before {
  content: "";
}

.fa-rectangle-barcode::before {
  content: "";
}

.fa-barcode-alt::before {
  content: "";
}

.fa-person-walking-arrow-right::before {
  content: "";
}

.fa-square-envelope::before {
  content: "";
}

.fa-envelope-square::before {
  content: "";
}

.fa-dice::before {
  content: "";
}

.fa-unicorn::before {
  content: "";
}

.fa-bowling-ball::before {
  content: "";
}

.fa-pompebled::before {
  content: "";
}

.fa-brain::before {
  content: "";
}

.fa-watch-smart::before {
  content: "";
}

.fa-book-user::before {
  content: "";
}

.fa-sensor-cloud::before {
  content: "";
}

.fa-sensor-smoke::before {
  content: "";
}

.fa-clapperboard-play::before {
  content: "";
}

.fa-bandage::before {
  content: "";
}

.fa-band-aid::before {
  content: "";
}

.fa-calendar-minus::before {
  content: "";
}

.fa-circle-xmark::before {
  content: "";
}

.fa-times-circle::before {
  content: "";
}

.fa-xmark-circle::before {
  content: "";
}

.fa-circle-4::before {
  content: "";
}

.fa-gifts::before {
  content: "";
}

.fa-album-collection::before {
  content: "";
}

.fa-hotel::before {
  content: "";
}

.fa-earth-asia::before {
  content: "";
}

.fa-globe-asia::before {
  content: "";
}

.fa-id-card-clip::before {
  content: "";
}

.fa-id-card-alt::before {
  content: "";
}

.fa-magnifying-glass-plus::before {
  content: "";
}

.fa-search-plus::before {
  content: "";
}

.fa-thumbs-up::before {
  content: "";
}

.fa-cloud-showers::before {
  content: "";
}

.fa-user-clock::before {
  content: "";
}

.fa-onion::before {
  content: "";
}

.fa-clock-twelve-thirty::before {
  content: "";
}

.fa-arrow-down-to-dotted-line::before {
  content: "";
}

.fa-hand-dots::before {
  content: "";
}

.fa-allergies::before {
  content: "";
}

.fa-file-invoice::before {
  content: "";
}

.fa-window-minimize::before {
  content: "";
}

.fa-rectangle-wide::before {
  content: "";
}

.fa-comment-arrow-up::before {
  content: "";
}

.fa-garlic::before {
  content: "";
}

.fa-mug-saucer::before {
  content: "";
}

.fa-coffee::before {
  content: "";
}

.fa-brush::before {
  content: "";
}

.fa-tree-decorated::before {
  content: "";
}

.fa-mask::before {
  content: "";
}

.fa-calendar-heart::before {
  content: "";
}

.fa-magnifying-glass-minus::before {
  content: "";
}

.fa-search-minus::before {
  content: "";
}

.fa-flower::before {
  content: "";
}

.fa-right-left-large::before {
  content: "";
}

.fa-ruler-vertical::before {
  content: "";
}

.fa-circles-overlap::before {
  content: "";
}

.fa-user-large::before {
  content: "";
}

.fa-user-alt::before {
  content: "";
}

.fa-starship-freighter::before {
  content: "";
}

.fa-train-tram::before {
  content: "";
}

.fa-bridge-suspension::before {
  content: "";
}

.fa-trash-check::before {
  content: "";
}

.fa-user-nurse::before {
  content: "";
}

.fa-boombox::before {
  content: "";
}

.fa-syringe::before {
  content: "";
}

.fa-cloud-sun::before {
  content: "";
}

.fa-shield-exclamation::before {
  content: "";
}

.fa-stopwatch-20::before {
  content: "";
}

.fa-square-full::before {
  content: "";
}

.fa-grip-dots::before {
  content: "";
}

.fa-comment-exclamation::before {
  content: "";
}

.fa-pen-swirl::before {
  content: "";
}

.fa-falafel::before {
  content: "";
}

.fa-circle-2::before {
  content: "";
}

.fa-magnet::before {
  content: "";
}

.fa-jar::before {
  content: "";
}

.fa-gramophone::before {
  content: "";
}

.fa-dice-d12::before {
  content: "";
}

.fa-note-sticky::before {
  content: "";
}

.fa-sticky-note::before {
  content: "";
}

.fa-down::before {
  content: "";
}

.fa-arrow-alt-down::before {
  content: "";
}

.fa-hundred-points::before {
  content: "";
}

.fa-100::before {
  content: "";
}

.fa-paperclip-vertical::before {
  content: "";
}

.fa-wind-warning::before {
  content: "";
}

.fa-wind-circle-exclamation::before {
  content: "";
}

.fa-location-pin-slash::before {
  content: "";
}

.fa-map-marker-slash::before {
  content: "";
}

.fa-face-sad-sweat::before {
  content: "";
}

.fa-bug-slash::before {
  content: "";
}

.fa-cupcake::before {
  content: "";
}

.fa-light-switch-off::before {
  content: "";
}

.fa-toggle-large-off::before {
  content: "";
}

.fa-pen-fancy-slash::before {
  content: "";
}

.fa-truck-container::before {
  content: "";
}

.fa-boot::before {
  content: "";
}

.fa-arrow-up-from-water-pump::before {
  content: "";
}

.fa-file-check::before {
  content: "";
}

.fa-bone::before {
  content: "";
}

.fa-cards-blank::before {
  content: "";
}

.fa-circle-3::before {
  content: "";
}

.fa-bench-tree::before {
  content: "";
}

.fa-keyboard-brightness-low::before {
  content: "";
}

.fa-ski-boot-ski::before {
  content: "";
}

.fa-brain-circuit::before {
  content: "";
}

.fa-user-injured::before {
  content: "";
}

.fa-block-brick-fire::before {
  content: "";
}

.fa-firewall::before {
  content: "";
}

.fa-face-sad-tear::before {
  content: "";
}

.fa-sad-tear::before {
  content: "";
}

.fa-plane::before {
  content: "";
}

.fa-tent-arrows-down::before {
  content: "";
}

.fa-exclamation::before {
  content: "\!";
}

.fa-arrows-spin::before {
  content: "";
}

.fa-face-smile-relaxed::before {
  content: "";
}

.fa-comment-xmark::before {
  content: "";
}

.fa-comment-times::before {
  content: "";
}

.fa-print::before {
  content: "";
}

.fa-turkish-lira-sign::before {
  content: "";
}

.fa-try::before {
  content: "";
}

.fa-turkish-lira::before {
  content: "";
}

.fa-face-nose-steam::before {
  content: "";
}

.fa-circle-waveform-lines::before {
  content: "";
}

.fa-waveform-circle::before {
  content: "";
}

.fa-dollar-sign::before {
  content: "\$";
}

.fa-dollar::before {
  content: "\$";
}

.fa-usd::before {
  content: "\$";
}

.fa-ferris-wheel::before {
  content: "";
}

.fa-computer-speaker::before {
  content: "";
}

.fa-skull-cow::before {
  content: "";
}

.fa-x::before {
  content: "X";
}

.fa-magnifying-glass-dollar::before {
  content: "";
}

.fa-search-dollar::before {
  content: "";
}

.fa-users-gear::before {
  content: "";
}

.fa-users-cog::before {
  content: "";
}

.fa-person-military-pointing::before {
  content: "";
}

.fa-building-columns::before {
  content: "";
}

.fa-bank::before {
  content: "";
}

.fa-institution::before {
  content: "";
}

.fa-museum::before {
  content: "";
}

.fa-university::before {
  content: "";
}

.fa-circle-t::before {
  content: "";
}

.fa-sack::before {
  content: "";
}

.fa-grid-2::before {
  content: "";
}

.fa-camera-cctv::before {
  content: "";
}

.fa-cctv::before {
  content: "";
}

.fa-umbrella::before {
  content: "";
}

.fa-trowel::before {
  content: "";
}

.fa-horizontal-rule::before {
  content: "";
}

.fa-bed-front::before {
  content: "";
}

.fa-bed-alt::before {
  content: "";
}

.fa-d::before {
  content: "D";
}

.fa-stapler::before {
  content: "";
}

.fa-masks-theater::before {
  content: "";
}

.fa-theater-masks::before {
  content: "";
}

.fa-kip-sign::before {
  content: "";
}

.fa-face-woozy::before {
  content: "";
}

.fa-cloud-question::before {
  content: "";
}

.fa-pineapple::before {
  content: "";
}

.fa-hand-point-left::before {
  content: "";
}

.fa-gallery-thumbnails::before {
  content: "";
}

.fa-circle-j::before {
  content: "";
}

.fa-eyes::before {
  content: "";
}

.fa-handshake-simple::before {
  content: "";
}

.fa-handshake-alt::before {
  content: "";
}

.fa-page-caret-up::before {
  content: "";
}

.fa-file-caret-up::before {
  content: "";
}

.fa-jet-fighter::before {
  content: "";
}

.fa-fighter-jet::before {
  content: "";
}

.fa-comet::before {
  content: "";
}

.fa-square-share-nodes::before {
  content: "";
}

.fa-share-alt-square::before {
  content: "";
}

.fa-shield-keyhole::before {
  content: "";
}

.fa-barcode::before {
  content: "";
}

.fa-plus-minus::before {
  content: "";
}

.fa-square-sliders-vertical::before {
  content: "";
}

.fa-sliders-v-square::before {
  content: "";
}

.fa-video::before {
  content: "";
}

.fa-video-camera::before {
  content: "";
}

.fa-message-middle::before {
  content: "";
}

.fa-comment-middle-alt::before {
  content: "";
}

.fa-graduation-cap::before {
  content: "";
}

.fa-mortar-board::before {
  content: "";
}

.fa-hand-holding-medical::before {
  content: "";
}

.fa-person-circle-check::before {
  content: "";
}

.fa-square-z::before {
  content: "";
}

.fa-message-text::before {
  content: "";
}

.fa-comment-alt-text::before {
  content: "";
}

.fa-turn-up::before {
  content: "";
}

.fa-level-up-alt::before {
  content: "";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/styles/fonts/fa-regular-400.woff2") format("woff2"), url("/styles/fonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-weight: 400;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/styles/fonts/fa-brands-400.woff2") format("woff2"), url("/styles/fonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-square-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-square-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-square-instagram:before {
  content: "";
}

.fa-instagram-square:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-square-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-square-snapchat:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-square-font-awesome-stroke:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-square-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-rendact:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-square-github:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-square-gitlab:before {
  content: "";
}

.fa-gitlab-square:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-square-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-square-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-square-google-plus:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before {
  content: "";
}

.fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-square-pied-piper:before {
  content: "";
}

.fa-pied-piper-square:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-square-facebook:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-square-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before {
  content: "";
}

.fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-square-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-square-git:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-square-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-stubber:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-odysee:before {
  content: "";
}

.fa-square-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}
body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0;
}
body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}
body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: 100ms;
}
body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0;
}
body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: 100ms;
}
body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: 150ms;
}
body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0;
}
body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: 150ms;
}
body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: 200ms;
}
body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0;
}
body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: 200ms;
}
body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: 250ms;
}
body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0;
}
body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: 250ms;
}
body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: 300ms;
}
body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0;
}
body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: 300ms;
}
body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: 350ms;
}
body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0;
}
body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: 350ms;
}
body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: 400ms;
}
body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0;
}
body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: 400ms;
}
body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: 450ms;
}
body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0;
}
body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: 450ms;
}
body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: 500ms;
}
body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0;
}
body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 500ms;
}
body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: 550ms;
}
body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0;
}
body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: 550ms;
}
body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: 600ms;
}
body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0;
}
body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: 600ms;
}
body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: 650ms;
}
body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0;
}
body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: 650ms;
}
body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: 700ms;
}
body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0;
}
body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: 700ms;
}
body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: 750ms;
}
body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0;
}
body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: 750ms;
}
body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: 800ms;
}
body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0;
}
body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: 800ms;
}
body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: 850ms;
}
body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0;
}
body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: 850ms;
}
body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: 900ms;
}
body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0;
}
body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: 900ms;
}
body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: 950ms;
}
body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0;
}
body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: 950ms;
}
body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1000ms;
}
body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0;
}
body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1000ms;
}
body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1050ms;
}
body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0;
}
body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1050ms;
}
body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1100ms;
}
body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0;
}
body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1100ms;
}
body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1150ms;
}
body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0;
}
body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1150ms;
}
body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1200ms;
}
body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0;
}
body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1200ms;
}
body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1250ms;
}
body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0;
}
body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1250ms;
}
body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1300ms;
}
body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0;
}
body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1300ms;
}
body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1350ms;
}
body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0;
}
body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1350ms;
}
body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1400ms;
}
body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0;
}
body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1400ms;
}
body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1450ms;
}
body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0;
}
body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1450ms;
}
body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1500ms;
}
body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0;
}
body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1500ms;
}
body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1550ms;
}
body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0;
}
body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1550ms;
}
body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1600ms;
}
body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0;
}
body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1600ms;
}
body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1650ms;
}
body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0;
}
body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1650ms;
}
body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1700ms;
}
body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0;
}
body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1700ms;
}
body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1750ms;
}
body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0;
}
body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1750ms;
}
body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1800ms;
}
body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0;
}
body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1800ms;
}
body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1850ms;
}
body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0;
}
body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1850ms;
}
body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1900ms;
}
body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0;
}
body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1900ms;
}
body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1950ms;
}
body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0;
}
body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1950ms;
}
body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2000ms;
}
body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0;
}
body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2000ms;
}
body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2050ms;
}
body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0;
}
body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2050ms;
}
body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2100ms;
}
body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0;
}
body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2100ms;
}
body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2150ms;
}
body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0;
}
body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2150ms;
}
body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2200ms;
}
body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0;
}
body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2200ms;
}
body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2250ms;
}
body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0;
}
body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2250ms;
}
body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2300ms;
}
body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0;
}
body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2300ms;
}
body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2350ms;
}
body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0;
}
body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2350ms;
}
body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2400ms;
}
body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0;
}
body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2400ms;
}
body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2450ms;
}
body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0;
}
body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2450ms;
}
body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2500ms;
}
body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0;
}
body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2500ms;
}
body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2550ms;
}
body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0;
}
body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2550ms;
}
body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2600ms;
}
body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0;
}
body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2600ms;
}
body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2650ms;
}
body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0;
}
body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2650ms;
}
body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2700ms;
}
body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0;
}
body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2700ms;
}
body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2750ms;
}
body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0;
}
body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2750ms;
}
body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2800ms;
}
body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0;
}
body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2800ms;
}
body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2850ms;
}
body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0;
}
body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2850ms;
}
body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2900ms;
}
body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0;
}
body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2900ms;
}
body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2950ms;
}
body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0;
}
body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2950ms;
}
body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3000ms;
}
body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0;
}
body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3000ms;
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}
body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 2rem, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -2rem, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-2rem, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(2rem, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-2rem, 2rem, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(2rem, 2rem, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-2rem, -2rem, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(2rem, -2rem, 0);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 2rem, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -2rem, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-2rem, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(2rem, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 2rem, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -2rem, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-2rem, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(2rem, 0, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}
[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}
[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}
[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}
[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}
[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

.icv {
  position: relative;
  overflow: hidden;
  cursor: row-resize;
}
.icv__icv--vertical {
  cursor: row-resize;
}
.icv__icv--horizontal {
  cursor: col-resize;
}
.icv__img {
  pointer-events: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  max-width: none;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  top: 0;
  display: block;
}
.icv__is--fluid .icv__img {
  display: none;
}
.icv__img-a {
  height: auto;
  position: static;
  z-index: 1;
  left: 0px;
}
.icv__img-b {
  height: 100%;
  position: absolute;
  z-index: 2;
  left: auto;
  right: 0px;
  width: auto;
}
.icv__icv--vertical .icv__img-b {
  width: 100%;
  height: auto;
}
.icv__imposter {
  z-index: 4;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.icv__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 3;
}
.icv__is--fluid .icv__wrapper, .icv__icv--vertical .icv__wrapper {
  width: 100% !important;
}
.icv__is--fluid .icv__wrapper, .icv__icv--horizontal .icv__wrapper {
  height: 100% !important;
}
.icv__fluidwrapper {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.icv__control {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  top: 0px;
  z-index: 5;
}
.icv__icv--vertical .icv__control {
  flex-direction: row;
  left: 0;
  width: 100%;
}
.icv__control-line {
  height: 50%;
  width: 1px;
  z-index: 6;
}
.icv__icv--vertical .icv__control-line {
  width: 50%;
}
.icv__theme-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  transition: all 0.1s ease-out 0s;
  z-index: 5;
}
.icv__icv--vertical .icv__theme-wrapper {
  flex-direction: column;
}
.icv__arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-out 0s;
}
.icv__arrow-a {
  transform: scale(1.5) rotateZ(180deg);
  height: 20px;
  width: 20px;
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.33));
  filter: drop-shadow(0px -3px 5px rgba(0, 0, 0, 0.33));
}
.icv__arrow-b {
  transform: scale(1.5) rotateZ(0deg);
  height: 20px;
  width: 20px;
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.33));
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.33));
}
.icv__circle {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 999px;
}
.icv__label {
  position: absolute;
  bottom: 1rem;
  z-index: 12;
  background: rgba(0, 0, 0, 0.33);
  color: white;
  border-radius: 3px;
  padding: 0.5rem 0.75rem;
  font-size: 0.85rem;
  user-select: none;
}
.icv__label.vertical {
  bottom: auto;
  left: 1rem;
}
.icv__label.on-hover {
  transform: scale(0);
  transition: 0.25s cubic-bezier(0.68, 0.26, 0.58, 1.22);
}
.icv:hover .icv__label.on-hover {
  transform: scale(1);
}
.icv__label-before {
  left: 1rem;
}
.icv__label-after {
  right: 1rem;
}
.icv__label-before.vertical {
  top: 1rem;
}
.icv__label-after.vertical {
  bottom: 1rem;
  right: auto;
}
.icv__body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  background: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  opacity: 0.5;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev {
  left: 2rem;
  z-index: 1;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -2rem;
}

.slick-next {
  right: 2rem;
  z-index: 1;
}
[dir=rtl] .slick-next {
  left: -2rem;
  right: auto;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -48px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 2rem;
  width: 2rem;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 2rem;
  height: 2rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 2rem;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/** *********************************************************************** **/
/**
 *
 */
.hiddenDiv {
  pointer-events: none;
}

.presentDiv {
  pointer-events: auto;
}

#site-scroll-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
}
#site-scroll-top button {
  position: relative;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
#site-scroll-top button i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/** *********************************************************************** **/
/**
 *
 */
#site-badge {
  display: none;
}
@media (min-width: 768px) {
  #site-badge {
    position: fixed;
    z-index: 99;
    right: 0;
    top: 3rem;
    transform: translateX(100%);
    display: block;
    width: 20rem;
  }
  #site-badge ul {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
  }
  #site-badge ul li {
    position: relative;
    display: block;
    margin: 0.5rem 0 0.5rem 0;
    padding: 0;
    transform: translateX(-3rem);
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  }
  #site-badge ul li:hover {
    transform: translateX(-23rem);
  }
  #site-badge ul li button {
    position: absolute;
    top: 0;
    z-index: 2;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    transform: translateX(-1rem);
    background: #ffffff;
    border: none;
    box-shadow: -3px 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 50% 50%;
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  }
  #site-badge ul li:hover button {
    transform: translateX(1.5rem);
  }
  #site-badge ul li aside {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 2rem 2rem 2rem 2rem;
    transform: translateX(3rem);
    background: #ffffff;
    border: none;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.375rem 0 0 0.375rem;
  }
}

/** *********************************************************************** **/
/**
 *
 */
#site-loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #183a68;
  transition: all 0.66s cubic-bezier(0.7, 0, 0.3, 1);
  transform: scale(1.1);
  opacity: 0;
}
#site-loader.active {
  z-index: 9999;
  transform: scale(1);
  opacity: 1;
}
#site-loader .indicator .fa-spin {
  color: #ffffff;
}
#site-loader .message {
  display: block;
  padding: 2rem 2rem;
  color: #ffffff;
}

/** *********************************************************************** **/
/**
 *
 */
.bg-white-blur {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.667);
}

/** *********************************************************************** **/
/**
 *
 */
.bg-gradient-primary {
  background: linear-gradient(150deg, #2b68bb 0%, #183a68 100%);
}
.bg-gradient-secondary {
  background: linear-gradient(150deg, #f23937 0%, #b70e0c 100%);
}
.bg-gradient-third {
  background: linear-gradient(150deg, #00cdce 0%, #009a9b 100%);
}

/** *********************************************************************** **/
/**
 *
 */
.brandshield {
  position: relative;
  z-index: 1;
}
.brandshield:after {
  display: none;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 10rem;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.brandshield-primary:after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 160"><path style="fill:%23183a68" d="m451.86,0l.14,160H0S451.86,173,451.86,0Z"/></svg>');
}
.brandshield-secondary:after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 160"><path style="fill:%23b70e0c" d="m451.86,0l.14,160H0S451.86,173,451.86,0Z"/></svg>');
}
.brandshield-white:after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 160"><path style="fill:%23ffffff" d="m451.86,0l.14,160H0S451.86,173,451.86,0Z"/></svg>');
}
.brandshield-light:after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 160"><path style="fill:%23f7f8f9" d="m451.86,0l.14,160H0S451.86,173,451.86,0Z"/></svg>');
}
.brandshield-dark:after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 160"><path style="fill:%23333333" d="m451.86,0l.14,160H0S451.86,173,451.86,0Z"/></svg>');
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .fa-2x {
    font-size: 3em;
    margin: 0.5rem 0 0 -1rem;
  }
}
/** *********************************************************************** **/
/**
 * Site-Header
 */
#site-header {
  position: fixed;
  z-index: 5;
  display: block;
  width: 100vw;
  /**
   *
   */
}
#site-header header {
  position: relative;
}
#site-header .secondary-navigation {
  display: block;
  position: relative;
  z-index: 2;
  background: #183a68;
}
#site-header .secondary-navigation nav ul, #site-header .secondary-navigation aside ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
#site-header .secondary-navigation nav ul li, #site-header .secondary-navigation aside ul li {
  margin: 0 0.5rem;
}
#site-header .secondary-navigation nav ul li a, #site-header .secondary-navigation aside ul li a {
  display: block;
  padding: 0.25rem 0.25rem;
  font-size: 1rem;
  text-decoration: none;
  color: #ffffff;
}
#site-header .secondary-navigation nav ul li a span, #site-header .secondary-navigation aside ul li a span {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
}
#site-header .primary-navigation {
  position: relative;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  padding: 0.667rem 0;
  overflow: visible;
  background: #ffffff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.33);
  transition: all 0.25s ease-in-out;
  transform-origin: center;
}
#site-header .primary-navigation figure {
  position: relative;
  width: 10rem;
  height: 100%;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  #site-header .primary-navigation figure {
    width: 10rem;
    height: 100%;
  }
}
#site-header .primary-navigation figure a {
  display: block;
  margin: 0;
  padding: 0;
}
#site-header .primary-navigation figure a svg {
  transform: translate(0, 0.5rem);
}
#site-header .primary-navigation aside {
  padding: 0.5rem 0 0.5rem 0;
  margin: 0 4rem 0;
  display: flex;
  justify-content: center;
}
#site-header .primary-navigation aside a {
  position: relative;
  display: inline-block;
  padding: 0.25rem 0.25rem;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
}
#site-header .primary-navigation nav > ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  #site-header .primary-navigation nav > ul {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-content: center;
    justify-items: stretch;
  }
}
#site-header .primary-navigation nav > ul > li {
  display: none;
  transition: all 0.25s ease-in-out;
}
#site-header .primary-navigation nav > ul > li[role=button] {
  display: flex;
}
#site-header .primary-navigation nav > ul > li > a .far:first-child {
  display: none;
}
#site-header .primary-navigation nav > ul > li > a .far:last-child {
  display: none;
}
@media (min-width: 768px) {
  #site-header .primary-navigation nav > ul > li .far:first-child {
    display: inline-block;
  }
  #site-header .primary-navigation nav > ul > li .far:last-child {
    display: none;
  }
}
@media (min-width: 1400px) {
  #site-header .primary-navigation nav > ul > li > a .far:first-child {
    display: inline-block;
  }
  #site-header .primary-navigation nav > ul > li > a .far:last-child {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  #site-header .primary-navigation nav > ul > li {
    display: flex;
    align-self: center;
    justify-self: center;
    padding: 0.75rem 0.75rem;
    margin: 0 0.25rem;
  }
  #site-header .primary-navigation nav > ul > li[role=button] {
    display: none;
  }
  #site-header .primary-navigation nav > ul > li > a {
    display: block;
    position: relative;
    z-index: 2;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-decoration: none;
    color: #183a68;
  }
  #site-header .primary-navigation nav > ul > li > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 3rem;
    z-index: 1;
    width: auto;
    min-width: 16rem;
    margin: 0;
    padding: 1.5rem 1rem 0.5rem 1rem;
    background: #ffffff;
    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 16px 16px rgba(0, 0, 0, 0.25);
    transform: translate(-16px, -24px);
    transition: all 0.25s ease-in-out;
  }
  #site-header .primary-navigation nav > ul > li > ul > li.splitter {
    margin: 1rem 0 1rem 0;
    border-top: 1px solid #f7f8f9;
  }
  #site-header .primary-navigation nav > ul > li > ul > li.splitter.title {
    padding: 0.5rem 0 0 0;
  }
  #site-header .primary-navigation nav > ul > li > ul > li.splitter.title span {
    font-size: 0.75rem;
    color: #333333;
  }
  #site-header .primary-navigation nav > ul > li > ul > li > a {
    position: relative;
    display: block;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 0.1em;
    text-decoration: none;
    color: #183a68;
  }
  #site-header .primary-navigation nav > ul > li > ul > li > a:not(.btn) {
    color: #183a68;
  }
  #site-header .primary-navigation nav > ul > li > ul > li > a:not(.btn):hover {
    color: #b70e0c;
  }
  #site-header .primary-navigation nav > ul > li > ul > li > a.btn-outline-secondary:hover, #site-header .primary-navigation nav > ul > li > ul > li > a.btn-secondary:hover {
    color: #ffffff;
  }
  #site-header .primary-navigation nav > ul > li[data-flyout] {
    position: relative;
    transition: all 0.25s ease-in-out;
  }
  #site-header .primary-navigation nav > ul > li[data-flyout]:after {
    content: "";
    display: block;
    width: 100%;
    height: 80%;
    position: absolute;
    left: 0;
    top: 10%;
    z-index: 1;
    background: #183a68;
    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
    transform: translate(0, 16px);
    transition: all 0.25s ease-in-out;
  }
  #site-header .primary-navigation nav > ul > li[data-flyout]:hover:after {
    opacity: 1;
    transform: translate(0, 0);
  }
  #site-header .primary-navigation nav > ul > li[data-flyout]:hover > a {
    color: #ffffff;
  }
  #site-header .primary-navigation nav > ul > li[data-flyout]:hover > a .fa-chevron-down {
    color: #ffffff !important;
  }
  #site-header .primary-navigation nav > ul > li[data-flyout]:hover > ul {
    visibility: visible;
    opacity: 1;
    transform: translate(-16px, -8px);
  }
}
#site-header .primary-navigation nav > ul > li button {
  display: block;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: transparent;
}
#site-header .primary-navigation svg, #site-header .primary-navigation nav {
  transition: all 0.25s ease-in-out;
  transform-origin: center;
}
#site-header .primary-navigation button[name=site-menu] {
  position: relative;
  background: transparent;
  outline: none;
  border: none;
}
#site-header .primary-navigation button[name=site-menu] .fa-bars {
  transition: all 0.66s cubic-bezier(0.7, 0, 0.3, 1);
}
#site-header .primary-navigation button[name=site-menu] .fa-times {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: rotate(-90deg);
  transition: all 0.66s cubic-bezier(0.7, 0, 0.3, 1);
}
#site-header .primary-navigation button[name=site-menu].active .fa-bars {
  opacity: 0;
  transform: rotate(90deg);
}
#site-header .primary-navigation button[name=site-menu].active .fa-times {
  opacity: 1;
  transform: rotate(0);
}
#site-header.site-header-out .primary-navigation {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.667);
}

/** *********************************************************************** **/
/**
 * Site-Mobile-Menu
 */
body.site-mobile-menu {
  overflow: hidden;
}

#site-mobile-menu {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  display: block;
  width: 90vw;
  height: 100vh;
  padding: 7.5rem 1rem 0 1rem;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateX(100vw);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}
#site-mobile-menu nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin: 0;
  padding: 0;
}
#site-mobile-menu nav > ul > li[data-flyout] {
  margin: 0;
  padding: 0;
  margin: 0.5rem 0 0.5rem 0;
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: 1px solid #f7f8f9;
}
#site-mobile-menu nav > ul > li[data-flyout] > a {
  position: relative;
  display: block;
  padding: 0.25rem 0.25rem;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  color: #183a68;
}
#site-mobile-menu nav > ul > li[data-flyout] > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin: 0 0 0 1.5rem;
  padding: 0;
}
#site-mobile-menu nav > ul > li[data-flyout] > ul > li.splitter {
  margin: 0.25rem 0 0.25rem 0;
  border-top: 1px solid #f7f8f9;
}
#site-mobile-menu nav > ul > li[data-flyout] > ul > li.splitter.title {
  padding: 0.25rem 0 0 0;
}
#site-mobile-menu nav > ul > li[data-flyout] > ul > li.splitter.title span {
  font-size: 0.75rem;
  color: #333333;
}
#site-mobile-menu nav > ul > li[data-flyout] > ul > li > a {
  position: relative;
  display: block;
  padding: 0.25rem 0.25rem;
  font-size: 1rem;
  font-weight: 200;
  text-decoration: none;
  color: #183a68;
}
#site-mobile-menu.active {
  transform: translateX(0);
  opacity: 1;
}
#site-mobile-menu.active nav ul li, #site-mobile-menu.active aside ul li {
  transform: translateX(0);
  opacity: 1;
}

/** *********************************************************************** **/
/**
 * Body
 */
body {
  background: #f7f8f9;
}

/** *********************************************************************** **/
/**
 * Site-Body
 */
#site-body {
  padding-top: 6.5rem;
  position: relative;
  z-index: 3;
  min-height: 100vh;
}
#site-body.sidebar-visible {
  overflow: hidden;
}

/** *********************************************************************** **/
/**
 * Site-Scroller
 */
#site-scroller {
  position: relative;
  z-index: 2;
}

.ScrollSmoother-wrapper {
  z-index: 2;
}

/* h3 Headlines for Mobile Devices */
@media screen and (max-width: 767px) {
  h3, .h3 {
    font-size: 20px;
  }
}
/** *********************************************************************** **/
/**
 * Site-Footer
 */
#site-footer aside.social-channels {
  display: flex;
  justify-content: end;
}
#site-footer aside.social-channels ul {
  display: flex;
  justify-items: end;
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
}
#site-footer aside.social-channels ul li {
  padding: 0 1.5rem 0 0;
}
#site-footer aside.social-channels ul li a {
  font-size: 1.5rem;
  text-decoration: none;
  color: #ffffff;
}
#site-footer aside.social-channels ul li a span {
  display: none;
}
#site-footer ul.sitemap {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
}
#site-footer ul.sitemap li {
  padding: 0.25rem 0;
}
#site-footer ul.sitemap li a {
  font-size: 1rem;
  font-weight: lighter;
  line-height: 100%;
  text-decoration: none;
  color: #ffffff;
}
#site-footer p.copyright {
  margin: 0;
  font-size: 1rem;
  font-weight: lighter;
  line-height: 100%;
  color: #ffffff;
}
#site-footer ul.legal-links {
  display: flex;
  justify-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
}
#site-footer ul.legal-links li {
  padding: 0 0.5rem 0 0.5rem;
  border-right: 1px solid #183a68;
}
#site-footer ul.legal-links li:last-child {
  border-right: none;
}
#site-footer ul.legal-links li a {
  font-size: 1rem;
  font-weight: lighter;
  line-height: 100%;
  text-decoration: none;
  color: #ffffff;
}
#site-footer ul.legal-links li a:hover, #site-footer ul.legal-links li a:focus {
  color: #183a68;
}

/** *********************************************************************** **/
/**
 * Site-Sidebar
 */
.site-sidebar {
  position: fixed;
  z-index: 998;
  top: 0;
  width: 90vw;
  height: 100vh;
  padding: 7rem 2rem 2rem 2rem;
  overflow: hidden;
  overflow-y: scroll;
  background: rgba(24, 58, 104, 0.9);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.33);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.87, 0, 0.13, 1);
}
.site-sidebar h6, .site-sidebar .h6 {
  color: var(--custom-ley-white);
}
.site-sidebar nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
.site-sidebar nav ul li {
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
  border-top: 1px solid var(--custom-ley-white);
}
.site-sidebar nav ul li:last-child {
  border-bottom: 1px solid var(--custom-ley-white);
}
.site-sidebar nav ul li a {
  display: block;
  font-weight: lighter;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--custom-ley-white);
}
.site-sidebar section ul {
  display: grid;
  grid-gap: 0 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: dense;
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
.site-sidebar section ul li {
  margin: 0;
  padding: 0.25rem 0 0.25rem 0;
}
.site-sidebar section ul li a {
  display: block;
  font-size: 0.75rem;
  font-weight: lighter;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: var(--custom-ley-white);
}

#site-sidebar-menu {
  right: -100%;
}
#site-sidebar-menu.visible {
  right: 0;
  opacity: 1;
}

/** *********************************************************************** **/
/**
 * Site-Flyout
 */
@media (max-width: 575.98px) {
  #site-flyout {
    display: none;
    position: fixed;
    z-index: 99;
    right: -50%;
    top: 0;
    height: 100vh;
    width: 50vw;
    background: #dee2e6;
    transition: right 0.5s ease-in-out;
  }
  #site-flyout nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  #site-flyout nav ul li {
    margin: 0 0 0.125rem 0;
    padding: 0;
    transform: translate(1rem, 0);
    transition: all 0.25s ease-in;
    background: rgba(255, 255, 255, 0.9);
    opacity: 0.5;
  }
  #site-flyout nav ul li a {
    display: block;
    padding: 0.75rem 2rem 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    color: #333333;
  }
  #site-flyout nav ul li.active {
    transform: translate(0, 0);
    opacity: 1;
  }
  #site-flyout nav ul li.active a {
    color: #183a68;
  }
  #site-flyout button {
    position: absolute;
    left: 0;
    top: 50%;
    display: inline-block;
    padding: 0.5rem 0.75rem;
    transform: translate(-100%, -50%);
    background: #dee2e6;
    border: none;
  }
  #site-flyout.open {
    right: 0;
  }
}
@media (min-width: 576px) {
  #site-flyout {
    position: fixed;
    z-index: 99;
    right: 0;
    bottom: 10%;
    display: block;
  }
  #site-flyout nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  #site-flyout nav ul li {
    margin: 0 0 0.125rem 0;
    padding: 0;
    transform: translate(calc(100% - 0.75rem), 0);
    transition: all 0.25s ease-in;
    background: rgba(255, 255, 255, 0.9);
    border-left: 0.75rem solid #183a68;
    border-radius: 0.25rem 0 0 0.25rem;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }
  #site-flyout nav ul li a {
    display: block;
    padding: 0.75rem 2rem 0.75rem 1rem;
    transition: all 0.25s ease-in;
    opacity: 0.75;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    color: #333333;
  }
  #site-flyout nav ul li.active, #site-flyout nav ul li:hover {
    transform: translate(0, 0);
    border-left-color: #b70e0c;
  }
  #site-flyout nav ul li.active a, #site-flyout nav ul li:hover a {
    opacity: 1;
    color: #183a68;
  }
  #site-flyout button {
    display: none;
    visibility: hidden;
  }
}

/** *********************************************************************** **/
/**
 *
 */
.vh-100 {
  height: 100vh;
}
.vh-xs-100 {
  height: 100vh;
}
@media (min-width: 576px) {
  .vh-sm-100 {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .vh-md-100 {
    height: 100vh;
  }
}
@media (min-width: 992px) {
  .vh-lg-100 {
    height: 100vh;
  }
}
@media (min-width: 1200px) {
  .vh-xl-100 {
    height: 100vh;
  }
}
@media (min-width: 1400px) {
  .vh-xxl-100 {
    height: 100vh;
  }
}
.vh-75 {
  height: 75vh;
}
.vh-xs-75 {
  height: 75vh;
}
@media (min-width: 576px) {
  .vh-sm-75 {
    height: 75vh;
  }
}
@media (min-width: 768px) {
  .vh-md-75 {
    height: 75vh;
  }
}
@media (min-width: 992px) {
  .vh-lg-75 {
    height: 75vh;
  }
}
@media (min-width: 1200px) {
  .vh-xl-75 {
    height: 75vh;
  }
}
@media (min-width: 1400px) {
  .vh-xxl-75 {
    height: 75vh;
  }
}
.vh-667 {
  height: 66.67vh;
}
.vh-xs-667 {
  height: 66.67vh;
}
@media (min-width: 576px) {
  .vh-sm-667 {
    height: 66.67vh;
  }
}
@media (min-width: 768px) {
  .vh-md-667 {
    height: 66.67vh;
  }
}
@media (min-width: 992px) {
  .vh-lg-667 {
    height: 66.67vh;
  }
}
@media (min-width: 1200px) {
  .vh-xl-667 {
    height: 66.67vh;
  }
}
@media (min-width: 1400px) {
  .vh-xxl-667 {
    height: 66.67vh;
  }
}
.vh-50 {
  height: 50vh;
}
.vh-xs-50 {
  height: 50vh;
}
@media (min-width: 576px) {
  .vh-sm-50 {
    height: 50vh;
  }
}
@media (min-width: 768px) {
  .vh-md-50 {
    height: 50vh;
  }
}
@media (min-width: 992px) {
  .vh-lg-50 {
    height: 50vh;
  }
}
@media (min-width: 1200px) {
  .vh-xl-50 {
    height: 50vh;
  }
}
@media (min-width: 1400px) {
  .vh-xxl-50 {
    height: 50vh;
  }
}
.vh-333 {
  height: 33.33vh;
}
.vh-xs-333 {
  height: 33.33vh;
}
@media (min-width: 576px) {
  .vh-sm-333 {
    height: 33.33vh;
  }
}
@media (min-width: 768px) {
  .vh-md-333 {
    height: 33.33vh;
  }
}
@media (min-width: 992px) {
  .vh-lg-333 {
    height: 33.33vh;
  }
}
@media (min-width: 1200px) {
  .vh-xl-333 {
    height: 33.33vh;
  }
}
@media (min-width: 1400px) {
  .vh-xxl-333 {
    height: 33.33vh;
  }
}
.vh-25 {
  height: 25vh;
}
.vh-xs-25 {
  height: 25vh;
}
@media (min-width: 576px) {
  .vh-sm-25 {
    height: 25vh;
  }
}
@media (min-width: 768px) {
  .vh-md-25 {
    height: 25vh;
  }
}
@media (min-width: 992px) {
  .vh-lg-25 {
    height: 25vh;
  }
}
@media (min-width: 1200px) {
  .vh-xl-25 {
    height: 25vh;
  }
}
@media (min-width: 1400px) {
  .vh-xxl-25 {
    height: 25vh;
  }
}
.vh-auto {
  height: auto;
}
.vh-xs-auto {
  height: auto;
}
@media (min-width: 576px) {
  .vh-sm-auto {
    height: auto;
  }
}
@media (min-width: 768px) {
  .vh-md-auto {
    height: auto;
  }
}
@media (min-width: 992px) {
  .vh-lg-auto {
    height: auto;
  }
}
@media (min-width: 1200px) {
  .vh-xl-auto {
    height: auto;
  }
}
@media (min-width: 1400px) {
  .vh-xxl-auto {
    height: auto;
  }
}

/** *********************************************************************** **/
/**
 *
 */
.h1 {
  font-size: 1.25rem;
}
@media (min-width: 576px) {
  .h1 {
    font-size: 1.875rem;
  }
}
@media (min-width: 768px) {
  .h1 {
    font-size: 2.5rem;
  }
}

.h2 {
  font-size: 1rem;
}
@media (min-width: 576px) {
  .h2 {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .h2 {
    font-size: 2rem;
  }
}

.h3 {
  font-size: 0.875rem;
}
@media (min-width: 576px) {
  .h3 {
    font-size: 1.3125rem;
  }
}
@media (min-width: 768px) {
  .h3 {
    font-size: 1.75rem;
  }
}

.h4 {
  font-size: 0.75rem;
}
@media (min-width: 576px) {
  .h4 {
    font-size: 1.125rem;
  }
}
@media (min-width: 768px) {
  .h4 {
    font-size: 1.5rem;
  }
}

.h5 {
  font-size: 0.625rem;
}
@media (min-width: 576px) {
  .h5 {
    font-size: 0.9375rem;
  }
}
@media (min-width: 768px) {
  .h5 {
    font-size: 1.25rem;
  }
}

.h6 {
  font-size: 0.5rem;
}
@media (min-width: 576px) {
  .h6 {
    font-size: 0.75rem;
  }
}
@media (min-width: 768px) {
  .h6 {
    font-size: 1rem;
  }
}

/** *********************************************************************** **/
/**
 *
 */
.h1.hr, .h2.hr, .h3.hr, .h4.hr, .h5.hr, .h6.hr {
  position: relative;
}
.h1.hr:after, .h2.hr:after, .h3.hr:after, .h4.hr:after, .h5.hr:after, .h6.hr:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -1rem;
  display: block;
  height: 2px;
  width: 10%;
  background: var(--custom-ley-black);
  transform: translate(-50%, 0);
}
.h1.text-white.hr:after, .h2.text-white.hr:after, .h3.text-white.hr:after, .h4.text-white.hr:after, .h5.text-white.hr:after, .h6.text-white.hr:after {
  background: var(--custom-ley-third);
}
.h1.text-primary.hr:after, .h2.text-primary.hr:after, .h3.text-primary.hr:after, .h4.text-primary.hr:after, .h5.text-primary.hr:after, .h6.text-primary.hr:after {
  background: var(--custom-ley-third);
}
.h1.text-secondary.hr:after, .h2.text-secondary.hr:after, .h3.text-secondary.hr:after, .h4.text-secondary.hr:after, .h5.text-secondary.hr:after, .h6.text-secondary.hr:after {
  background: var(--custom-ley-third);
}

/** *********************************************************************** **/
/**
 *
 */
.lead {
  font-size: 0.9375rem;
}
@media (min-width: 576px) {
  .lead {
    font-size: 0.9375rem;
  }
}
@media (min-width: 768px) {
  .lead {
    font-size: 1.25rem;
  }
}

/** *********************************************************************** **/
/**
 *
 */
.us {
  position: relative;
}
.us:after {
  content: "";
  position: absolute;
  bottom: -8px;
  right: calc(var(--custom-ley-controls) * -2);
  width: calc(var(--custom-ley-controls) * 2);
  height: 1px;
  background-color: var(--custom-ley-white);
}

/** *********************************************************************** **/
/**
 *
 */
.ls-0 {
  letter-spacing: 0;
}
.ls-1 {
  letter-spacing: 0.1em;
}
.ls-2 {
  letter-spacing: 0.2em;
}
.ls-3 {
  letter-spacing: 0.3em;
}

/** *********************************************************************** **/
/**
 *
 */
.card {
  border: none;
}

/** *********************************************************************** **/
/**
 *
 */
.btn {
  font-weight: lighter;
}

/** *********************************************************************** **/
/**
 *
 */
.form-switch .form-check-input {
  border-radius: 2em;
}
.form-switch .form-check-input[type=checkbox] {
  border-radius: 1.25em;
}

/** *********************************************************************** **/
/**
 *
 */
.separator {
  position: relative;
  text-align: center;
}
.separator:after, .separator:before {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(50% - 1rem);
  border-top-width: 1px;
  border-top-color: var(--custom-ley-black);
  border-top-style: solid;
}
.separator:before {
  left: 0;
}
.separator:after {
  right: 0;
}

/** *********************************************************************** **/
/**
 *
 */
/** *********************************************************************** **/
/**
 *
 */
.list-group-borderless, .list-group-borderless .list-group-item {
  border: none;
}
.list-group-transparent, .list-group-transparent .list-group-item {
  background: none;
}

/** *********************************************************************** **/
/**
 *
 */
.translate-x1e {
  transform: translateX(1rem);
}
.translate-x2e {
  transform: translateX(2rem);
}
.translate-x3e {
  transform: translateX(2rem);
}

/** *********************************************************************** **/
/**
 *
 */
.img-shadow {
  position: relative;
  z-index: 1;
}
.img-shadow:after {
  content: "";
  position: absolute;
  z-index: 1;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  transform: translate(-1.5rem, -1.5rem);
}
.img-shadow img {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}
.img-shadow-primary:after {
  background: linear-gradient(300deg, #2b68bb 0%, #183a68 100%);
}
.img-shadow-secondary:after {
  background: linear-gradient(300deg, #f23937 0%, #b70e0c 100%);
}

/** *********************************************************************** **/
/**
 *
 */
#funding-rates-select .short-select {
  display: block;
  line-height: 120%;
}
#funding-rates-select .long-select {
  display: block;
  line-height: 120%;
}

#funding-rates {
  width: 10rem;
  height: 10rem;
  padding: 3rem 1rem 0 1rem;
  border-radius: 50%;
  background: #183a68;
}
#funding-rates .short {
  display: block;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-align: center;
  color: #ffffff;
}
#funding-rates .long {
  display: block;
  font-size: 0.75rem;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
}

/** *********************************************************************** **/
/**
 *
 */
.icon {
  display: inline-block;
  position: relative;
}
.icon-shape {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.icon-shape i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/** *********************************************************************** **/
/**
 *
 */
.feature {
  /**
   *
   */
}
.feature-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.feature-list li {
  clear: both;
  margin: 0 0 2rem 0;
}
.feature-list li:last-child {
  margin: 0;
}
.feature-list li .icon {
  float: left;
}

/** *********************************************************************** **/
/**
 *
 */
.feature-stage .image {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.feature-stage .content {
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 99;
  transform: translate(-50%, 10%);
  width: 80vw;
  bottom: 10%;
}
@media (min-width: 992px) {
  .feature-stage .content {
    width: 50vw;
  }
}

/** *********************************************************************** **/
/**
 *
 */
.carousel {
  /**
   *
   */
  /**
   *
   */
}
@media (max-width: 991.98px) {
  .carousel .slick-circle-button {
    top: 20%;
    padding-left: 2rem;
    padding-right: 3rem;
  }
}
.carousel-herostage .image {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.carousel-herostage .content {
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 99;
  transform: translate(-50%, 0);
  width: 75vw;
  position: relative;
  left: auto;
  bottom: auto;
  margin: auto;
  transform: translate(0, -25%);
}
@media (min-width: 1200px) {
  .carousel-herostage .content {
    width: 75vw;
    position: relative;
    left: auto;
    bottom: auto;
    margin: auto;
    transform: translate(0, -25%);
  }
}
@media (min-width: 1400px) {
  .carousel-herostage .content {
    position: absolute;
    width: 50vw;
    left: 2rem;
    bottom: 2rem;
    transform: translate(0, 0);
  }
}
@media (min-width: 1920px) {
  .carousel-herostage .content {
    width: 25vw;
    left: 4rem;
    bottom: 5rem;
    transform: translate(0, 0);
  }
}
.carousel-frontpage .image {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.carousel-frontpage .content {
  width: 75vw;
  position: relative;
  left: auto;
  bottom: auto;
  margin: auto;
  transform: translate(0, -25%);
}
@media (min-width: 1200px) {
  .carousel-frontpage .content {
    width: 50vw;
    transform: translate(0, -50%);
  }
}

/** *********************************************************************** **/
/**
 *
 */
.offer-stage {
  counter-reset: itemOfferCounter;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
}
.offer-stage:after {
  content: "";
  display: block;
  clear: both;
}
.offer-stage .offer-item {
  counter-increment: itemOfferCounter;
  position: relative;
  float: left;
  overflow: hidden;
  width: 80px;
  padding: 0 15px;
  transition: all 0.5s cubic-bezier(0.87, 0, 0.13, 1);
}
.offer-stage .offer-item .offer-item-container {
  width: 800px;
}
.offer-stage .offer-item .offer-item-title {
  position: absolute;
  top: 100%;
  left: 40px;
  z-index: 1;
  width: 545px;
  height: 80px;
  margin: 0 auto;
  padding: 33.33% 0 0 0;
  transform: rotate(-90deg);
  transform-origin: left;
  backdrop-filter: blur(1px);
  background: rgba(255, 255, 255, 0.667);
  transition: all 0.5s cubic-bezier(0.87, 0, 0.13, 1);
  cursor: pointer;
}
.offer-stage .offer-item .offer-item-image {
  height: 50vh;
  margin: 0 -15px;
}
.offer-stage .offer-item .offer-item-image img {
  max-width: 100%;
  height: 50vh;
  object-fit: cover;
}
.offer-stage .offer-item .offer-item-content {
  padding: 3rem 3rem 3rem 3rem;
}
.offer-stage .offer-item.active .offer-item-title {
  opacity: 0;
}
.offer-stage .offer-item.active .offer-item-content {
  opacity: 1;
}

.offer-item.active {
  width: calc(100% - var(--custom-ley-offer-items) * 80px);
}

.offer-item.active .offer-item-container {
  width: 1007px;
}

.offer-item.active .offer-item-image {
  max-width: 415px;
}

.offer-item.active .offer-item-container .row .col:first-child {
  max-width: 40% !important;
}

@media (max-width: 1280px) {
  .offer-item.active .offer-item-container {
    width: 750px;
  }

  .offer-item-container {
    width: 595px;
  }
}
@media (max-width: 1199px) {
  .offer-item-title {
    height: 50px;
    left: 25px;
    top: 94%;
  }

  .offer-item {
    width: 50px;
  }

  .offer-item.active {
    width: calc(100% - counter(itemOfferCounter)*50px);
  }
}
/*
@media (max-width:991px){
    // .offer-item.active .offer-item-content {margin-left: 0;}
    .offer-item-title {font-size: 14px;top: 93%; width: 280px;height: 36px; left: 17px;}
    .offer-item.active .offer-item-container {width: 500px;}
    .offer-item-container {width: 395px; max-height: 279px;}
    .offer-item {width: 35px;}
    .offer-item.active {width: calc(100% - counter(itemOfferCounter)*35px);}
}

@media (max-width:767px){
    .offer-item {width: 100%;}
    .offer-item-container {width: 100%;max-height: unset;display: none;}
    .offer-item-title{ padding-left: 15px; position: relative; transform: rotate(0); border-bottom: 1px solid #999; height: 40px; width: 100%; left: 0;}
    .offer-item-image {margin: 0;}
    .offer-item.active .offer-item-container {width: 100%; display: block;}
    .offer-item.active .offer-item-container .row .col:first-child {max-width: 50% !important;}
    .offer-item.active{ width: 100%;}
}

@media (max-width:574px){
    .container {max-width: 100%;}
    .offer-item.active .offer-item-container .row {display: block;}
    .offer-item.active .offer-item-container .row .col {max-width: 100% !important;}
    .offer-item-image,.offer-item.active .offer-item-image {width: 100%;max-width: unset;}
    .offer-item-image img{ width: 100%;}
    .offer-item.active .offer-item-container .row .col:first-child {max-width: 100% !important;}
}
*/
/** *********************************************************************** **/
/**
 *
 */
.stepper {
  width: 100%;
}
.stepper-default .item {
  position: relative;
  width: 50%;
  padding-top: 0.25rem;
  padding-bottom: 1.5rem;
}
@media (max-width: 767.98px) {
  .stepper-default .item {
    width: 100%;
    margin-left: 0;
  }
}
.stepper-default .item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  margin: 0.5rem 0 0 -0.5rem;
  border: 1px solid #183a68;
  border-radius: 50%;
}
.stepper-default .item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: -0.1875rem;
  width: 1px;
  margin: 1.875rem 0 0 -1px;
  background: #183a68;
}
.stepper-default .item:nth-child(odd) {
  margin-left: 0;
  padding-right: 1.5rem;
  padding-left: 0;
  text-align: right;
}
@media (max-width: 767.98px) {
  .stepper-default .item:nth-child(odd) {
    padding-right: 0;
    padding-left: 1.5rem;
    text-align: left;
  }
}
.stepper-default .item:nth-child(odd):before {
  right: 0;
  left: auto;
  margin-right: -0.5rem;
}
@media (max-width: 767.98px) {
  .stepper-default .item:nth-child(odd):before {
    right: auto;
    left: 0;
  }
}
.stepper-default .item:nth-child(odd):after {
  right: 0;
  left: auto;
  margin-right: -1px;
}
@media (max-width: 767.98px) {
  .stepper-default .item:nth-child(odd):after {
    right: auto;
    left: 0;
  }
}
.stepper-default .item:nth-child(even) {
  margin-left: 50%;
  padding-left: 1.5rem;
  text-align: left;
}
@media (max-width: 767.98px) {
  .stepper-default .item:nth-child(even) {
    margin-left: 0;
  }
}

/** *********************************************************************** **/
/**
 *
 */
#stepper-form-bath .stepper .item:before {
  border-color: #183a68;
}

.stepper-form {
  counter-reset: steps;
  display: flex;
}
.stepper-form .step {
  display: flex;
  position: relative;
  padding: 5.625rem 20% 0 0;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .stepper-form .step {
    display: flex;
    position: relative;
    padding: 5.625rem 15% 0 0;
  }
}
.stepper-form .step::before {
  counter-increment: steps;
  content: counter(steps, decimal);
  position: absolute;
  z-index: 20;
  display: block;
  top: 0;
  left: 0;
  width: 3.75rem;
  height: 3.75rem;
  border: 2px solid #dee2e6;
  border-radius: 50%;
  font-size: 1.5rem;
  line-height: calc(3.75rem - .25rem);
  text-align: center;
}
.stepper-form .step:hover::before, .stepper-form .step.active::before {
  background: #b70e0c;
  border-color: transparent;
  color: #ffffff;
}
.stepper-form .step:not(:last-child)::after {
  content: "";
  display: block;
  position: absolute;
  top: 29px;
  left: 3.75rem;
  width: calc(100% - 3.75rem);
  height: 2px;
  background: #e9ecef;
  z-index: 10;
}

/** *********************************************************************** **/
/**
 *
 */
.stepper-visual {
  counter-reset: steps;
  display: flex;
  align-content: start;
  justify-content: space-between;
}
@media (max-width: 575.98px) {
  .stepper-visual {
    display: block;
  }
}
.stepper-visual .step {
  position: relative;
  margin: 0 auto;
  padding: 0 2rem;
}
.stepper-visual .step figure {
  position: relative;
  display: block;
  margin: 0 0 1.25rem 0;
  padding: 2.5rem 2.5rem 0 0;
}
.stepper-visual .step figure::before {
  counter-increment: steps;
  content: counter(steps, decimal);
  position: absolute;
  z-index: 20;
  display: block;
  top: 2.5rem;
  left: 0;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 0.375rem 0 50% 0;
  background-color: #b70e0c;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: calc(2.75rem - .25rem);
  text-align: center;
  color: #ffffff;
}
.stepper-visual .step figure img {
  position: relative;
  z-index: 2;
  max-width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.stepper-visual .step figure figcaption {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 2.5rem;
  left: 2.5rem;
  display: block;
  border-radius: 0.375rem;
  background-color: #183a68;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.stepper-visual .step figure figcaption h1, .stepper-visual .step figure figcaption .h1, .stepper-visual .step figure figcaption h2, .stepper-visual .step figure figcaption .h2, .stepper-visual .step figure figcaption h3, .stepper-visual .step figure figcaption .h3, .stepper-visual .step figure figcaption h4, .stepper-visual .step figure figcaption .h4, .stepper-visual .step figure figcaption h5, .stepper-visual .step figure figcaption .h5, .stepper-visual .step figure figcaption h6, .stepper-visual .step figure figcaption .h6 {
  position: absolute;
  top: 0;
  right: 0;
  left: 100%;
  margin: 1rem 0 0 -0.5rem;
  transform: rotate(90deg);
  transform-origin: 0% 0%;
  white-space: nowrap;
  line-height: 100%;
  color: #ffffff;
}
.stepper-visual .step-primary figure::before {
  background-color: #b70e0c;
}
.stepper-visual .step-primary figure figcaption {
  background-color: #183a68;
}
.stepper-visual .step-secondary figure::before {
  background-color: #183a68;
}
.stepper-visual .step-secondary figure figcaption {
  background-color: #b70e0c;
}
.stepper-visual .step-third figure::before {
  background-color: #183a68;
}
.stepper-visual .step-third figure figcaption {
  background-color: #009a9b;
}

.grid-item {
  float: left;
  margin-bottom: 2rem;
}